import React, { useState } from 'react'
import AvatarsItem from '../../components/AvatarsItem/AvatarsItem'
import { useDispatch } from 'react-redux';
import { setErrorButton } from '../../store/buttonSlice';

const avatars = [
    {
        name: "Samantha",
        gender: "Female",
        age: "32",
        img: "Avatar card.webp",
        checked: false
    },
    {
        name: "Tim",
        gender: "Male",
        age: "29",
        img: "Avatar card-1.webp",
        checked: false
    },
    {
        name: "Sylvester",
        gender: "Male",
        age: "24",
        img: "Avatar card-2.webp",
        checked: false
    },
    {
        name: "Michael",
        gender: "Male",
        age: "44",
        img: "Avatar card-3.webp",
        checked: false
    },
    {
        name: "Gabrielle",
        gender: "Female",
        age: "32",
        img: "Avatar card-4.webp",
        checked: false
    }, {
        name: "Mary",
        gender: "Female",
        age: "38",
        img: "Avatar card-5.webp",
        checked: false
    },
    {
        name: "Vanessa",
        gender: "Female",
        age: "32",
        img: "Avatar card-6.webp",
        checked: false
    },
    {
        name: "Daniela",
        gender: "Female",
        age: "26",
        img: "Avatar card-7.webp",
        checked: false
    },
    {
        name: "Lauren",
        gender: "Female",
        age: "32",
        img: "Avatar card-8.webp",
        checked: false
    },
    {
        name: "Jennifer",
        gender: "Female",
        age: "21",
        img: "Avatar card-9.webp",
        checked: false
    },
    {
        name: "Amber",
        gender: "Female",
        age: "32",
        img: "Avatar card-10.webp",
        checked: false
    },
];

export default function AvatarsContent({ setDisabled, error, setError }) {

    const [avatarsState, setAvatarsState] = useState(avatars);
    const [selectAll, setSelectAll] = useState(false);

    const dispatch = useDispatch();
    const handleCheckboxClick = (index) => {
        const updatedAvatars = avatarsState.map((avatar, i) =>
            i === index ? { ...avatar, checked: !avatar.checked } : avatar
        );
        setAvatarsState(updatedAvatars);


        const allChecked = updatedAvatars.every(avatar => avatar.checked);
        setSelectAll(allChecked);
        const anyChecked = updatedAvatars.some(avatar => avatar.checked);
        setDisabled(!anyChecked);
        dispatch(setErrorButton(false))
        setError(false)
    };

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        const updatedAvatars = avatarsState.map(avatar => ({ ...avatar, checked: newSelectAll }));
        const anyChecked = updatedAvatars.some(avatar => avatar.checked);
        setDisabled(!anyChecked);
        setAvatarsState(updatedAvatars);
        setSelectAll(newSelectAll);
    };

    return (
        <>   {avatarsState.map((avatar, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

                <AvatarsItem
                    onClick={() => handleCheckboxClick(index)}
                    checked={avatar.checked}
                    name={avatar.name}
                    gender={avatar.gender}
                    age={avatar.age}
                    img={avatar.img}
                    error={error}
                />

            </div>

        ))}

            <div className={`avatars-item avatars-item--all  ${selectAll ? 'checked' : ''}`}
                onClick={handleSelectAll}
            >
                <div className={`avatars-item__checked ${selectAll ? 'checked' : ''} ${error ? 'error' : ''}`}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.30859 6.00764L4.8727 8.31534L10.0009 3.69995" stroke="white" stroke-width="1.6" stroke-linecap="round" />
                    </svg>
                </div>
                <div className="avatars-item__all">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 19.375H9.375C7.63055 19.375 6.75831 19.375 6.04857 19.5903C4.45056 20.075 3.20005 21.3256 2.7153 22.9236C2.5 23.6334 2.5 24.5055 2.5 26.25M19.5 22L23.5 25.5L28.5 19.5903M18.125 9.375C18.125 12.4816 15.6066 15 12.5 15C9.3934 15 6.875 12.4816 6.875 9.375C6.875 6.2684 9.3934 3.75 12.5 3.75C15.6066 3.75 18.125 6.2684 18.125 9.375Z" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    Select all
                </div>

            </div>
        </>
    )
}
