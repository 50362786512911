import React, { useState } from 'react'
import AvatarsItem from '../../components/AvatarsItem/AvatarsItem'
import CreativesItem from '../../components/CreativesItem/CreativesItem';
import { useDispatch } from 'react-redux';
import { setErrorButton } from '../../store/buttonSlice';

const avatars = [
    {
        img: "01.webp",
        checked: false
    }, {
        img: "02.webp",
        checked: false
    }, {
        img: "03.webp",
        checked: false
    }, {
        img: "04.webp",
        checked: false
    }, {
        img: "05.webp",
        checked: false
    }, {
        img: "06.webp",
        checked: false
    }, {
        img: "07.webp",
        checked: false
    }, {
        img: "08.webp",
        checked: false
    }, {
        img: "09.webp",
        checked: false
    }, {
        img: "10.webp",
        checked: false
    }, {
        img: "11.webp",
        checked: false
    }, {
        img: "12.webp",
        checked: false
    }, {
        img: "13.webp",
        checked: false
    }, {
        img: "14.webp",
        checked: false
    }, {
        img: "15.webp",
        checked: false
    }, {
        img: "16.webp",
        checked: false
    }, {
        img: "17.webp",
        checked: false
    }, {
        img: "18.webp",
        checked: false
    }, {
        img: "19.webp",
        checked: false
    }, {
        img: "20.webp",
        checked: false
    }, {
        img: "21.webp",
        checked: false
    }, {
        img: "22.webp",
        checked: false
    }, {
        img: "23.webp",
        checked: false
    }, {
        img: "24.webp",
        checked: false
    },
];

export default function CreativesContent({ setDisabled, error, setError, content }) {
    const [avatarsState, setAvatarsState] = useState(avatars);
    const [selectAll, setSelectAll] = useState(false);
    const dispatch = useDispatch();

    const handleCheckboxClick = (index) => {
        const updatedAvatars = avatarsState.map((avatar, i) =>
            i === index ? { ...avatar, checked: !avatar.checked } : avatar
        );
        setAvatarsState(updatedAvatars);


        const allChecked = updatedAvatars.every(avatar => avatar.checked);
        setSelectAll(allChecked);
        const anyChecked = updatedAvatars.some(avatar => avatar.checked);
        setDisabled(!anyChecked);
        setError(false)
        dispatch(setErrorButton(false))
    };

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        const updatedAvatars = avatarsState.map(avatar => ({ ...avatar, checked: newSelectAll }));
        setAvatarsState(updatedAvatars);
        setSelectAll(newSelectAll);
    };

    return (
        <>   {avatarsState
            .slice(0, content ? 4: avatarsState.length)
            .map((avatar, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <CreativesItem
                        onClick={() => handleCheckboxClick(index)}
                        checked={avatar.checked}
                        name={avatar.name}
                        gender={avatar.gender}
                        age={avatar.age}
                        img={avatar.img}
                        error={error}
                    />
                </div>
            ))}

        </>
    )
}
