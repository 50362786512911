import React, { useEffect, useRef, useState } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './plan.scss';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Promocode from './components/Promocode/Promocode';
import Tariffs from './components/Tariffs/Tariffs';
import MyButton from '../../components/UI/button/MyButton';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


const Plan = () => {

    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [activePlan, setActivePlan] = useState('1');

    const buttonRef = useRef(null);

    const [isShow, setIsShow] = useState(true);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        if (buttonRef.current) {
            gsap.to(buttonRef.current, {
                opacity: 0,
                duration: 0.5,
                scrollTrigger: {
                    trigger: "#price",
                    start: "bottom bottom-=20rem",
                    end: "bottom top",
                    // markers: true,
                    onLeaveBack: () => {
                        setIsShow(true);
                    },
                    onEnter: () => {
                        setIsShow(false);
                    },
                },
            });
        }
    }, [buttonRef]);


    const goals = queryParams.get('goals-value') ? queryParams.get('goals-value').split(',') : [];



    return (
        <div className="plan">
            <div >
                <p className="text" style={{ marginBottom: "12rem" }}>
                    {queryParams.get('name')}, your setup #243848 is ready!
                </p>
                <CustomTitle style={{ marginBottom: "24rem" }} title="Choose a plan to get access to your personal AI copilot  " />
                <div className="plan-item">

                    <div className="plan-item__img">
                        <img src={process.env.PUBLIC_URL + `/img/plan.webp`} alt="" />
                    </div>
                    <div className="plan-item-bottom">
                        <div className="plan-item-bottom-top">
                            <h4>
                                Before AI copilot
                            </h4>
                            <svg width="43" height="24" viewBox="0 0 43 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.07951 19.421C9.49607 19.8375 10.1714 19.8375 10.588 19.421L18.0089 12.0001L10.588 4.57916C10.1714 4.16259 9.49607 4.16259 9.07951 4.57915C8.66295 4.99571 8.66295 5.67109 9.07951 6.08765L14.9919 12.0001L9.07951 17.9125C8.66295 18.329 8.66295 19.0044 9.07951 19.421Z" fill="white" />
                             
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0795 19.421C18.4961 19.8375 19.1714 19.8375 19.588 19.421L27.0089 12.0001L19.588 4.57916C19.1714 4.16259 18.4961 4.16259 18.0795 4.57915C17.663 4.99571 17.663 5.67109 18.0795 6.08765L23.9919 12.0001L18.0795 17.9125C17.663 18.329 17.663 19.0044 18.0795 19.421Z" fill="white" />
                           
                            
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0795 19.421C27.4961 19.8375 28.1714 19.8375 28.588 19.421L36.0089 12.0001L28.588 4.57916C28.1714 4.16259 27.4961 4.16259 27.0795 4.57915C26.663 4.99571 26.663 5.67109 27.0795 6.08765L32.9919 12.0001L27.0795 17.9125C26.663 18.329 26.663 19.0044 27.0795 19.421Z" fill="white" />
                             
                            </svg>
                            <h4>After AI copilot</h4>
                        </div>
                        <div className="plan-item-bottom-wrap">
                            <div className="plan-item-bottom-item">
                                <p>Monthly purchases</p>
                                <h4>{queryParams.get('current')}</h4>

                            </div>
                            <div className="plan-item-bottom-item">
                                <p>Monthly purchases</p>
                                <h4>{queryParams.get('target')}</h4>

                            </div>
                            <div className="plan-item-bottom-item">
                                <p>Sales Growth Tools</p>
                                <h4>Beginer</h4>
                                <div className="plan-item-bottom-item__line">
                                    <span className="white"></span>
                                    <span></span>
                                    <span></span>
                                </div>

                            </div>
                            <div className="plan-item-bottom-item">
                                <p>Sales Growth Tools</p>
                                <h4>Advanced</h4>
                                <div className="plan-item-bottom-item__line">
                                    <span className="white"></span>
                                    <span className="white"></span>
                                    <span className="white"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <ul className="plan-list">
                    {(goals.includes('0') || goals.length === 0) && (
                        <li>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 7.08068L6.92298 10.1614L13.7689 4" stroke="#4DCC8C" stroke-width="2.2885" stroke-linecap="round" />
                            </svg>
                            Increase purchases amount
                        </li>
                    )}

                    {(goals.includes('1') || goals.length === 0) && (
                        <li>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 7.08068L6.92298 10.1614L13.7689 4" stroke="#4DCC8C" stroke-width="2.2885" stroke-linecap="round" />
                            </svg>
                            Lower CPA (cost per action)
                        </li>
                    )}
                    {(goals.includes('2') || goals.length === 0) && (
                        <li>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 7.08068L6.92298 10.1614L13.7689 4" stroke="#4DCC8C" stroke-width="2.2885" stroke-linecap="round" />
                            </svg>
                            Boost ROAS (return on ad spend)
                        </li>
                    )}
                    {(goals.includes('3') || goals.length === 0) && (
                        <li>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 7.08068L6.92298 10.1614L13.7689 4" stroke="#4DCC8C" stroke-width="2.2885" stroke-linecap="round" />
                            </svg>
                            Grow CTR (click through rate)
                        </li>
                    )}


                </ul>
                <Promocode />
                <div id="price">
                    <Tariffs setActivePlan={setActivePlan} activePlan={activePlan} buttonShow={!isShow} />

                </div>
                {isShow && (
                    <a href='#price' className="plan-btn" ref={buttonRef}>
                        <MyButton style={{ width: "100%" }}
                            buttonText={'Get my plan'}
                        ></MyButton>
                    </a>
                )}

                {(queryParams.get('video-ads') || queryParams.get('static-ads') || queryParams.get('ad-campaigns')) &&
                    <>
                        <div className="plan-ai">
                            <h2>AI copilot highlights</h2>
                            {queryParams.get('video-ads') &&
                                <div className="plan-ai-wrap">
                                    <div className="plan-ai-wrap__title">
                                        AI video ads
                                    </div>
                                    {queryParams.get('video-ads') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai1.webp`} alt="" />
                                            <div>
                                                <h4>Viral video ads</h4>
                                                <p>UGC-style video ads generation</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('video-avatars') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai2.webp`} alt="" />
                                            <div>
                                                <h4>Hyper-realistic avatars</h4>
                                                <p>30+ lifelike AI avatars to represent your ads</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('video-templates') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>Dynamic templates</h4>
                                                <p>High quality templates with rich transitions</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('video-scripts') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>Sales-focused scripts</h4>
                                                <p>AI crafted scripts using proven frameworks</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('video-hooks') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>Attention grabbing hooks & CTAs</h4>
                                                <p>Grow CTR and maximize results with AI</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('video-conversion') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>AI conversion widget</h4>
                                                <p>AI copilot to guide through video ad creation</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {queryParams.get('static-ads') &&
                                <div className="plan-ai-wrap">
                                    <div className="plan-ai-wrap__title">
                                        AI static ads
                                    </div>
                                    {queryParams.get('static-ads') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>Quick static ads creation with AI</h4>
                                                <p>Selling static ad in less than 1 minute</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('static-templates') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>100+ tested templates</h4>
                                                <p>AI-picked and regularly updated</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('static-text') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>AI text tools</h4>
                                                <p>Engaging texts and CTAs managed by AI</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('static-editor') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>Drag-n-drop editing</h4>
                                                <p>Easy AI customization without editing skills</p>
                                            </div>
                                        </div>
                                    }

                                    {queryParams.get('static-bulk') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>Bulk creation</h4>
                                                <p>Generate multiple ads at once</p>
                                            </div>
                                        </div>
                                    }

                                </div>
                            }

                            {queryParams.get('ad-campaigns') &&
                                <div className="plan-ai-wrap">
                                    <div className="plan-ai-wrap__title">
                                        AI ad campaigns
                                    </div>
                                    {queryParams.get('ad-campaigns') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai1.webp`} alt="" />
                                            <div>
                                                <h4>Smart AI campaigns</h4>
                                                <p>Less than 15 minutes to launch AI campaign</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('ad-channels') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai2.webp`} alt="" />
                                            <div>
                                                <h4>Ad platforms setup</h4>
                                                <p>AI handles platform setups seamlessly</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('ad-targeting') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>Smart AI targeting</h4>
                                                <p>Find buyers for your product with AI</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('ad-optimization') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>AI-optimized ads</h4>
                                                <p>Launch mistake-free ad campaigns with AI</p>
                                            </div>
                                        </div>
                                    }
                                    {queryParams.get('ad-reports') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>Real-time reporting</h4>
                                                <p>24/7 personalized AI-driven insights</p>
                                            </div>
                                        </div>
                                    }


                                    {queryParams.get('ad-copilot') &&
                                        <div className="plan-ai-item">
                                            <img src={process.env.PUBLIC_URL + `/img/plan/ai3.webp`} alt="" />
                                            <div>
                                                <h4>AI ads copilot</h4>
                                                <p>Let AI setup and boost your ads</p>
                                            </div>
                                        </div>
                                    }

                                </div>
                            }

                        </div>
                        <Tariffs setActivePlan={setActivePlan} activePlan={activePlan} buttonShow={true} />
                    </>
                }


                <div className="plan-money">
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.2308 58.6666C31.9006 58.6666 31.5704 58.5908 31.2724 58.4365L21.6695 53.4656C18.9446 52.0536 16.813 50.4689 15.1485 48.6228C11.5055 44.5854 9.47857 39.4026 9.44635 34.0264L9.3336 16.3313C9.32017 14.2891 10.6383 12.4561 12.6088 11.7657L30.2415 5.61808C31.2885 5.24415 32.4563 5.23892 33.5221 5.59978L51.2219 11.5382C53.2031 12.1998 54.5428 14.0198 54.5535 16.0594L54.6663 33.7675C54.7012 39.1359 52.7441 44.3396 49.1574 48.4215C47.5117 50.2937 45.3989 51.9019 42.7009 53.3401L33.1839 58.4235C32.8885 58.583 32.561 58.664 32.2308 58.6666" fill="#5BF0A5" fill-opacity="0.2" />
                        <path d="M30.1846 38.189C29.6692 38.1917 29.1537 38.006 28.7564 37.6242L23.6449 32.7082C22.8556 31.9447 22.8475 30.7052 23.6288 29.9364C24.41 29.165 25.6852 29.1572 26.4772 29.9181L30.1551 33.4535L39.1352 24.5994C39.9191 23.828 41.1943 23.8201 41.9836 24.5811C42.7756 25.3446 42.7836 26.5867 42.0024 27.3529L31.6048 37.6059C31.2129 37.9929 30.7001 38.1864 30.1846 38.189" fill="#5BF0A5" />
                    </svg>
                    <div className="plan-money__title">
                        100% Money-Back Guarantee
                    </div>
                    <p>
                        We’re so confident that Zeely AI can help you improve your sales that we’ll return your money if  you don’t see visible results in 4 weeks (and can prove that you followed our plan).
                    </p>
                    <div className="plan-money-bottom">
                        <h4>
                            Guaranteed safe checkout
                        </h4>
                        <img src={process.env.PUBLIC_URL + `/img/cards.svg`} alt="" />
                    </div>
                </div>
                <div className="plan-bottom">
                    <p>2024 © All rights reserved<br></br><br></br>
                        Zeely, Inc.<br></br>
                        2810 N Church St, Ste 27833, Wilmington, <br></br>
                        DE 19802-4447, United States
                    </p>
                    <ul>
                        <li>
                            <a href="#">Legal Center</a>
                        </li>
                        <li>
                            <a href="#">Terms & Conditions</a>
                        </li>
                        <li>
                            <a href="#">Privacy Policy</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default Plan;
