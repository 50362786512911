import React, { useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setButtonClick, setIconButton, setIsShowButton, setTextButton } from '../../store/buttonSlice';
import './style.scss';
import MyButtonWhite from '../../components/UI/button/MyButtonWhite';
import CreativeQuizContent from './CreativeQuizContent';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setButtonClickNot, setIconButtonNot, setIsShowButtonNot, setTextButtonNot } from '../../store/buttonNotSlice';

export default function CreativeQuiz() {

    const dispatch = useDispatch();
    const history = useHistory();
    const buttonClicked = useSelector((state) => state.button.buttonClicked);
    const nextPage = useSelector((state) => state.appRoute.nextPage);
    const buttonNotClicked = useSelector((state) => state.buttonNot.buttonClicked);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (buttonClicked) {
            dispatch(setButtonClick(false));
            queryParams.set('static-ads', 1);
            history.push({
                pathname: nextPage,
                search: `?${queryParams.toString()}`
            });
        }
    }, [buttonClicked, dispatch]);

    useEffect(() => {
        if (buttonNotClicked) {
            dispatch(setButtonClickNot(false));
            history.push({
                pathname: '/ad-campaigns',
                search: `?${queryParams.toString()}`
            });
        }
    }, [buttonNotClicked, dispatch]);

    useEffect(() => {
        dispatch(setIsShowButton(true));
        dispatch(setTextButton('Let’s do it'));
        dispatch(setIconButton('Tick'));

        dispatch(setIsShowButtonNot(true));
        dispatch(setTextButtonNot('Not sure'));
        dispatch(setIconButtonNot('not'));
    }, []);


    return (
        <div className="page creative-quiz page--full">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '12rem' }}
                    title={'Would you like to have quick static ads made with AI?'}
                />
                <p className="text" style={{ marginBottom: '24rem' }}>
                    Takes 1 min. AI handles writing and generating visuals.
                </p>
                <div className="creative-quiz-wrapper">
                    <CreativeQuizContent />
                </div>

            </div>
        </div>
    );
}
