import React, { useState, useEffect } from "react";
import CustomTitle from "../../components/UI/title/CustomTitle";
import MyButton from "../../components/UI/button/MyButton";
import "./style.scss";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { setIsShowPrivacy } from "../../store/termSlice";
import { useCheckSubscriptionQuery, useRegisterMutation } from "../../api/dataApi";
import CryptoJS from 'crypto-js';

const Email = ({ save }) => {
  const history = useHistory();
  const { brandName } = useSelector((state) => state.scrapping);
  const dispatch = useDispatch();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [name, setName] = useState(save ? brandName : "");

  const handleInputChange = (event) => {
    const newValue = event.target.value.replace(/\s+/g, "");
    setName(newValue);
    setError("");
  };

  useEffect(() => {
    if (name == "") {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [name]);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;

  const [error, setError] = useState("");


  const privacyChange = () => {
    dispatch(setIsShowPrivacy(true));
  };

  const [register, { isLoading, isError, isSuccess, errorRegister }] = useRegisterMutation();

  const { data: subscriptionData, error: subscriptionError, isLoading: isSubscriptionLoading } = useCheckSubscriptionQuery(name);



  // const { data: subscriptionsData, error: subscriptionsError, isLoading: isSubscriptions } = useSubscriptionsCheckQuery(name)

  const secretKey = 'onrD(}7zNTP%onp>)/wB9';

  const encryptText = (text) => {
    const ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString();
    return ciphertext;
  };

  const decryptText = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const original = bytes.toString(CryptoJS.enc.Utf8);
    return original;
  };



  const handleRegister = async () => {
    try {
      const response = await register(name).unwrap();
      console.log('Registration successful:', response);


      const encrypted = await encryptText(response.code, secretKey);
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      queryParams.set('code', encrypted);
      queryParams.set('email', name);
      queryParams.set('new-account', 1);
      history.push({
        pathname: '/predict',
        search: `?${queryParams.toString()}`,
      });

    } catch (err) {

      if (err.data && err.data.message === "User already exist") {
        const dataSubscription = await subscriptionData;
        queryParams.set('email', name);
        history.push({
          pathname: dataSubscription?.data?.hasSubscription === true ? '/welcome' : '/predict',
          search: `?${queryParams.toString()}`,
        });
      }
      console.error('Registration failed:', err);
    }
  };

  return (
    <div className="emailPage">
      <div className="emailPage-top">
        <CustomTitle
          style={{ marginBottom: "24rem" }}
          title={`${queryParams.get('name')}, enter your email to get access to your personal AI marketing copilot`}
        />
        <div
          className={`input ${error ? "error" : ""}`}
          style={{ paddingLeft: "46rem", paddingRight: "16rem" }}
        >
          <input
            type="text"
            placeholder="Email"
            value={name}
            onChange={handleInputChange}
            autocapitalize="none"
          />
          <div className={`input-border ${name === '' ? 'empty' : ''}`}></div>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.80569 2.85718L14.412 2.85845C15.5369 2.87209 16.1238 2.99766 16.7325 3.32321C17.3226 3.6388 17.7899 4.10617 18.1055 4.69626C18.4514 5.34303 18.5716 5.96516 18.5716 7.23416V12.7659C18.5716 14.0349 18.4514 14.657 18.1055 15.3038C17.7899 15.8939 17.3226 16.3613 16.7325 16.6769C16.0857 17.0228 15.4636 17.1429 14.1946 17.1429H5.80569C4.53669 17.1429 3.91456 17.0228 3.26779 16.6769C2.6777 16.3613 2.21033 15.8939 1.89475 15.3038C1.54885 14.657 1.42871 14.0349 1.42871 12.7659L1.42998 7.01679C1.44363 5.89188 1.5692 5.30499 1.89475 4.69626C2.21033 4.10617 2.6777 3.6388 3.26779 3.32321C3.91456 2.97732 4.53669 2.85718 5.80569 2.85718ZM16.9195 5.52005L12.0204 10.4185C10.9419 11.497 9.21545 11.533 8.09367 10.5263L7.97983 10.4185L3.08073 5.52005C2.91686 5.88774 2.85728 6.30832 2.85728 7.23416V12.7659C2.85728 13.8192 2.9344 14.2186 3.15448 14.6301C3.33692 14.9712 3.60037 15.2347 3.9415 15.4171C4.30461 15.6113 4.65823 15.6942 5.46 15.711L5.80569 15.7143H14.1946C15.2479 15.7143 15.6473 15.6372 16.0588 15.4171C16.3999 15.2347 16.6634 14.9712 16.8458 14.6301C17.04 14.267 17.1229 13.9134 17.1397 13.1116L17.143 12.7659V7.23416C17.143 6.30832 17.0834 5.88774 16.9195 5.52005ZM14.1946 4.28575H5.80569C4.87985 4.28575 4.45928 4.34533 4.09158 4.5092L8.98999 9.4083C9.51852 9.93683 10.3581 9.96465 10.9194 9.49175L11.0103 9.4083L15.9087 4.5092C15.541 4.34533 15.1204 4.28575 14.1946 4.28575Z" fill="#CAD4DD" />
          </svg>
          <span style={{ left: "46rem" }}>Email</span>
          {error && <div className="error-text" style={{ marginLeft: '-46rem' }}>{error}</div>}
        </div>
      </div>
      <div className="emailPage-bottom">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.7688 9.21381H16.2312C18.5886 9.21381 20.5 11.083 20.5 13.3885V18.3253C20.5 20.6308 18.5886 22.5 16.2312 22.5H7.7688C5.41136 22.5 3.5 20.6308 3.5 18.3253V13.3885C3.5 11.083 5.41136 9.21381 7.7688 9.21381ZM11.9949 17.8294C12.4928 17.8294 12.8891 17.4419 12.8891 16.955V14.7489C12.8891 14.2719 12.4928 13.8843 11.9949 13.8843C11.5072 13.8843 11.1109 14.2719 11.1109 14.7489V16.955C11.1109 17.4419 11.5072 17.8294 11.9949 17.8294Z" fill="#CAD4DD" />
          <path opacity="0.4" d="M17.523 7.89595V9.36667C17.1673 9.2673 16.7913 9.21761 16.4052 9.21761H15.7447V7.89595C15.7447 5.87868 14.0681 4.23903 12.0053 4.23903C9.94257 4.23903 8.26594 5.86874 8.25578 7.87608V9.21761H7.60545C7.20916 9.21761 6.83319 9.2673 6.47754 9.37661V7.89595C6.4877 4.91476 8.95692 2.5 11.985 2.5C15.0537 2.5 17.523 4.91476 17.523 7.89595Z" fill="#CAD4DD" />
        </svg>
        <p>
          We respect your privacy and are committed to protecting your personal data. Your data will be processed in accordance with our <span onClick={privacyChange}>Privacy Policy.</span>
        </p>
      </div>
      <MyButton
        onClick={() => {
          if (isButtonDisabled) {
            setError("Please enter your email");
            return;
          }
          if (!emailRegex.test(name)) {
            setError("That email doesn't look right. Please correct it.");
            return;
          }
          handleRegister()
        }}
        className={`${isButtonDisabled ? 'disable' : ''} ${error ? 'error' : ''}`}
        buttonIcon={""}
        buttonText={`Continue`}
        buttonStatus={isLoading ? "load" : ""}
      ></MyButton>
    </div>
  );
};

export default Email;
