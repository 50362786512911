import React, { useState } from 'react'
import CustomRadio from '../../components/UI/radio/CustomRadio';
import { setErrorButton } from '../../store/buttonSlice';
import { useDispatch } from 'react-redux';

export default function CurrentContent({ checkboxes, setCheckboxes, error, setDisabled, setError, setValue, setValueId }) {

    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(null);

    const handleCheckboxChange = (checkbox) => {
        setSelectedOption(checkbox.id);
        setDisabled(false)
        setError(false);
        dispatch(setErrorButton(false))
        setValue(checkbox.text)
        setValueId(checkbox.id)
    };

    return (

        <>
            {checkboxes && checkboxes.map((checkbox) => (
                <CustomRadio
                    key={checkbox.id}
                    id={checkbox.id}
                    checked={selectedOption === checkbox.id}
                    onChange={() => handleCheckboxChange(checkbox)}
                    className={error ? 'error' : undefined}
                    style={{ marginBottom: '8rem' }}
                >
                    {checkbox.text}
                </CustomRadio>
            ))}</>
    )
}
