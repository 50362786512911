import React, { useEffect, useState } from "react";
import CustomTitle from "../../components/UI/title/CustomTitle";
import Loader from "../../components/Loader/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import './style.scss';

export default function Home({isStart}) {
  const history = useHistory();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const target = 100;
    const intervalTime = 40;

    const interval = setInterval(() => {
      if(isStart) {
        setProgress((prev) => (prev < target ? prev + 1 : target));
      }
      
    }, intervalTime);

    return () => clearInterval(interval);
  }, [progress, isStart]);

  useEffect(() => {
    if (progress === 100) {
      history.push("/stage");
    }
  }, [progress]);

  return (
    <div className="page page--without-button">
      <div className="page-top">
        <CustomTitle
          style={{ marginBottom: "12rem" }}
          title="See how fast you can<br>create video ads & grow<br>
your sales with AI copilot"
        />
        <p className="text" style={{ marginBottom: "24rem" }}>
          Take the FREE quiz to set up your personalized AI marketing assistant
          tailored to your goals
        </p>
<div style={{ 
  aspectRatio: "335/294",
  background: "#fcfdff",
  border: "1px solid rgba(242, 244, 246, 0.5)",
  borderRadius: "20px"
}}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/img/WEBP_Loader-01/Videos in Minutes.webp"
            }
            alt=""
          />
        </div>
      </div>

      <Loader title={"Loading the quiz..."} progress={progress} />
    </div>
  );
}
