import React, { useEffect } from 'react'
import CustomTitle from '../../components/UI/title/CustomTitle'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import './style.scss';
import MyButton from '../../components/UI/button/MyButton';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { CustomEase } from 'gsap/all';

export default function Predict({ isStart }) {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const currentDate = new Date();
    const daysToAdd = parseInt(queryParams.get('days'), 10);
    const futureDate = new Date(currentDate.setDate(currentDate.getDate() + daysToAdd));
    const futureMonth = futureDate.toLocaleString('en-US', { month: 'long' });
    const futureYear = futureDate.getFullYear();
    const weeksToAdd = Math.floor(daysToAdd / 7);



    gsap.registerPlugin(MotionPathPlugin);
    gsap.registerPlugin(CustomEase);
    useEffect(() => {
        if (isStart) {
            gsap.set("#path", {
                strokeDasharray: "1000",
                strokeDashoffset: "1000",
                stroke: "#5BF0A5"
            });

            gsap.to("#gradient-container", {
                duration: 3,
                width: "293rem",
                ease: CustomEase.create("custom", "M0,0 C0.208,0.147 0.472,0.455 0.496,0.496 0.574,0.63 0.774,0.715 1,1 "),


            });


            gsap.to("#path", {
                duration: 3,
                strokeDashoffset: 650,
                ease: CustomEase.create("custom", "M0,0 C0.208,0.147 0.472,0.455 0.496,0.496 0.574,0.63 0.774,0.715 1,1 "),

            });


            gsap.fromTo(
                "#circle",
                {
                    opacity: 0,
                    scale: 0,
                    transformOrigin: "50% 50%",
                },
                {
                    opacity: 1,
                    scale: 1,
                    delay: 3,
                    duration: .5,
                    ease: "power1.inOut"
                }
            );
        }

        // gsap.to("#groupContainer", {
        //     duration: 3,
        //     scaleX: 2, // Увеличение ширины группы
        //     transformOrigin: "0% 50%", // Точка трансформации
        //     ease: "power1.inOut",
        //     // repeat: -1,
        //     yoyo: true
        // });
        // gsap.to("#path", {
        //     duration: 5,
        //     ease: "power1.inOut",
        //     motionPath: {
        //         path: "#path",
        //         align: "#path",
        //         autoRotate: true,
        //         alignOrigin: [0.5, 0.5]
        //     }
        // });
    }, [isStart]);

    return (
        <div className="page predict page--without-button">
            <div className="page-top">
                <p className="text" style={{ marginBottom: '12rem' }}>
                    {queryParams.get('name')}, your setup #243848 is ready!
                </p>
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title={`AI copilot will help you achieve up to 750 monthly purchases by ${futureMonth} ${futureYear}`}
                />

                <div className="predict-item">
                    <div className="predict-item-top">
                        <span>Purchases amount:</span>
                        <span>Goal: 750+</span>
                    </div>
                    <div className="predict-item__img">
                        <div className="predict-item__img--gradient" id="gradient-container">
                            <div id="gradient-container">
                                <svg width="293" height="170" viewBox="0 0 293 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    {/* <path d="M15 161C35.9746 158.334 70.9322 166.359 98.008 109.154C131.853 37.6465 157.536 40.6813 213.676 44.6728C254.479 47.5738 278.924 20.0683 290 1" stroke="#F2F4F6" stroke-width="3.14635" /> */}
                                    <g clip-path="url(#clip0_43_4463)">
                                        <path d="M11 161C32.4322 158.334 68.1525 166.359 95.8191 109.154C130.402 37.6465 156.645 40.6813 214.011 44.6728C255.704 47.5738 280.682 20.0683 292 1" stroke="#5BF0A5" stroke-width="3.14635" />
                                        <path d="M97.2912 109.225C71.1015 166.468 31.2881 158.437 11 161.105V170H291.5V1C280.786 20.0808 248.642 47.6044 209.174 44.7015C154.871 40.7074 130.028 37.6707 97.2912 109.225Z" fill="url(#paint0_linear_43_4463)" />
                                        {/* <path d="M293 161L293 170" stroke="#5BF0A5" /> */}
                                    </g>
                                    {/* <circle cx="9.2927" cy="159" r="7.34148" fill="#5BF0A5" stroke="white" stroke-width="2.09757" /> */}
                                    <defs>
                                        <linearGradient id="paint0_linear_43_4463" x1="87.1367" y1="6.75542" x2="161.814" y2="191.61" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#5BF0A5" />
                                            <stop offset="1" stop-color="white" stop-opacity="0" />
                                        </linearGradient>
                                        <clipPath id="clip0_43_4463">
                                            <rect width="282" height="169" fill="white" transform="translate(11 1)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>




                        </div>
                        <svg width="335" height="197" viewBox="0 0 335 197" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.6" d="M0 46.707H334.562" stroke="url(#paint0_linear_43_4434)" stroke-width="1.04878" />
                            <path opacity="0.7" d="M0 83.4141H334.562" stroke="url(#paint1_linear_43_4434)" stroke-width="1.04878" />
                            <path opacity="0.8" d="M0 120.121H334.562" stroke="url(#paint2_linear_43_4434)" stroke-width="1.04878" />
                            <path opacity="0.9" d="M0 156.83H334.562" stroke="url(#paint3_linear_43_4434)" stroke-width="1.04878" />
                            <path d="M0 193.537H334.562" stroke="url(#paint4_linear_43_4434)" stroke-width="1.04878" />
                            <g clip-path="url(#clip0_43_4434)">
                                <rect x="26.5117" y="185.146" width="1.04878" height="8.39026" rx="0.524391" fill="#CAD4DD" />
                            </g>
                            <rect x="308.562" y="30.9746" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="41.4624" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="51.9503" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="62.4381" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="72.9259" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="83.4137" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="93.9016" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="104.389" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="114.877" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="125.365" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="135.853" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="146.341" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="156.828" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="167.316" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="177.804" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <rect x="308.562" y="188.292" width="1.04878" height="5.24391" rx="0.524391" fill="#CAD4DD" />
                            <path d="M32 184C52.9746 181.334 87.9322 189.359 115.008 132.154C148.853 60.6465 174.536 63.6813 230.676 67.6728C271.479 70.5738 295.924 43.0683 307 24" stroke="#F2F4F6" stroke-width="3.14635" />
                            <g clip-path="url(#clip1_43_4434)">
                                {/* <path d="M28 184C49.4322 181.334 85.1525 189.359 112.819 132.154C147.402 60.6465 173.645 63.6813 231.011 67.6728C272.704 70.5738 297.682 43.0683 309 24" stroke="#5BF0A5" stroke-width="3.14635" />
                                <path d="M114.291 132.225C88.1015 189.468 48.2881 181.437 28 184.105V193H308.5V24C297.786 43.0808 265.642 70.6044 226.174 67.7015C171.871 63.7074 147.028 60.6707 114.291 132.225Z" fill="url(#paint5_linear_43_4434)" /> */}
                                <path d="M310 184L310 193" stroke="#5BF0A5" />
                            </g>
                            <circle cx="26.2927" cy="182" r="7.34148" fill="#5BF0A5" stroke="white" stroke-width="2.09757" />
                            <g id='circle'> <circle cx="308.293" cy="25.2927" r="6.2927" fill="#5BF0A5" />
                            <circle cx="308.293" cy="25.2927" r="11.7927" stroke="#5BF0A5" stroke-opacity="0.5" stroke-width="11" />
                            <circle cx="308.293" cy="25.2927" r="15.2927" stroke="#5BF0A5" stroke-opacity="0.2" stroke-width="18" />
                            <circle cx="308.293" cy="25.2927" r="7.2927" stroke="white" stroke-width="2" /></g>
                           
                            <defs>
                                <linearGradient id="paint0_linear_43_4434" x1="0" y1="47.207" x2="334.562" y2="47.207" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#F2F4F6" stop-opacity="0.2" />
                                    <stop offset="0.33" stop-color="#F2F4F6" />
                                    <stop offset="1" stop-color="#F2F4F6" stop-opacity="0.2" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_43_4434" x1="0" y1="83.9141" x2="334.562" y2="83.9141" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#F2F4F6" stop-opacity="0.2" />
                                    <stop offset="0.33" stop-color="#F2F4F6" />
                                    <stop offset="1" stop-color="#F2F4F6" stop-opacity="0.2" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_43_4434" x1="0" y1="120.621" x2="334.562" y2="120.621" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#F2F4F6" stop-opacity="0.2" />
                                    <stop offset="0.33" stop-color="#F2F4F6" />
                                    <stop offset="1" stop-color="#F2F4F6" stop-opacity="0.2" />
                                </linearGradient>
                                <linearGradient id="paint3_linear_43_4434" x1="0" y1="157.33" x2="334.562" y2="157.33" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#F2F4F6" stop-opacity="0.2" />
                                    <stop offset="0.33" stop-color="#F2F4F6" />
                                    <stop offset="1" stop-color="#F2F4F6" stop-opacity="0.2" />
                                </linearGradient>
                                <linearGradient id="paint4_linear_43_4434" x1="0" y1="194.037" x2="334.562" y2="194.037" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#F2F4F6" stop-opacity="0.2" />
                                    <stop offset="0.33" stop-color="#F2F4F6" />
                                    <stop offset="1" stop-color="#F2F4F6" stop-opacity="0.2" />
                                </linearGradient>
                                <linearGradient id="paint5_linear_43_4434" x1="104.137" y1="29.7554" x2="178.814" y2="214.61" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5BF0A5" />
                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                </linearGradient>

                            </defs>
                        </svg>


                        {/* <img src={process.env.PUBLIC_URL + `/img/Graph-line.svg`} alt="" /> */}
                    </div>

                    <div className="predict-item-bottom">
                        <span>Week1</span>
                        <span>Week {weeksToAdd}</span>
                    </div>
                </div>
            </div>
            <MyButton
                onClick={() => {
                    history.push({
                        pathname: '/plan',
                        search: `?${queryParams.toString()}`
                    });
                }}
                buttonIcon={''}
                buttonText={`Get access to AI copilot`}></MyButton>
        </div>
    )
}
