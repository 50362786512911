import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Wizard, Steps, Step, WithWizard } from 'react-albus-react18';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';
import axios from 'axios';
import John from './pages/John';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';
import MyButton from './components/UI/button/MyButton';
import Signup from './pages/Signup/Signup';
import Trial from './pages/Trial/Trial';
import Bundle from './pages/Bundle/Bundle';
import Graph from './pages/Graph/Graph';
import Downgrade from './pages/Downgrade';
import Website from './pages/Website/Website';
import BrandName from './pages/BrandName';
import Term from './pages/Term';
import Privacy from './pages/Privacy';
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from '@mui/material';
import WelcomeToZeely from './pages/WelcomeToZeely/WelcomeToZeely';
import Signup2 from './pages/Signup/Signup2';
import CheckEmail from './components/CheckEmail/CheckEmail';
import BackHistory from './components/BackHistory/BackHistory';
import ReactPlayer from 'react-player';
import TermPage from './pages/TermPage';
import PrivacyPage from './pages/PrivacyPage';
import mixpanel from 'mixpanel-browser';
import PriceRoute from './pages/PriceNew/PriceRoute';
import useOverflow from './hooks/useOverflow';
import useBlockClassName from './hooks/useBlockClassName';
import Welcome1 from './pages/Welcome/Welcome1';
import Header from './components/Header/Header';
import WebsiteLoader from './pages/WebsiteLoader/WebsiteLoader';
import CheckDetails from './pages/CheckDetails/CheckDetails';
import AIAnalyzer from './pages/AIAnalyzer/AIAnalyzer';
import { useDispatch, useSelector } from 'react-redux';
import { setIsShowPrivacy, setIsShowTerm } from './store/termSlice';
import BusinessArea from './pages/BusinessArea';
import Name from './pages/Name/Name';
import WebsiteLoaderImg from './pages/WebsiteLoader/components/WebsiteLoaderImg/WebsiteLoaderImg';
import AiAnalyzerImg from './pages/AIAnalyzer/components/AiAnalyzerImg/AiAnalyzerImg';
import Sell from './pages/Sell';
import CompanyName from './pages/CompanyName/CompanyName';
import Email from './pages/Email/Email';
import Home from './pages/Home/Home';
import Stage from './pages/Stage/Stage';
import Products from './pages/Products/Products';
import Featured from './pages/Featured/Featured';
import VideoQuiz from './pages/VideoQuiz/VideoQuiz';
import StageContent from './pages/Stage/StageContent';
import ProductsContent from './pages/Products/ProductsContent';
import VideosContent from './pages/VideoQuiz/VideosContent';
import Avatars from './pages/Avatars/Avatars';
import AvatarsContent from './pages/Avatars/AvatarsContent';
import VideoTemplate from './pages/VideoTemplate/VideoTemplate';
import VideoTemplateContent from './pages/VideoTemplate/VideoTemplateContent';
import CreativeQuiz from './pages/CreativeQuiz/CreativeQuiz';
import Creatives from './pages/Creatives/Creatives';
import CreativeQuizContent from './pages/CreativeQuiz/CreativeQuizContent';
import { setButtonClick } from './store/buttonSlice';
import SmartAi from './pages/SmartAi/SmartAi';
import Channels from './pages/Channels/Channels';
import ChannelsContent from './pages/Channels/ChannelsContent';
import SmartAiContent from './pages/SmartAi/SmartAiContent';
import { setButtonClickNot } from './store/buttonNotSlice';
import MyButtonWhite from './components/UI/button/MyButtonWhite';
import Platfroms from './pages/Platforms/Platfroms';
import PlatfromsContent from './pages/Platforms/PlatformsContent';
import Goals from './pages/Goals/Goals';
import GoalsContent from './pages/Goals/GoalsContent';
import Current from './pages/Current/Current';
import Target from './pages/Target/Target';
import Reach from './pages/Reach/Reach';
import Reviews from './pages/Reviews/Reviews';
import Predict from './pages/Predict/Predict';
import Plan from './pages/Plan/Plan';
import FeaturedContent from './pages/Featured/FeaturedContent';
import { preload } from 'react-dom';
import CreativesContent from './pages/Creatives/CreativesContent';
import ReviewsContent from './pages/Reviews/ReviewsContent';
import FinalOffer from './pages/FinalOffer/FinalOffer';
import FinalPlan from './pages/Plan/FinalPlan';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
import MyInput from './components/UI/input/MyInput';
import AIAnalyzerContent from './pages/AIAnalyzer/AIAnalyzerContent';

function App({ isStart }) {
    const { overflow, changeOverflow } = useOverflow();
    const { blockClassName } = useBlockClassName();
    const isShowTerm = useSelector((state) => state.term.isShowTerm);
    const isShowPrivacy = useSelector((state) => state.term.isShowPrivacy);
    const buttonShow = useSelector((state) => state.button.isShow);
    const buttonText = useSelector((state) => state.button.text);
    const buttonIcon = useSelector((state) => state.button.icon);
    const buttonError = useSelector((state) => state.button.error);
    const buttonNotShow = useSelector((state) => state.buttonNot.isShow);
    const buttonNotText = useSelector((state) => state.buttonNot.text);
    const buttonNotIcon = useSelector((state) => state.buttonNot.icon);

    const dispatch = useDispatch();

    function setSessionIdLocalStorage(sessionId) {
        localStorage.setItem('session_id', sessionId);
    }

    function getSessionIdFromLocalStorage() {
        return localStorage.getItem('session_id');
    }

    function generateSessionId() {
        return Math.random().toString(36).substring(2);
    }

    const sessionId = getSessionIdFromLocalStorage() || generateSessionId();
    setSessionIdLocalStorage(sessionId);

    useEffect(() => {
        mixpanel.init('e777f1e7a8a69795e4b701389d9859a7', {
            ignore_dnt: true,
            debug: true,
            track_pageview: false,
            persistence: 'localStorage'
        });
        mixpanel.identify(sessionId);

        const isFirstSession = !localStorage.getItem('mixpanel_first_session');

        if (isFirstSession) {
            const userAgent = navigator.userAgent;
            const deviceType = detectDeviceType(userAgent);

            const installationDate = new Date();

            const formattedInstallationDate = formatDate(installationDate);

            localStorage.setItem('installation_date', formattedInstallationDate);

            mixpanel.track('SESSION_STARTED', {
                session_id: sessionId,
                // device_type: deviceType,
                session_start: formattedInstallationDate,
                // installation_source: window.location.hostname,
                web_utm_campaign: localStorage.getItem('utmCampaign'),
                web_utm_source: localStorage.getItem('utmSource'),
                web_utm_medium: localStorage.getItem('utmMedium'),
                web_utm_content: localStorage.getItem('utmContent'),
                web_utm_term: localStorage.getItem('utmTerm'),
                isDebug: false,
                funnel: 'MAIN_v1'
            });

            localStorage.setItem('mixpanel_first_session', 'true');
        }
    }, []);

    const formatDate = (date) => {
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);

        return `${month}.${day}.${year} ${hours}:${minutes}:${seconds}`;
    };

    const detectDeviceType = (userAgent) => {
        if (/Android/i.test(userAgent)) {
            return 'Android';
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            return 'iOS';
        } else if (/Windows Phone/i.test(userAgent)) {
            return 'Windows Phone';
        } else if (/Windows/i.test(userAgent)) {
            return 'Windows';
        } else if (/Macintosh|Mac OS/i.test(userAgent)) {
            return 'Macintosh';
        } else if (/Linux/i.test(userAgent)) {
            return 'Linux';
        } else {
            return 'Unknown';
        }
    };

    function saveUTMToLocalStorage() {
        const queryParams = new URLSearchParams(window.location.search);
        const utmSource = queryParams.get('utm_source');
        const utmMedium = queryParams.get('utm_medium');
        const utmCampaign = queryParams.get('utm_campaign');
        const utmContent = queryParams.get('utm_content');
        const utmTerm = queryParams.get('utm_term');

        if (utmSource) localStorage.setItem('utmSource', utmSource);
        if (utmMedium) localStorage.setItem('utmMedium', utmMedium);
        if (utmCampaign) localStorage.setItem('utmCampaign', utmCampaign);
        if (utmContent) localStorage.setItem('utmContent', utmContent);
        if (utmTerm) localStorage.setItem('utmTerm', utmTerm);
    }

    useEffect(() => {
        saveUTMToLocalStorage();
    }, []);

    const history = useHistory();

    const customAnimation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

    const [buttonTime, setButtonTime] = useState(0);
    const [buttonChoose, setButtonChoose] = useState(false);
    const [creativeId, setCreativeId] = useState('');
    const [creativeType, setCreativeType] = useState('');
    const [buttonStatus, setButtonStatus] = useState('');
    const [loginChangeStatus, setLoginChangeStatus] = useState(false);
    const [buttonSkip, setButtonSkip] = useState(false);
    const nextPageButtonRef = useRef(null);
    const [aiShow, setAiShow] = useState(false);
    const [homeShow, sethomeShow] = useState(false);
    const [handShow, setHandShow] = useState(false);
    const [blockStyle, setBlockStyle] = useState({});
    const [welcometoShow, setWelcometo] = useState(false);
    const [WelcomeWrapper, setWelcomeWrapper] = useState(false);
    const [showBrandName, setShowBrandName] = useState(false);
    const [showWebSite, setShowWebSite] = useState(false);

    const [timeLeft, setTimeLeft] = useState(30);
    const [isChecked, setIsChecked] = useState(true);
    const [showTerm, setShowTerm] = useState(false);
    const [showTermBundle, setShowTermBundle] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showBlock, setShowBlock] = useState(true);
    const [signupBottom, setSignupBottom] = useState('');
    const [priceBottomShow, setPriceBottomShow] = useState(false);

    const [muteStatus, setMuteStatus] = useState(true);

    const [blockHeight, setBlockHeight] = useState('auto');
    const [blockPaddingBottom, setBlockPaddingBottom] = useState('0rem');
    const [whiteWrapper, setWhiteWrapper] = useState(false);
    const blockHeightRef = useRef(null);

    const [checkboxes, setCheckboxes] = useState({
        Facebook: true
    });
    const [error, setError] = useState(false);
    const [selectedOption, setSelectedOption] = useState('1');

    const areAllCheckboxesUnchecked = () => {
        return Object.values(checkboxes).every((isChecked) => !isChecked);
    };

    const areAllRadioUnchecked = () => {
        return selectedOption === null;
    };

    const [caseStatus, setCaseStatus] = useState(true);
    const [caseName, setCaseName] = useState('case1');
    useEffect(() => {
        if (localStorage.getItem('direction')) {
            if (localStorage.getItem('direction') == 'SERVICE') {
                setCaseStatus(false);
                setCaseName('case2');
            } else {
                setCaseStatus(true);
                setCaseName('case1');
            }
        }
    }, [localStorage.getItem('direction')]);

    const forgetPassword = (email) => {
        const data = {
            email: email
        };

        axios
            .post('https://staging.zeely.link/users/forgot-password', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow'
            })
            .then((response) => {})
            .catch((error) => {});
    };

    const [eventProperties, setEventProperties] = useState([]);
    const [eventName, setEventName] = useState([]);
    const [WebsiteStatus, setWebsiteStatus] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('haveWebsite')) {
            if (localStorage.getItem('haveWebsite') == 'No') {
                setWebsiteStatus(false);
            }
            if (localStorage.getItem('haveWebsite') == 'Yes') {
                setWebsiteStatus(true);
            }
        }
    }, []);

    const handleClickAi = () => {
        if (nextPageButtonRef.current) {
            // nextPageButtonRef.current.click();
        }
    };

    // const SignupElement2 = <Signup2 forgetPassword={forgetPassword} setWelcomeWrapper={setWelcomeWrapper} setBlockPaddingBottom={setBlockPaddingBottom} sessionId={sessionId} setShowBrandName={setShowBrandName} setShowWebSite={setShowWebSite} setWelcometo={setWelcometo} setWhiteWrapper={setWhiteWrapper} setLoginChangeStatus={setLoginChangeStatus} setSelectedOption={setSelectedOption} setError={setError} error={error} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} setButtonShow={setButtonShow} setButtonText={setButtonText} signupBottom={signupBottom} setSignupBottom={setSignupBottom} />

    // const PriceElement = <PriceRoute setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setShowTermBundle={setShowTermBundle} setPriceBottomShow={setPriceBottomShow} setButtonShow={setButtonShow} setButtonText={setButtonText} setWhiteWrapper={setWhiteWrapper} />;
    // const [trialLoad, setTrialLoad] = useState();
    // const TrialElement = <Trial setBlockStyle={setBlockStyle} setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setTrialLoad={setTrialLoad} setShowTermBundle={setShowTermBundle} setButtonShow={setButtonShow} setWelcometo={setWelcometo} setWhiteWrapper={setWhiteWrapper} />;

    // { id: '', component: <Start setButtonShow={setButtonShow} setHandShow={setHandShow} setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} />, preload: '' },
    // { id: 'price', component: "", preload: '' },
    // { id: 'trial', component: "", preload: '' },

    const [platfrom, setPlatfrom] = useState('');

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('android')) {
            setPlatfrom('Android');
        } else if (
            userAgent.includes('iphone') ||
            userAgent.includes('ipad') ||
            userAgent.includes('ipod')
        ) {
            setPlatfrom('iOS');
        } else {
            setPlatfrom('');
        }
    }, []);

    const stepsMain = [
        { id: '', component: <Home isStart={isStart} />, preload: <StageContent /> },
        { id: 'stage', component: <Stage />, preload: <ProductsContent /> },
        { id: 'products', component: <Products />, preload: <FeaturedContent /> },
        { id: 'helped', component: <Featured />, preload: <VideosContent /> },
        {
            id: 'video-ads',
            component: <VideoQuiz />,
            preload: (
                <>
                    <AvatarsContent /> <CreativeQuizContent />
                </>
            )
        },
        {
            id: 'video-avatars',
            component: <Avatars />,
            preload: <VideoTemplateContent  video={'Dynamic_templates.m3u8'} />
        },
        {
            id: 'video-templates',
            component: (
                <VideoTemplate
                    queryName={'video-templates'}
                    video={'Dynamic_templates.m3u8'}
                    title={'Add dynamic templates?'}
                    text={
                        'Create diverse high-quality video ads with rich transitions and performance-focused elements'
                    }
                />
            ),
            preload: (
                <VideoTemplateContent
                    video={'Conversion_Scripts.m3u8'}
                />
            )
        },
        {
            id: 'video-scripts',
            component: (
                <VideoTemplate
                    queryName={'video-scripts'}
                    video={'Conversion_Scripts.m3u8'}
                    title={'Add sales-focused scripts?'}
                    text={
                        'Let AI craft your scripts using proven frameworks like AIDA, PAS, Before-After-Bridge, 4U, and FAB'
                    }
                />
            ),
            preload: <VideoTemplateContent img={'Add custom hooks & CTAs_.webp'} />
        },
        {
            id: 'video-hooks',
            component: (
                <VideoTemplate
                    queryName={'video-hooks'}
                    img={'Add custom hooks & CTAs_.webp'}
                    title={'Add custom hooks & CTAs?'}
                    text={
                        'Capture attention and drive sales with top hooks and call-to-actions that grow CTR and maximize results'
                    }
                />
            ),
            preload: (
                <VideoTemplateContent
 
                    video={'Conversion-focused_cursor.m3u8'}
                />
            )
        },
        {
            id: 'video-conversion',
            component: (
                <VideoTemplate
                    queryName={'video-conversion'}

                    video={'Conversion-focused_cursor.m3u8'}
                    title={'Add conversion widget?'}
                    text={
                        'Create AI video ads with high CTR, low cost per click, increased purchases, and outstanding ROAS'
                    }
                />
            ),
            preload: <CreativeQuizContent />
        },
        {
            id: 'static-ads',
            component: <CreativeQuiz />,
            preload: (
                <VideoTemplateContent

                    video={'100-proven-templates.m3u8'}
                />
            )
        },
        {
            id: 'static-templates',
            component: (
                <VideoTemplate
                    queryName={'static-templates'}

                    title={'Add 100+ tested templates?'}
                    video={'100-proven-templates.m3u8'}
                    text={
                        'Access over 100 AI-picked, high-selling templates, updated regularly to boost sales and drive results'
                    }
                />
            ),
            preload: <CreativesContent content="4" />
        },
        {
            id: 'static-examples',
            component: <Creatives />,
            preload: (
                <VideoTemplateContent

                    video={'AI-Powered_Content.m3u8'}
                />
            )
        },
        {
            id: 'static-text',
            component: (
                <VideoTemplate
                    queryName={'static-text'}
                    video={'AI-Powered_Content.m3u8'}
                    title={'Add AI text tools?'}
                    text={
                        'Let AI generate engaging headlines, CTAs, and other ad copy — all optimized for best performance'
                    }
                />
            ),
            preload: <VideoTemplateContent video={"Drag_n_Drop_Editing.m3u8"} />
        },
        {
            id: 'static-editor',
            component: (
                <VideoTemplate
                    queryName={'static-editor'}
                    video={"Drag_n_Drop_Editing.m3u8"}
                    title={'Add drag-and-drop editor?'}
                    text={
                        'Customize your creatives easily — no design skills or experience needed, with our smart AI tools'
                    }
                />
            ),
            preload: <VideoTemplateContent img={'Add bulk creation_.webp'} />
        },
        {
            id: 'static-bulk',
            component: (
                <VideoTemplate
                    queryName={'static-bulk'}
                    img={'Add bulk creation_.webp'}
                    title={'Add bulk creation?'}
                    text={
                        'Generate multiple ads at once, perfect for A/B testing  and maximizing performance'
                    }
                />
            ),
            preload: (
                <SmartAiContent img={'Would you like to have smart ad campaigns run by AI_.webp'} />
            )
        },
        {
            id: 'ad-campaigns',
            component: (
                <SmartAi
                    title={'Would you like to have smart ad campaigns run by AI?'}
                    img={'Would you like to have smart ad campaigns run by AI_.webp'}
                    text={'Takes 15 mins. AI handles creatives, texts, and setup'}
                />
            ),
            preload: <ChannelsContent />
        },
        {
            id: 'ad-channels',
            component: <Channels />,
            preload: <VideoTemplateContent img={'Add smart AI targeting_.webp'} />
        },
        {
            id: 'ad-targeting',
            component: (
                <VideoTemplate
                    queryName={'ad-targeting'}
                    img={'Add smart AI targeting_.webp'}
                    title={'Add smart AI targeting?'}
                    text={
                        'Discover untapped audiences ready to buy your product with the help of AI'
                    }
                />
            ),
            preload: (
                <VideoTemplateContent img={'Add AI-optimized ads_Add AI-optimized ads_.webp'} />
            )
        },
        {
            id: 'ad-optimization',
            component: (
                <VideoTemplate
                    queryName={'ad-optimization'}
                    img={'Add AI-optimized ads_Add AI-optimized ads_.webp'}
                    title={'Add AI-optimized ads?'}
                    text={
                        'Let AI monitor, adjust, and scale your campaigns 24/7 for higher ROAS and steady performance growth'
                    }
                />
            ),
            preload: <VideoTemplateContent img={'Add real-time reporting_.webp'} />
        },
        {
            id: 'ad-reports',
            component: (
                <VideoTemplate
                    queryName={'ad-reports'}
                    img={'Add real-time reporting_.webp'}
                    title={'Add real-time reporting?'}
                    text={
                        'Get personalized AI-driven insights in one tap to make data-driven decisions for campaign success'
                    }
                />
            ),
            preload: <VideoTemplateContent img={'Add AI copilot for your ads_.webp'} />
        },
        {
            id: 'ad-copilot',
            component: (
                <VideoTemplate
                    queryName={'ad-copilot'}
                    img={'Add AI copilot for your ads_.webp'}
                    title={'Add AI copilot for your ads?'}
                    text={
                        'Have AI run your ad account, crunch numbers, and set up your ads to boost your Meta standing'
                    }
                />
            ),
            preload: <PlatfromsContent />
        },
        { id: 'ad-platform', component: <Platfroms />, preload: <GoalsContent /> },
        { id: 'ad-goals', component: <Goals />, preload: '' },
        { id: 'ad-current', component: <Current />, preload: '' },
        { id: 'ad-target', component: <Target />, preload: '' },
        { id: 'ad-timeline', component: <Reach />, preload: <ReviewsContent /> },
        { id: 'reviews', component: <Reviews />, preload: '' },
        { id: 'name', component: <Name />, preload: '' },
        {
            id: 'email',
            component: <Email />,
            preload: <img src={process.env.PUBLIC_URL + `/img/Graph-line.svg`} alt="" />
        },
        {
            id: 'predict',
            component: <Predict isStart={isStart} />,
            preload: <img src={process.env.PUBLIC_URL + `/img/plan.webp`} alt="" />
        },
        { id: 'plan', component: <Plan />, preload: '' },
        { id: 'checkout', component: <Trial />, preload: '' },
        { id: 'final-offer', component: <FinalOffer />, preload: '' },
        { id: 'final-plan', component: <FinalPlan />, preload: '' },
        { id: 'welcome', component: <WelcomeToZeely />, preload: '' },
        { id: 'confirm-email', component: <ConfirmEmail />, preload: '' },
        { id: 'signup', component: <Signup />, preload: '' },
        {
            id: 'product-link',
            component: <Website />,
            preload: (
                <>
                    <WebsiteLoaderImg />
                    <img src={process.env.PUBLIC_URL + '/img/i1.jpg'} alt="" />
                    <img src={process.env.PUBLIC_URL + '/img/i2.jpg'} alt="" />
                    <img src={process.env.PUBLIC_URL + '/img/i3.jpg'} alt="" />
                </>
            )
        },
        { id: 'product-loader', component: <WebsiteLoader />, preload: '' },
        { id: 'industry', component: <BusinessArea />, preload: <MyInput /> },
        {
            id: 'industry-save',
            component: <BusinessArea save={true} />,
            preload: ''
        },
        { id: 'company-name', component: <CompanyName />, preload: '' },
        {
            id: 'company-name-save',
            component: <CompanyName save={true} />,
            preload: ''
        },
        {
            id: 'product-check',
            component: <CheckDetails />,
            preload: <AiAnalyzerImg />
        },
        {
            id: 'ai-loader',
            component: <AIAnalyzer />,
            preload: (
                <>
                      <AIAnalyzerContent />
                    <img src={process.env.PUBLIC_URL + '/img/welcome/welcome1-all.jpg'} alt="" />
                    <img
                        src={process.env.PUBLIC_URL + '/img/welcome/welcome1-android.jpg'}
                        alt=""
                    />
                    <img src={process.env.PUBLIC_URL + '/img/welcome/welcome1-apple.jpg'} alt="" />
                </>
            )
        },
        {
            id: 'loader',
            component: <AIAnalyzer showIndustry={true} />,
            preload: (
                <>
          
                    <img src={process.env.PUBLIC_URL + '/img/welcome/welcome1-all.jpg'} alt="" />
                    <img
                        src={process.env.PUBLIC_URL + '/img/welcome/welcome1-android.jpg'}
                        alt=""
                    />
                    <img src={process.env.PUBLIC_URL + '/img/welcome/welcome1-apple.jpg'} alt="" />
                </>
            )
        },
        {
            id: 'booster',
            component: <Bundle />,
            preload: (
                <img
                    src={process.env.PUBLIC_URL + '/img/graph.svg'}
                    style={{ width: '100%' }}
                    alt=""
                />
            )
        },
        { id: 'booster-prediction', component: <Graph />, preload: ''},
        // { id: 'booster2', component: <Downgrade setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setShowTermBundle={setShowTermBundle} setBlockPaddingBottom={setBlockPaddingBottom} setShowBrandName={setShowBrandName} setShowWebSite={setShowWebSite} setWelcomeWrapper={setWelcomeWrapper} setButtonShow={setButtonShow} setWhiteWrapper={setWhiteWrapper} />, preload: '' },
        // { id: 'brandname', component: <Welcome1 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonStatus={setButtonStatus} setShowBrandName={setShowBrandName} BrandName={true} setShowWebSite={setShowWebSite} WebSite={false} setsetShowBrandName setWelcomeWrapper={setWelcomeWrapper} setBlockPaddingBottom={setBlockPaddingBottom} setWhiteWrapper={setWhiteWrapper} />, preload: <John /> },
        {
            id: 'thankyou',
            component: (
                <Welcome1
                    platfrom={platfrom}
                    setEventName={setEventName}
                    setEventProperties={setEventProperties}
                    sessionId={sessionId}
                    // setButtonShow={setButtonShow}
                    setButtonStatus={setButtonStatus}
                    setShowBrandName={setShowBrandName}
                    BrandName={false}
                    setShowWebSite={setShowWebSite}
                    WebSite={false}
                    setWelcomeWrapper={setWelcomeWrapper}
                    setBlockPaddingBottom={setBlockPaddingBottom}
                    setWhiteWrapper={setWhiteWrapper}
                />
            ),
            preload: ""
        },
        // { id: 'signup2', component: SignupElement2, preload: '' },
        {
            id: 'term',
            component: (
                <TermPage
                    setWhiteWrapper={setWhiteWrapper}
                    setShowBlock={setShowBlock}
                    //  setButtonShow={setButtonShow}
                    setShowTerm={setShowTerm}
                />
            ),
            preload: ''
        },
        {
            id: 'privacy',
            component: (
                <PrivacyPage
                    setWhiteWrapper={setWhiteWrapper}
                    setShowBlock={setShowBlock}
                    //  setButtonShow={setButtonShow}
                    setShowPrivacy={setShowPrivacy}
                />
            ),
            preload: ''
        }
    ];

    const [BusinessAreaHave, setBusinessAreaHave] = useState(1);

    const termChange = () => {
        setShowBlock(false);
        // setButtonShow(false)
        setShowTerm(true);
        setBlockPaddingBottom('0');
    };

    const privacyChange = () => {
        setShowBlock(false);
        // setButtonShow(false)
        setShowPrivacy(true);
        setBlockPaddingBottom('0');
    };

    const termClose = () => {
        setShowBlock(true);
        // setButtonShow(true)
        setShowTerm(false);
        setShowPrivacy(false);
        setBlockPaddingBottom('32rem');
    };

    const termBundleClose = () => {
        dispatch(setIsShowTerm(false));
    };

    const privacyBundleClose = () => {
        dispatch(setIsShowPrivacy(false));
    };

    const loginChange = () => {
        setButtonStatus('loginChange');
    };

    const handleClickChoose = (nextFunction, clickedElement, id, value) => {
        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function (profile) {
                profile.creativesQuiz = profile.creativesQuiz || {};
                if (profile.creativesQuiz.hasOwnProperty(id)) {
                    profile.creativesQuiz[id] = { type: creativeType, checked: value };
                } else {
                    profile.creativesQuiz[id] = { type: creativeType, checked: value };
                }
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                creativesQuiz: {}
            };
            newProfile.creativesQuiz[id] = { type: creativeType, checked: value };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }

        const tl = gsap.timeline();
        tl.to(clickedElement, {
            duration: 0.085,
            scale: 0.95,
            opacity: 0.85,
            background: '#F2F4F6',
            ease: 'power4.out'
        });
        tl.to(clickedElement, {
            duration: 0.55,
            scale: 1,
            opacity: 1,
            background: '#FFF',
            ease: 'elastic.out(1,0.6)'
        });

        const preEventProperties = eventProperties;

        preEventProperties.web_utm_campaign = localStorage.getItem('utmCampaign');
        preEventProperties.web_utm_source = localStorage.getItem('utmSource');
        preEventProperties.web_utm_medium = localStorage.getItem('utmMedium');
        preEventProperties.web_utm_content = localStorage.getItem('utmContent');
        preEventProperties.web_utm_term = localStorage.getItem('utmTerm');
        preEventProperties.isDebug = false;
        preEventProperties.funnel = 'MAIN_v1';
        mixpanel.track(eventName, eventProperties);

        setTimeout(() => {
            nextFunction();
        }, 350);
    };

    const [trialNext, setTrialNext] = useState();
    const [trialStatus, setTrialStatus] = useState(false);

    useEffect(() => {
        if (trialStatus) {
            if (trialStatus) {
                // if (trialLoad) {
                setButtonStatus('load');
            } else {
                if (typeof trialNext === 'function') {
                    setTimeout(() => {
                        setButtonStatus('');
                        setTrialStatus(false);
                        const preEventProperties = eventProperties;

                        preEventProperties.web_utm_campaign = localStorage.getItem('utmCampaign');
                        preEventProperties.web_utm_source = localStorage.getItem('utmSource');
                        preEventProperties.web_utm_medium = localStorage.getItem('utmMedium');
                        preEventProperties.web_utm_content = localStorage.getItem('utmContent');
                        preEventProperties.web_utm_term = localStorage.getItem('utmTerm');
                        preEventProperties.isDebug = false;
                        preEventProperties.funnel = 'MAIN_v1';

                        if (eventName === 'web_price') {
                            preEventProperties.plan_name = localStorage.getItem('plan');
                        }
                        mixpanel.track(eventName, eventProperties);
                        trialNext();
                    }, 300);
                }
            }
        }
        // }, [trialLoad, trialStatus]);
    }, [trialStatus]);

    const handleClick = () => {
        dispatch(setButtonClick(true));
    };

    const handleClickNot = () => {
        dispatch(setButtonClickNot(true));
    };

    const sendAlert = async (path, data, errorLog) => {
        const now = new Date();
        const utcString = now.toISOString();

        const formdata = new FormData();
        formdata.append('domain', window.location.hostname);
        formdata.append('time', utcString);
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('path', path);
        formdata.append('data', data);
        formdata.append('error', errorLog);
        console.log(formdata);
        try {
            const response = await axios.post('https://zee.sale/send-alert.php', formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleForget = () => {
        setButtonStatus('EnterEmail');
    };

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [timeLeft]);

    useEffect(() => {
        const setVHVariable = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVHVariable();
        window.addEventListener('resize', setVHVariable);
        return () => window.removeEventListener('resize', setVHVariable);
    }, []);

    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                dispatch(setIsShowTerm(false));
                dispatch(setIsShowPrivacy(false));
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Router>
                <Route
                    render={({ history }) => (
                        <Wizard
                            history={history}
                            render={({ step, steps }) => (
                                <div
                                    className={`wrapper ${aiShow ? 'block-ai' : ''} ${
                                        homeShow ? 'block-home' : ''
                                    } ${whiteWrapper ? 'block-white' : ''} ${
                                        WelcomeWrapper ? 'block-welcome' : ''
                                    } ${blockClassName}`}>
                                    <Helmet>
                                        <meta name="theme-color" content="#fff"></meta>
                                    </Helmet>
                                    <>
                                        <WithWizard
                                            render={({ next, previous, step, steps }) => (
                                                <BackHistory
                                                    sessionId={sessionId}
                                                    step={step}
                                                    steps={steps}
                                                    nextFunction={next}
                                                />
                                            )}
                                        />
                                        <Header step={step} steps={steps} />
                                    </>

                                    {steps.indexOf(step) >
                                        steps.findIndex((step) => step.id === 'signup') && (
                                        <CheckEmail />
                                    )}

                                    <div
                                        className="block"
                                        style={{
                                            paddingBottom: blockPaddingBottom
                                            // overflow
                                        }}>
                                        {(steps.indexOf(step) ===
                                            steps.findIndex((step) => step.id === 'price') ||
                                            steps.indexOf(step) ===
                                                steps.findIndex((step) => step.id === 'trial')) && (
                                            <>
                                                <div
                                                    className={`${
                                                        steps.indexOf(step) ===
                                                            steps.findIndex(
                                                                (step) => step.id === 'price'
                                                            ) && 'preload'
                                                    }`}>
                                                    {/* {TrialElement} */}
                                                </div>
                                                {/* {steps.indexOf(step) ===
                          steps.findIndex((step) => step.id === "price") &&
                          PriceElement} */}
                                            </>
                                        )}

                                        <Steps>
                                            {stepsMain.map((stepMain) => (
                                                <Step key={stepMain.id} id={stepMain.id}>
                                                    <>
                                                        <div className="preload">
                                                            {steps.indexOf(step) + 4 < stepsMain.length ? (
                                                                <>
                                                                    {
                                                                        stepsMain[
                                                                            steps.indexOf(step) + 4
                                                                        ]?.preload
                                                                    }
                                                                    {
                                                                        stepsMain[
                                                                            steps.indexOf(step) + 3
                                                                        ]?.preload
                                                                    }
                                                                    {
                                                                        stepsMain[
                                                                            steps.indexOf(step) + 2
                                                                        ]?.preload
                                                                    }
                                                                    {
                                                                        stepsMain[
                                                                            steps.indexOf(step) + 1
                                                                        ]?.preload
                                                                    }
                                                                    {
                                                                        stepsMain[
                                                                            steps.indexOf(step)
                                                                        ]?.preload
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {stepsMain[0].preload}
                                                                    {stepsMain[1].preload}
                                                                    {stepsMain[2].preload}
                                                                    {stepsMain[3].preload}
                                                                    {stepsMain[4].preload}
                                                                </>
                                                            )}
                                                        </div>

                                                        {!(
                                                            welcometoShow ||
                                                            steps.indexOf(step) ===
                                                                steps.findIndex(
                                                                    (step) => step.id === 'trial'
                                                                )
                                                        ) && (
                                                            <>
                                                                <Reveal
                                                                    style={{
                                                                        ...blockStyle,
                                                                        display: showBlock
                                                                            ? 'block'
                                                                            : 'none'
                                                                    }}
                                                                    cascade
                                                                    keyframes={customAnimation}>
                                                                    {stepMain.component}
                                                                </Reveal>
                                                            </>
                                                        )}
                                                    </>
                                                </Step>
                                            ))}
                                        </Steps>

                                        {welcometoShow && (
                                            <Steps>
                                                {stepsMain.map((stepMain) => (
                                                    <Step key={stepMain.id} id={stepMain.id}>
                                                        <div className="preload">
                                                            {stepMain.preload}
                                                        </div>
                                                        {stepMain.component}
                                                    </Step>
                                                ))}
                                            </Steps>
                                        )}

                                        {buttonShow && (
                                            <div className="block-button">
                                                <WithWizard
                                                    render={({ next, previous, step, steps }) => (
                                                        <>
                                                            {buttonNotShow && (
                                                                <MyButtonWhite
                                                                    style={{ marginBottom: '8rem' }}
                                                                    buttonText={buttonNotText}
                                                                    buttonIcon={buttonNotIcon}
                                                                    onClick={() => handleClickNot()}
                                                                />
                                                            )}

                                                            <MyButton
                                                                // ref={nextPageButtonRef}
                                                                buttonStatus={buttonStatus}
                                                                buttonTime={buttonTime}
                                                                buttonText={buttonText}
                                                                buttonIcon={buttonIcon}
                                                                onClick={() => handleClick()}
                                                                className={
                                                                    `${buttonError ? 'error' : ''}`
                                                                    // areAllCheckboxesUnchecked() ||
                                                                    // areAllRadioUnchecked()
                                                                    //     ? buttonError
                                                                    //         ? 'error'
                                                                    //         : 'disable'
                                                                    //     : undefined
                                                                }></MyButton>

                                                            {handShow && (
                                                                <div className="hand">
                                                                    <svg
                                                                        width="55"
                                                                        height="55"
                                                                        viewBox="0 0 55 55"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <g clip-path="url(#clip0_11035_53290)">
                                                                            <path
                                                                                d="M15.323 14.04C13.5422 15.0681 12.9223 17.3819 13.9504 19.1626L21.3335 31.9506L20.1934 31.6158L19.7859 31.535C18.8377 31.2832 17.8283 31.4172 16.9787 31.9077C16.129 32.3983 15.5083 33.2054 15.2522 34.1525C15.0004 35.1007 15.1345 36.11 15.625 36.9597C16.1155 37.8093 16.9227 38.4301 17.8697 38.6861L17.8891 38.7197L31.8116 42.3642L31.8981 42.359L31.9711 42.4078C34.2106 42.6893 36.4809 42.2333 38.4381 41.109L40.3 40.034C41.4661 39.3641 42.4883 38.4704 43.3081 37.4043C44.1278 36.3382 44.7289 35.1207 45.0769 33.8217C45.4249 32.5227 45.5129 31.1678 45.3358 29.8347C45.1588 28.5016 44.7202 27.2165 44.0452 26.0534L39.1627 17.5967C38.1346 15.8159 35.8198 15.1966 34.0401 16.2241C33.579 16.4903 33.2256 16.8632 32.9297 17.2707C31.7621 16.0685 29.8684 15.7459 28.3583 16.6177C27.529 17.0965 26.9555 17.8606 26.6813 18.7132C25.7774 18.5142 24.8317 18.6584 24.0281 19.1177C23.6609 19.3359 23.3344 19.6163 23.0631 19.9463L20.4456 15.4126C19.4175 13.6319 17.1038 13.0119 15.323 14.04Z"
                                                                                fill="white"></path>
                                                                            <path
                                                                                d="M15.323 14.04C13.5422 15.0681 12.9223 17.3819 13.9504 19.1626L21.3335 31.9506L20.1934 31.6158L19.7859 31.535C18.8377 31.2832 17.8283 31.4172 16.9787 31.9077C16.129 32.3983 15.5083 33.2054 15.2522 34.1525C15.0004 35.1007 15.1345 36.11 15.625 36.9597C16.1155 37.8093 16.9227 38.4301 17.8697 38.6861L17.8891 38.7197L31.8116 42.3642L31.8981 42.359L31.9711 42.4078C34.2106 42.6893 36.4809 42.2333 38.4381 41.109L40.3 40.034C41.4661 39.3641 42.4883 38.4704 43.3081 37.4043C44.1278 36.3382 44.7289 35.1207 45.0769 33.8217C45.4249 32.5227 45.5129 31.1678 45.3358 29.8347C45.1588 28.5016 44.7202 27.2165 44.0452 26.0534L39.1627 17.5967C38.1346 15.8159 35.8198 15.1966 34.0401 16.2241C33.579 16.4903 33.2256 16.8632 32.9297 17.2707C31.7621 16.0685 29.8684 15.7459 28.3583 16.6177C27.529 17.0965 26.9555 17.8606 26.6813 18.7132C25.7774 18.5142 24.8317 18.6584 24.0281 19.1177C23.6609 19.3359 23.3344 19.6163 23.0631 19.9463L20.4456 15.4126C19.4175 13.6319 17.1038 13.0119 15.323 14.04ZM16.573 16.2051C17.1738 15.8582 17.9337 16.0618 18.2805 16.6626L25.1555 28.5705L27.3206 27.3205L24.8206 22.9903C24.4737 22.3895 24.6773 21.6297 25.2781 21.2828C25.8789 20.9359 26.6388 21.1395 26.9857 21.7403L29.4857 26.0705L31.6507 24.8205L29.1507 20.4903C28.8038 19.8895 29.0074 19.1297 29.6083 18.7828C30.2091 18.4359 30.9689 18.6395 31.3158 19.2403L33.8158 23.5705L36.0826 22.2617L34.8326 20.0967C34.4857 19.4958 34.6893 18.736 35.2901 18.3891C35.8899 18.0429 36.6508 18.2458 36.9977 18.8467L41.8808 27.3045C44.0458 31.0544 42.7999 35.704 39.049 37.8696L37.1892 38.9433C35.6033 39.859 33.9059 40.0841 32.2744 39.8857L18.5124 36.2842C17.7525 36.0806 17.4494 35.5556 17.653 34.7957C17.8577 34.0353 18.3817 33.7328 19.1415 33.9364L26.5061 35.9097L16.1155 17.9126C15.7686 17.3118 15.9722 16.552 16.573 16.2051Z"
                                                                                fill="black"></path>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_11035_53290">
                                                                                <rect
                                                                                    width="40"
                                                                                    height="40"
                                                                                    fill="white"
                                                                                    transform="translate(0 20) rotate(-30)"></rect>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </div>
                                                            )}

                                                            {buttonSkip && (
                                                                <div
                                                                    className="skip"
                                                                    onClick={next}>
                                                                    Skip
                                                                </div>
                                                            )}
                                                            {signupBottom == 'checkbox' && (
                                                                <Reveal
                                                                    cascade
                                                                    keyframes={customAnimation}>
                                                                    <div className="googlesingup-bottom">
                                                                        {window.location
                                                                            .pathname ===
                                                                        '/signup' ? (
                                                                            <>
                                                                                {loginChangeStatus && (
                                                                                    <div className="googlesingup-bottom-login">
                                                                                        Already have
                                                                                        an account?{' '}
                                                                                        <Link
                                                                                            onClick={
                                                                                                loginChange
                                                                                            }
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                                textDecoration:
                                                                                                    'underline',
                                                                                                textDecorationColor:
                                                                                                    '#000'
                                                                                            }}>
                                                                                            Log in
                                                                                        </Link>
                                                                                    </div>
                                                                                )}
                                                                                <div className="googlesingup-bottom-term">
                                                                                    By continuing I
                                                                                    agree to the
                                                                                    <br />{' '}
                                                                                    <span
                                                                                        onClick={
                                                                                            termChange
                                                                                        }>
                                                                                        Terms &
                                                                                        Conditions
                                                                                    </span>
                                                                                    ,{' '}
                                                                                    <span
                                                                                        onClick={
                                                                                            privacyChange
                                                                                        }>
                                                                                        Privacy
                                                                                        Policy
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <div className="googlesingup-bottom-login">
                                                                                <Link
                                                                                    onClick={() => {
                                                                                        history.push(
                                                                                            '/signup'
                                                                                        );

                                                                                        mixpanel.track(
                                                                                            'web_signup_2_act',
                                                                                            {
                                                                                                session_id:
                                                                                                    sessionId,
                                                                                                web_utm_campaign:
                                                                                                    localStorage.getItem(
                                                                                                        'utmCampaign'
                                                                                                    ),
                                                                                                web_utm_source:
                                                                                                    localStorage.getItem(
                                                                                                        'utmSource'
                                                                                                    ),
                                                                                                web_utm_medium:
                                                                                                    localStorage.getItem(
                                                                                                        'utmMedium'
                                                                                                    ),
                                                                                                web_utm_content:
                                                                                                    localStorage.getItem(
                                                                                                        'utmContent'
                                                                                                    ),
                                                                                                web_utm_term:
                                                                                                    localStorage.getItem(
                                                                                                        'utmTerm'
                                                                                                    ),
                                                                                                isDebug: false,
                                                                                                funnel: 'MAIN_v1'
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        textDecoration:
                                                                                            'none'
                                                                                    }}>
                                                                                    Sign up with a
                                                                                    new email
                                                                                </Link>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Reveal>
                                                            )}
                                                            {signupBottom == 'forget' && (
                                                                <Reveal
                                                                    cascade
                                                                    keyframes={customAnimation}>
                                                                    <div
                                                                        className="forget"
                                                                        onClick={handleForget}>
                                                                        Forgot password?
                                                                    </div>
                                                                </Reveal>
                                                            )}
                                                            {signupBottom == 'loginChange' && (
                                                                <Reveal
                                                                    cascade
                                                                    keyframes={customAnimation}>
                                                                    <div
                                                                        className="forget"
                                                                        onClick={handleForget}>
                                                                        Forgot password?
                                                                    </div>
                                                                </Reveal>
                                                            )}
                                                            {signupBottom == 'EnterCode' && (
                                                                <Reveal
                                                                    cascade
                                                                    keyframes={customAnimation}>
                                                                    {timeLeft > 0 ? (
                                                                        <div className="forget">
                                                                            Wait 00:{timeLeft} to
                                                                            resend
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className="forget"
                                                                            onClick={handleForget}>
                                                                            I didn't receive a code
                                                                        </div>
                                                                    )}
                                                                </Reveal>
                                                            )}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {buttonChoose && (
                                            <div className="block-button">
                                                <WithWizard
                                                    render={({ next, previous, step, steps }) => (
                                                        <>
                                                            <div className="choose-action">
                                                                <div
                                                                    className="choose-action-btn"
                                                                    onClick={(event) =>
                                                                        handleClickChoose(
                                                                            next,
                                                                            event.currentTarget,
                                                                            creativeId,
                                                                            false
                                                                        )
                                                                    }>
                                                                    <svg
                                                                        width="21"
                                                                        height="20"
                                                                        viewBox="0 0 21 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M5.24485 4.49493C5.5238 4.21598 5.97606 4.21598 6.25501 4.49493L10.7499 8.98929L15.2449 4.49493C15.5023 4.23744 15.9075 4.21763 16.1877 4.43551L16.255 4.49493C16.534 4.77388 16.534 5.22614 16.255 5.50508L11.7606 10L16.255 14.4949C16.5125 14.7524 16.5323 15.1576 16.3144 15.4378L16.255 15.5051C15.9761 15.784 15.5238 15.784 15.2449 15.5051L10.7499 11.0107L6.25501 15.5051C5.99752 15.7626 5.59235 15.7824 5.31215 15.5645L5.24485 15.5051C4.96591 15.2261 4.96591 14.7739 5.24485 14.4949L9.73922 10L5.24485 5.50508C4.98737 5.24759 4.96756 4.84243 5.18543 4.56222L5.24485 4.49493Z"
                                                                            fill="#EF2C4F"
                                                                            stroke="#EF2C4F"
                                                                            stroke-width="0.4"
                                                                        />
                                                                    </svg>
                                                                    No
                                                                </div>
                                                                <div
                                                                    className="choose-action-btn"
                                                                    onClick={(event) =>
                                                                        handleClickChoose(
                                                                            next,
                                                                            event.currentTarget,
                                                                            creativeId,
                                                                            true
                                                                        )
                                                                    }>
                                                                    <svg
                                                                        width="21"
                                                                        height="20"
                                                                        viewBox="0 0 21 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M8.60693 13.2756L4.8263 9.49492C4.54735 9.21598 4.09509 9.21598 3.81614 9.49492C3.5372 9.77387 3.5372 10.2261 3.81614 10.5051L8.10186 14.7908C8.3808 15.0697 8.83306 15.0697 9.11201 14.7908L17.6834 6.21936C17.9624 5.94042 17.9624 5.48816 17.6834 5.20921C17.4045 4.93026 16.9522 4.93026 16.6733 5.20921L8.60693 13.2756Z"
                                                                            fill="#5BF0A5"
                                                                            stroke="#5BF0A5"
                                                                            stroke-width="0.444444"
                                                                        />
                                                                    </svg>
                                                                    Yes
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {showBrandName && (
                                        <div className="brandname">
                                            <div className="brandname-wrapper">
                                                <BrandName
                                                    BusinessAreaHave={BusinessAreaHave}
                                                    setBusinessAreaHave={setBusinessAreaHave}
                                                    WebsiteStatus={WebsiteStatus}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {isShowPrivacy && (
                                        <div className="brandname">
                                            <div className="brandname-wrapper" ref={wrapperRef}>
                                                <div
                                                    className="term-back"
                                                    onClick={privacyBundleClose}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="46"
                                                        height="46"
                                                        viewBox="0 0 46 46"
                                                        fill="none">
                                                        <path
                                                            d="M25.75 17L20.25 22.8182L25.75 29"
                                                            stroke="black"
                                                            stroke-width="1.6"
                                                            stroke-linecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                                <Privacy />
                                            </div>
                                        </div>
                                    )}

                                    {isShowTerm && (
                                        <div className="brandname">
                                            <div className="brandname-wrapper" ref={wrapperRef}>
                                                <div
                                                    className="term-back"
                                                    onClick={termBundleClose}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="46"
                                                        height="46"
                                                        viewBox="0 0 46 46"
                                                        fill="none">
                                                        <path
                                                            d="M25.75 17L20.25 22.8182L25.75 29"
                                                            stroke="black"
                                                            stroke-width="1.6"
                                                            stroke-linecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                                <Term />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                    )}
                />
            </Router>
        </>
    );
}

export default App;
