import React, { useState, useEffect, useRef } from "react";
import CustomTitle from "../../components/UI/title/CustomTitle";
import "./signup.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { ReactComponent as IconEmail } from "../../assets/icons/email.svg";
import { ReactComponent as IconPassword } from "../../assets/icons/password.svg";
import { ReactComponent as IconEye } from "../../assets/icons/eye.svg";
import { ReactComponent as IconNotEye } from "../../assets/icons/not-eye.svg";
import MyButton from "../../components/UI/button/MyButton";
import { useChangePasswordMutation, useChangePasswordNewMutation, useCreateBusinessProfileMutation, useFetchBusinessProfilesQuery, useLoginMutation, useUpdateBusinessProfileMutation } from "../../api/dataApi";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CryptoJS from 'crypto-js';
import { Helmet } from "react-helmet";

const Signup = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();
  const [title, setTitle] = useState("Set up your password");

  const [inputEmail, setInputEmail] = useState(
    queryParams.get("email") ? queryParams.get("email") : ""
  );

  const [errorEmail1, setErrorEmail1] = useState(false);
  const [msgEmail1, setMsgEmail1] = useState("");
  const [errorEmail2, setErrorEmail2] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [placeholderPassword, setPlaceholderPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [msgPassword, setMsgPassword] = useState("");
  const [inputPassword2, setInputPassword2] = useState("");
  const [errorPassword2, setErrorPassword2] = useState(false);
  const [msgPassword2, setMsgPassword2] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [disabledEmail, setDisabledEmail] = useState(false);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;

  const [status, setStatus] = useState("");



  useEffect(() => {
    if (!queryParams.get("new-account")) {
      setDisabledEmail(true)
    }

  }, [])

  const [changePassword, { isLoading: isChangingPassword, error: changePasswordError }] = useChangePasswordMutation();

  const [changePasswordNew, { isLoading: isChangingPasswordNew, error: changePasswordNewError }] = useChangePasswordNewMutation();


  const [login, { isLoading: isLoggingIn, error: loginError }] = useLoginMutation();


  const [isLogin, setIsLogin] = useState(false);

  const [createBusinessProfile, { isLoading, error }] =
    useCreateBusinessProfileMutation();

  const [updateProfile, { isLoading: updating, error: updateError }] = useUpdateBusinessProfileMutation();

  const { data: businessProfiles, error: errorbusinessProfiles, isLoading: isLoadingBusinessProfiles, refetch } = useFetchBusinessProfilesQuery();

  const logIn = async () => {
    const data = {
      email: queryParams.get("email"),
      password: inputPassword,
      os: "web",
    };
    try {
      const response = await login(data).unwrap();
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);

      if (queryParams.get("new-account")) {
        handleCreateProfile()
      } else {
        await refetch()
        setIsLogin(true);
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    if (isLogin && !isLoadingBusinessProfiles) {

      if (errorbusinessProfiles?.data?.message === "Business profile not found") {
        handleCreateProfile()
        setIsLogin(false)
      }
      if (businessProfiles) {
        handleUpdateProfile()
        setIsLogin(false)
      }
    }
  }, [isLogin, isLoadingBusinessProfiles, errorbusinessProfiles, businessProfiles])

  const handleCreateProfile = async () => {
    const data = {
      direction: queryParams.get("direction")
        ? queryParams.get("direction")
        : "PRODUCT",
      ...queryParams.get("name") && { name: queryParams.get("name") },
      ...queryParams.get("email") && { email: queryParams.get("email") },
    };
    try {
      await createBusinessProfile(data).unwrap();
      history.push({
        pathname: '/product-link',
        search: `?${queryParams.toString()}`,
      });
      // console.log('Профиль успешно создан');
    } catch (err) {
      console.error("Ошибка создания профиля:", err);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      await businessProfiles
      const profileId = businessProfiles?.data?.id;
      const data = {
        direction: queryParams.get("direction")
          ? queryParams.get("direction")
          : businessProfiles?.data?.direction || "PRODUCT",
        ...queryParams.get("name") && { userName: queryParams.get("name") },
        ...queryParams.get("email") && { email: queryParams.get("email") },
      }
      if (profileId) {
        await updateProfile({ id: profileId, data }).unwrap();
        history.push({
          pathname: '/product-link',
          search: `?${queryParams.toString()}`,
        });
      }
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  const checkSubscribe = () => {
    axios
      .get("https://staging.zeely.link/subscriptions/current", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        redirect: "follow",
      })
      .then((response) => {
        console.log(response.data.data.status);

        if (
          response.data.data.status === "ACTIVE" ||
          response.data.data.status === "REDEMPTION"
        ) {
          history.push("/thankyou");
          localStorage.setItem("userId", response.data.data.userId);
        } else {
          checkName();
        }
      })
      .catch((error) => {
        checkName();
      });
  };

  const checkName = () => {
    axios
      .get("https://staging.zeely.link/users", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        redirect: "follow",
      })
      .then((response) => {
        localStorage.setItem("userId", response.data.data.id);
        if (response.data.data.name && response.data.data.name.length) {
          history.push("/prediction");
        } else {
          history.push("/name");
        }
      })
      .catch((error) => {
        // history.push('/name');
      });
  };



  const handleChange = () => { };

  const [showLastSymbol, setShowLastSymbol] = useState(false);

  const handlePasswordChange = (e) => {
    setErrorEmail1(false);
    setErrorPassword2(false);
    setErrorPassword(false);
    setShowLastSymbol(true);
    if (e.target.value.length > 7) {
    } else {
    }
    setInputPassword(e.target.value);
  };

  const handlePassword2Change = (e) => {
    setErrorEmail1(false);
    setErrorPassword2(false);
    setErrorPassword(false);
    setErrorPassword2(false);
    setInputPassword2(e.target.value);
  };

  useEffect(() => {
    if (
      !emailRegex.test(inputEmail) ||
      inputPassword.length < 8 ||
      inputPassword !== inputPassword2
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [inputEmail, inputPassword, inputPassword2]);

  const secretKey = 'onrD(}7zNTP%onp>)/wB9';

  const decryptText = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const original = bytes.toString(CryptoJS.enc.Utf8);
    return original;
  };

  const handleSubmit = async () => {
    setErrorEmail1(false);
    setErrorPassword(false);
    setErrorPassword2(false);

    if (!emailRegex.test(inputEmail)) {
      setErrorEmail1(true);
      setMsgEmail1("That email doesn't look right. Please correct it.");
    }

    if (inputPassword.length < 8) {
      setErrorPassword(true);
      setMsgPassword("Your password needs to be at least 8 characters long");
    }

    if (inputPassword !== inputPassword2) {
      setErrorPassword2(true);
      setMsgPassword2("Those passwords don’t match");
    }

    if (!emailRegex.test(inputEmail) || inputPassword.length < 8 || inputPassword !== inputPassword2) {
      return;
    }

    setStatus("load");

    const data = {
      email: queryParams.get("email"),
      code: decryptText(queryParams.get("code")),
      newPassword: inputPassword,
    };

    const dataNew = {
      oldPassword: decryptText(queryParams.get("code")),
      newPassword: inputPassword
    }

    try {
      if (queryParams.get("new-account")) {
        const response = await changePasswordNew(dataNew);
        if (response.error) {
          // setError('Sorry, you entered the wrong code. Please try again.');
        } else {
          logIn()
        }
      } else {
        // handleUpdateProfile()
        const response = await changePassword(data);
        if (response.error) {
          // setError('Sorry, you entered the wrong code. Please try again.');
        } else {
          logIn()
        }
      }
    } catch (error) {
      setStatus("");
      console.error("Error changing password:", error);
    }
  };


  return (
    <div className="signup">
      <div className="signup-top">
        <CustomTitle style={{ marginBottom: "24rem" }} title={title} />
        <Helmet>
          <meta name="theme-color" content="#fff"></meta>
        </Helmet>
        <div
          className={`input ${disabledEmail ? 'disabled' : ''} ${errorEmail1 ? "error" : ""}`}
          style={{
            paddingLeft: "46rem",
            paddingRight: disabled ? "70rem" : "",
          }}
        >
          <input
            type="email"
            value={inputEmail}
            onChange={(e) => {
              setErrorEmail1(false);
              setErrorPassword2(false);
              setErrorPassword(false);
              setInputEmail(e.target.value.toLowerCase());
            }}
            placeholder="Enter your email"
            name="email1"
          />
          <IconEmail />
          <span>Email</span>
          <div className={`input-border ${inputEmail === '' ? 'empty' : ''}`}></div>
          {!disabledEmail && (
            <div className="change" onClick={handleChange}>
              Change
            </div>
          )}


          {errorEmail1 && (
            <div className="error-text" style={{ marginLeft: "-46rem" }}>
              {msgEmail1}
            </div>
          )}
        </div>

        <div
          className={`input ${errorPassword ? "error" : ""}`}
          style={{
            marginTop: "10rem",
            paddingLeft: "46rem",
            paddingRight: "46rem",
          }}
        >
          <input
            type={showPassword ? "text" : "password"}
            value={inputPassword}
            onChange={handlePasswordChange}
            placeholder="Enter your password"
            name="password"
            autocomplete="off"
          />
          <div className="mask">
          </div>
          <div className={`input-border ${inputPassword === '' ? 'empty' : ''}`}></div>
          <IconPassword />
          <span>Password</span>
          <div className="show" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <IconEye /> : <IconNotEye />}
          </div>
          {errorPassword && (
            <div className="error-text" style={{ marginLeft: "-46rem" }}>
              {msgPassword}
            </div>
          )}
        </div>

        <div
          className={`input ${errorPassword2 ? "error" : ""}`}
          style={{
            marginTop: "10rem",
            paddingLeft: "46rem",
            paddingRight: "46rem",
          }}
        >
          <input
            type={showPassword ? "text" : "password"}
            value={inputPassword2}
            onChange={handlePassword2Change}
            onBlur={() => {
              if (inputPassword !== inputPassword2) {
                setErrorPassword2(true);
                setMsgPassword2("Those passwords don’t match");
              }
            }}
            placeholder="Repeat your password"
            name="password"
            autocomplete="off"
          />
          <div className={`input-border ${inputPassword2 === '' ? 'empty' : ''}`}></div>
          <IconPassword />
          <span>Repeat password</span>
          <div className="show" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <IconEye /> : <IconNotEye />}
          </div>
          {errorPassword2 && (
            <div className="error-text" style={{ marginLeft: "-46rem" }}>
              {msgPassword2}
            </div>
          )}
        </div>
      </div>

      <MyButton
        // ref={nextPageButtonRef}
        buttonStatus={status}
        buttonIcon={"no"}
        buttonText={"Continue"}
        onClick={() => handleSubmit()}
        className={`${disabled ? "disable" : ""} ${errorEmail1 || errorPassword || errorPassword2 ? "error" : ""
          }`}
      ></MyButton>
    </div>
  );
};

export default Signup;
