import React, { useEffect, useState } from 'react'
import CustomTitle from '../../components/UI/title/CustomTitle'
import Loader from '../../components/Loader/Loader'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useScrappingQuery } from '../../api/dataApi';
import WebsiteLoaderImg from './components/WebsiteLoaderImg/WebsiteLoaderImg';

export default function WebsiteLoader() {
    const history = useHistory();
    const [progress, setProgress] = useState(0);

    const [url, setUrl] = useState(localStorage.getItem('site-url'))



    const { data: scrappingData, error: scrappingError, isLoading: isScrapping } = useScrappingQuery({
        url: url,
    });




    useEffect(() => {
      if(scrappingError && progress === 100) {
        history.push('/industry')
      }
    }, [scrappingError])
    
    useEffect(() => {
        let timeout = null;
    
        if (isScrapping && progress === 100) {
            timeout = setTimeout(() => {
                history.push('/industry');
            }, 30000);
        }
    
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [isScrapping, progress]);

    useEffect(() => {
        const target = 100;
        const intervalTime =  !isScrapping && scrappingData ? 50 : progress > 90 ? 1500 : 166;
    
        const interval = setInterval(() => {
            setProgress((prev) => (prev < target ? prev + 1 : target));
        }, intervalTime);
    
        return () => clearInterval(interval);
    }, [isScrapping, scrappingData, progress]);


    useEffect(() => {
        if (progress === 100 && scrappingData) {
            console.log(scrappingData)
            if(scrappingData.brandName && scrappingData.industry) {
                history.push('/product-check')
            }
            else {
                history.push('/industry')
            }
        }
    }, [progress, scrappingData])


    return (
        <div className='website-loader'>
            <div className="website-loader-top">
                <CustomTitle style={{ marginBottom: "32rem" }} title="Hold tight for AI magic!" />
                <WebsiteLoaderImg />
            </div>
            <Loader progress={progress} title={'Analyzing your site...'} />
        </div>
    )
}
