import React, { useEffect } from 'react'
import CustomTitle from '../../components/UI/title/CustomTitle'
import MyButtonWhite from '../../components/UI/button/MyButtonWhite'
import { useDispatch, useSelector } from 'react-redux';
import { setButtonClick, setIconButton, setIsShowButton, setTextButton } from '../../store/buttonSlice';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import SmartAiContent from './SmartAiContent';
import { setButtonClickNot, setIconButtonNot, setIsShowButtonNot, setTextButtonNot } from '../../store/buttonNotSlice';

export default function SmartAi({ img, title, text }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        dispatch(setIsShowButton(true));
        dispatch(setTextButton('Let’s do it'));
        dispatch(setIconButton('Tick'));

        dispatch(setIsShowButtonNot(true));
        dispatch(setTextButtonNot('Not sure'));
        dispatch(setIconButtonNot('not'));
    }, []);

    const buttonClicked = useSelector((state) => state.button.buttonClicked);
    const nextPage = useSelector((state) => state.appRoute.nextPage);
    const buttonNotClicked = useSelector((state) => state.buttonNot.buttonClicked);


    useEffect(() => {
        if (buttonClicked) {
            dispatch(setButtonClick(false));
            queryParams.set('ad-campaigns', 1);
            history.push({
                pathname: nextPage,
                search: `?${queryParams.toString()}`
            });
        }
    }, [buttonClicked, dispatch]);

    useEffect(() => {
        if (buttonNotClicked) {
            dispatch(setButtonClickNot(false));
            history.push({
                pathname: '/ad-platform',
                search: `?${queryParams.toString()}`
            });
        }
    }, [buttonNotClicked, dispatch]);



    return (
        <div className="page page--with-notButton">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '12rem' }}
                    title={title}
                />
                <p className="text" style={{ marginBottom: '24rem' }}>{text}</p>
                <SmartAiContent img={img} />
            </div>
        </div>
    )
}
