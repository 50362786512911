import React from 'react';
import './style.scss';

export default function Steps({steps, stepIndex}) {

    const videoQuiz = steps.findIndex(step => step.id === 'video-ads');
    const goals = steps.findIndex(step => step.id === 'ad-platform');
    
    return (
        <div className="steps">
            <div className={`steps-item active`}>
                <span className="steps-item-title">1. Intro</span>
                <div className="steps-item-line"></div>
            </div>
            <div className={`steps-item ${stepIndex >= videoQuiz ? 'active' : ''}`}>
                <span className="steps-item-title">2. Setup</span>
                <div className="steps-item-line"></div>
            </div>
            <div className={`steps-item ${stepIndex >= goals ? 'active' : ''}`}>
                <span className="steps-item-title">3. Goals</span>
                <div className="steps-item-line"></div>
            </div>
        </div>
    );
}
