import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, FreeMode } from 'swiper/modules';

const slides = [
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-01.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-02.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-03.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-04.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-05.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-06.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-07.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-08.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-09.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-10.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-11.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-12.webp' },
    { src: '/img/WEBP_Slider(Tepmlates_Static)/Static-template-13.webp' }
];


const CreativeQuizContent = () => {

    const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [loadings, setLoadings] = useState([true, true, true, true, true, true]);

    const handleLoadedData = (index) => {
        setLoadings(prevLoadings => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
        });
    };
    return (
        <Swiper
            spaceBetween={13}
            slidesPerView={2.2}
            // centeredSlides={true}
            // allowSlideNext={false}
            // allowSlidePrev={false}
            allowTouchMove={false}

            speed={2000}
            loop={true}
            autoplay={{
                delay: 0,
                disableOnInteraction: false,

            }}
            freeMode={true}
            modules={[Autoplay, FreeMode]}
        // onSwiper={(s) => {
        //     setSwiper(s);
        // }}

        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <div className="video-quiz__img" style={{ aspectRatio: "65/116" }}>
                        <img
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12rem' }}
                            src={process.env.PUBLIC_URL + slide.src}
                            alt={`Slide ${index + 1}`}
                        />
                    </div>
                </SwiperSlide>
            ))}

        </Swiper>
    );
}

export default CreativeQuizContent;