import React, { useEffect } from 'react'
import { useFetchPicturesQuery } from '../../api/unsplashApi';
import { useFetchBusinessProfilesQuery } from '../../api/dataApi';

export default function AIAnalyzerContent() {

    
    const { data: businessProfiles, isLoading: isLoadingProfiles } = useFetchBusinessProfilesQuery();
    const { data: picturesData, error: fetchError } = useFetchPicturesQuery({ query: localStorage.getItem('industry') });

    
    return (
        <div className="ai-analyzer-top__industry-wrap">
            <div className="ai-analyzer-top__industry">
                <div className="ai-analyzer-top__industry-item">
                    <img src={process.env.PUBLIC_URL + '/img/i1.jpg'} alt="" />
                    <span>Jewelry</span>
                </div>
                <div className="ai-analyzer-top__industry-item">
                    <img src={(Array.isArray(picturesData) && picturesData[0]) ?? process.env.PUBLIC_URL + '/img/i1.jpg'} alt="" />
                    <span><div>{localStorage.getItem('industry')}</div></span>
                </div>
                <div className="ai-analyzer-top__industry-item">
                    <img src={process.env.PUBLIC_URL + '/img/i3.jpg'} alt="" />
                    <span>Beauty</span>
                </div>
            </div>
        </div>
    )
}
