import React, { useState } from 'react'
import ReactPlayer from 'react-player';

export default function VideoTemplateContent({ img, video }) {
    const [loading, setLoading] = useState(true);

    const handleLoadedData = () => {
        setLoading(false);
    };

    return (
        <div style={{
            aspectRatio: "335/249", position: "relative", overflow: "hidden", border: "1px solid rgba(242, 244, 246, 0.5)",
            borderRadius: "30rem",
            backgroundColor: "#fcfdff",
        }} >
            {loading && (
                img &&
                <img
                    style={{

                        position: "absolute",
                        top: 0,
                    }}
                    src={process.env.PUBLIC_URL + "/img/WEBP_Animation-static-preview/" + img} alt="" />
            )}

            {video &&


                <ReactPlayer
                    width='100%'
                    style={{transform: "scale(1.02)"}}
                    height='auto'
                    loop={true}
                    playing={true}
                    playsinline={true}
                    volume={0.5}
                    muted={true}
                    url={process.env.PUBLIC_URL + `/video/animation/${video}`} controls={false} />
            }


        </div>
    )
}
