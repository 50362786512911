import React from 'react'
import './style.scss';

export default function CreativesItem({ img, checked, onClick, error }) {
    return (
        <div className={`creatives-item ${checked ? 'checked' : ''}`} onClick={onClick}>
            <div className={`creatives-item__checked ${checked ? 'checked' : ''} ${error ? 'error' : ''}`}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.30859 6.00764L4.8727 8.31534L10.0009 3.69995" stroke="white" stroke-width="1.6" stroke-linecap="round" />
            </svg>
            </div>
            <img
                src={process.env.PUBLIC_URL + "/img/Creatives/" + img}
                alt=""
            />
            
        </div>
    )
}
