import React, { useEffect } from 'react';
import LogoGrowth from '../Logo/Logogrowth';
import './style.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LogoDark from '../Logo/LogoDark';
import Steps from '../Steps/Steps';

export default function Header({ step, steps }) {
    const history = useHistory();

    return (
        <div className="header">
            <div className="header-top">
                {step.id === 'booster-prediction' && (
                    <div
                        className="back"
                        onClick={() => {
                            // mixpanel.track('web_booster_1', {
                            //     web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                            //     session_id: sessionId,
                            //     web_level: 'TRIAL',
                            //     web_plan__name: localStorage.getItem('plan'),
                            //     user_id: localStorage.getItem('userId'),
                            //     web_utm_campaign: localStorage.getItem("utmCampaign"),
                            //     web_utm_source: localStorage.getItem("utmSource"),
                            //     web_utm_medium: localStorage.getItem("utmMedium"),
                            //     web_utm_content: localStorage.getItem("utmContent"),
                            //     web_utm_term: localStorage.getItem("utmTerm"),
                            //     isDebug: false,
                            //     funnel: 'MAIN_v1',
                            // });
                            history.push('/thankyou');
                        }}>
                        <svg
                            style={{ width: '30rem', height: '30rem' }}
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 9L21 21"
                                stroke="#A7A8AA"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M21 9L9 21"
                                stroke="#A7A8AA"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                    </div>
                )}
                {step.id === 'booster' && (
                    <div
                        className="back"
                        style={{
                            left: 'auto',
                            right: '0',
                            fontSize: '14rem',
                            color: '#A7A8AA',
                            fontWeight: '600',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            // mixpanel.track('web_booster', {
                            //     session_id: sessionId,
                            //     web_level: 'TRIAL',
                            //     web_plan__name: localStorage.getItem('plan'),
                            //     web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                            //     user_id: localStorage.getItem('userId'),
                            //     web_utm_campaign: localStorage.getItem("utmCampaign"),
                            //     web_utm_source: localStorage.getItem("utmSource"),
                            //     web_utm_medium: localStorage.getItem("utmMedium"),
                            //     web_utm_content: localStorage.getItem("utmContent"),
                            //     web_utm_term: localStorage.getItem("utmTerm"),
                            //     isDebug: false,
                            //     funnel: 'MAIN_v1',
                            // });
                            // next()
                            history.push('/booster-prediction');
                        }}>
                        Skip
                    </div>
                )}
                {['', 'reviews', 'name', 'email', 'predict', 'plan', 'final-offer', 'final-plan'].includes(step.id) && (
                    <LogoDark style={{ width: '78rem', height: '22rem' }} />
                )}
                {steps.indexOf(step) > steps.findIndex((step) => step.id === '') &&
                    steps.indexOf(step) < steps.findIndex((step) => step.id === 'reviews') && (
                        <Steps steps={steps} stepIndex={steps.indexOf(step)} />
                    )}

                {steps.indexOf(step) > steps.findIndex((step) => step.id === 'welcome') && (
                    <LogoGrowth style={{ width: '151rem', height: '32rem' }} />
                )}
            </div>
        </div>
    );
}
