import { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import './MyButton.scss';

const MyButtonWhite = ({ children, className, onClick, buttonText, buttonTime, buttonIcon, buttonStatus, ...props }) => {

    const myElementRef = useRef(null);

    const [icon, setIcon] = useState('');
    useEffect(() => {
        if (buttonIcon !== undefined) {
            setIcon(buttonIcon);
        } else {
            setIcon('');
        }
    }, [buttonIcon]);

    const [animate, setAnimate] = useState(false);

    const handleClick = () => {

        if (className === 'disable' || className === 'error') {
            if (onClick) {
                onClick();
            }
            return;
        }
        setAnimate(true);

        const tl = gsap.timeline();
        tl.to(myElementRef.current, {
            duration: 0.085,
            scale: 0.95,
            opacity: 0.85,
            ease: "power4.out",
        });

        tl.to(myElementRef.current, {
            duration: 0.55,
            scale: 1,
            opacity: 1,
            ease: "elastic.out(1,0.6)",
        });


        // if ('vibrate' in navigator) {
        //     navigator.vibrate(100);
        // }
        setTimeout(() => {
            if (onClick) {
                onClick();
            }
        }, buttonTime);
        setTimeout(() => {
            setAnimate(false);
        }, 635);

    };



    const combinedClassName = `btn white ${className || ''} ${animate ? 'animate' : ''}`;

    return (
        <button {...props} className={combinedClassName} onClick={handleClick} ref={myElementRef}>
            {buttonStatus == 'load' ? (
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            ) : (
                <>
                 {icon === "not" && (
                        <svg style={{ width: "21rem", height: "20rem", marginLeft: '0', marginRight: '8rem' }} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.5 4L16.5 16" stroke="#EF2C4F" stroke-width="1.6" stroke-linecap="round"/>
                        <path d="M16.5 4L4.5 16" stroke="#EF2C4F" stroke-width="1.6" stroke-linecap="round"/>
                        </svg>
                        
                    )}
                    {buttonText}
                    {icon === "applePay" && (
                        <svg style={{ width: "47rem", height: "24rem" }} width="47" height="24" viewBox="0 0 47 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7989_67341)">
                                <path d="M22.9894 4.62109C25.6363 4.62109 27.4605 6.56814 27.4605 9.35508C27.4605 12.1802 25.6005 14.1273 22.9178 14.1273H20.0205V19.0521H17.9102V4.62109H22.9894ZM20.0205 12.2566H22.4171C24.2413 12.2566 25.2786 11.1876 25.2786 9.39326C25.2786 7.56075 24.2413 6.52996 22.4171 6.52996H19.9848V12.2566H20.0205ZM27.9971 16.1125C27.9971 14.28 29.3205 13.1346 31.6813 12.9819L34.3998 12.8292V12.0275C34.3998 10.844 33.6486 10.1568 32.4325 10.1568C31.2521 10.1568 30.5367 10.7676 30.3578 11.6839H28.4263C28.5336 9.77504 30.0717 8.36247 32.504 8.36247C34.9005 8.36247 36.4386 9.69868 36.4386 11.8366V19.0903H34.5071V17.3723H34.4713C33.899 18.5558 32.6471 19.2812 31.3594 19.2812C29.3563 19.2812 27.9971 17.9832 27.9971 16.1125ZM34.3998 15.158V14.3181L31.9675 14.4708C30.7513 14.5472 30.0717 15.1199 30.0717 16.0361C30.0717 16.9524 30.7871 17.5632 31.8602 17.5632C33.2909 17.5632 34.3998 16.5324 34.3998 15.158ZM38.2271 22.9844V21.2282C38.3701 21.2664 38.7278 21.2664 38.8709 21.2664C39.8009 21.2664 40.3017 20.8465 40.6236 19.7775C40.6236 19.7393 40.8025 19.1285 40.8025 19.1285L37.2255 8.59154H39.4075L41.9113 17.1814H41.9471L44.4509 8.59154H46.5971L42.9128 19.7011C42.0544 22.259 41.0886 23.0607 39.0498 23.0607C38.9067 23.0226 38.4059 23.0226 38.2271 22.9844Z" fill="white" />
                                <path d="M8.97577 4.91965C9.561 4.18861 9.95594 3.17264 9.84854 2.16016C9.00283 2.19588 7.98037 2.7422 7.37822 3.47063C6.83274 4.11716 6.36084 5.15229 6.49108 6.14473C7.4315 6.21967 8.39054 5.65069 8.97577 4.91965ZM11.4055 11.3448C11.3869 9.15517 13.1392 8.10173 13.2204 8.04945C12.2317 6.5682 10.6985 6.36257 10.1521 6.33991C8.84553 6.2066 7.5998 7.13282 6.9393 7.13282C6.27627 7.13282 5.25634 6.36082 4.17045 6.38435C2.74628 6.40526 1.4329 7.23738 0.699668 8.55047C-0.776093 11.2019 0.32502 15.1177 1.76611 17.262C2.47143 18.3119 3.31037 19.4917 4.41571 19.449C5.47961 19.4063 5.87879 18.7398 7.16426 18.7398C8.4472 18.738 8.81001 19.4473 9.93057 19.4272C11.074 19.402 11.7996 18.3555 12.5007 17.3003C13.3066 16.0848 13.6407 14.9042 13.6576 14.8449C13.6348 14.8292 11.4309 13.9631 11.4055 11.3448Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7989_67341">
                                    <rect width="46.5" height="22.1429" fill="white" transform="translate(0 0.929688)" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                    {icon === "" && (
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3901 3.54991C10.1197 3.81648 10.0944 4.23771 10.316 4.53326L10.3826 4.61054L14.9583 9.25065L3.25 9.25065C2.83579 9.25065 2.5 9.58644 2.5 10.0007C2.5 10.383 2.78611 10.6985 3.15592 10.7448L3.25 10.7507H14.9583L10.3826 15.3908C10.116 15.6611 10.0966 16.0827 10.3224 16.3751L10.3901 16.4514C10.6605 16.718 11.082 16.7374 11.3744 16.5115L11.4507 16.4439L17.2841 10.5272C17.548 10.2595 17.57 9.84306 17.3501 9.55058L17.2841 9.4741L11.4507 3.55743C11.1599 3.26247 10.6851 3.2591 10.3901 3.54991Z" fill="black" />
                        </svg>

                    )}
                </>
            )}



        </button>
    );
};

export default MyButtonWhite;