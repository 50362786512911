import React, { useEffect, useState } from 'react'
import ChannelsContent from './ChannelsContent';
import './style.scss';
import { setButtonClick, setErrorButton, setIconButton, setIsShowButton, setTextButton } from '../../store/buttonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setIsShowButtonNot } from '../../store/buttonNotSlice';
import CustomTitle from '../../components/UI/title/CustomTitle';

export default function Channels({ }) {
    const initialCheckboxState = {
        Google: false,
        TikTok: false,
        Facebook: false,
        Instagram: false,
    };

    const [disabled, setDisabled] = useState(true);
    const [checkboxes, setCheckboxes] = useState(initialCheckboxState);
    const [error, setError] = useState(false);

    const [value, setValue] = useState('');

    useEffect(() => {
        const updatedCheckboxes = Object.keys(checkboxes).reduce((acc, key) => {
            if (checkboxes[key]) {
            acc.push(key);
            }
            return acc;
        }, []);
        setValue(updatedCheckboxes);
    }, [checkboxes])

    const dispatch = useDispatch();

    const history = useHistory();
    const buttonClicked = useSelector((state) => state.button.buttonClicked);
    const nextPage = useSelector((state) => state.appRoute.nextPage);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (buttonClicked) {
            if (disabled) {
                setError(true);
                dispatch(setErrorButton(true))
                dispatch(setButtonClick(false));
                setTimeout(() => {
                    setError(false);
                    dispatch(setErrorButton(false))
                }, 2000);
                return;
            }
            dispatch(setButtonClick(false));
            queryParams.set('ad-channels', 1);
            queryParams.set('ad-channels-value', value);

            history.push({
                pathname: nextPage,
                search: `?${queryParams.toString()}`
            });
        }
    }, [buttonClicked, dispatch, disabled]);


    useEffect(() => {
        dispatch(setIsShowButton(true));
        dispatch(setTextButton('Choose platforms'));
        dispatch(setIconButton(''));

        dispatch(setIsShowButtonNot(false));
    }, []);


    return (
        <div className="channels page">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title={'Which platforms would you pick for your first ads?'}
                />
                <div className="channels-wrapper">
                    <ChannelsContent setError={setError} checkboxes={checkboxes} setCheckboxes={setCheckboxes} error={error} setDisabled={setDisabled} />
                </div>

            </div>

        </div>
    )
}
