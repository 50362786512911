import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const unsplashApi = createApi({
    reducerPath: 'unsplashApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://unsplash.com' }),
    endpoints: (builder) => ({
        fetchPictures: builder.query({
            query: ({ query}) =>
                `/napi/search/photos?query=${encodeURIComponent(query)}&per_page=${10}`,
            transformResponse: (response) =>
                response.results
                    .map((item) => item.urls['regular'])
                    .filter((url) => !url.includes('premium_photo'))
        })
    })
});

export const {
    useFetchPicturesQuery
} = unsplashApi;
