import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setButtonClick, setErrorButton, setIconButton, setIsShowButton, setTextButton } from '../../store/buttonSlice';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './style.scss';
import AvatarsContent from './AvatarsContent';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setIsShowButtonNot } from '../../store/buttonNotSlice';

export default function Avatars() {
    const[disabled, setDisabled] = useState(true);
    const [error, setError] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const dispatch = useDispatch();
    const history = useHistory();
    const buttonClicked = useSelector((state) => state.button.buttonClicked);
    const nextPage = useSelector((state) => state.appRoute.nextPage);

    useEffect(() => {
        if (buttonClicked) {
            if(disabled) {
                setError(true);
                dispatch(setErrorButton(true))
                dispatch(setButtonClick(false));
                setTimeout(() => {
                    setError(false);
                    dispatch(setErrorButton(false))
                }, 2000);
                return ;
            }
            
            dispatch(setButtonClick(false));
            queryParams.set('video-avatars', 1);
            history.push({
                pathname: nextPage,
                search: `?${queryParams.toString()}`
            });
        }
    }, [buttonClicked, dispatch, disabled]);

    useEffect(() => {
        dispatch(setIsShowButton(true));
        dispatch(setTextButton('Choose AI avatars'));
        dispatch(setIconButton(''));
        dispatch(setIsShowButtonNot(false));
    }, []);



    return (
        <div className="page avatars">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title={'Who would you pick to represent your ads?'}
                />
                <div className="avatars-content">
                    <div className="avatars-wrapper">
                        <AvatarsContent setDisabled={setDisabled} error={error} setError={setError}/>
                    </div>
                    <div className="avatars-bottom">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9488 14.5399C8.49884 14.5399 5.58789 15.1037 5.58789 17.2794C5.58789 19.4561 8.51765 20 11.9488 20C15.3988 20 18.3098 19.4363 18.3098 17.2605C18.3098 15.0839 15.38 14.5399 11.9488 14.5399Z" fill="#CAD4DD" />
                            <path opacity="0.4" d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z" fill="#CAD4DD" />
                            <path opacity="0.4" d="M21.0881 9.21926C21.6925 6.84179 19.9205 4.70658 17.664 4.70658C17.4187 4.70658 17.1841 4.73359 16.9549 4.77952C16.9244 4.78673 16.8904 4.80204 16.8725 4.82905C16.8519 4.86327 16.8671 4.9092 16.8895 4.93892C17.5673 5.89531 17.9568 7.05973 17.9568 8.3097C17.9568 9.50744 17.5996 10.6241 16.9728 11.5508C16.9083 11.6463 16.9656 11.775 17.0793 11.7949C17.2369 11.8228 17.3981 11.8372 17.5629 11.8417C19.2059 11.8849 20.6807 10.8214 21.0881 9.21926Z" fill="#CAD4DD" />
                            <path d="M22.8094 14.8169C22.5086 14.1721 21.7824 13.73 20.6783 13.5129C20.1572 13.3851 18.747 13.2049 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9067 20.2888 17.8887C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9588 23.0637 15.3456 22.8094 14.8169Z" fill="#CAD4DD" />
                            <path opacity="0.4" d="M7.04459 4.77979C6.81626 4.73296 6.58077 4.70685 6.33543 4.70685C4.07901 4.70685 2.30701 6.84207 2.9123 9.21953C3.31882 10.8216 4.79355 11.8852 6.43661 11.842C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.5511C6.3999 10.6235 6.04263 9.50771 6.04263 8.30997C6.04263 7.0591 6.43303 5.89468 7.11085 4.93919C7.13234 4.90947 7.14845 4.86354 7.12696 4.82932C7.10906 4.8014 7.07593 4.787 7.04459 4.77979Z" fill="#CAD4DD" />
                            <path d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.485 3.13531 17.8065 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6831C3.61883 14.9166 5.9621 13.6045 6.56918 13.3028C6.59425 13.2884 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z" fill="#CAD4DD" />
                        </svg>
                        More avatars to represent your brand are regularly added and will be available in the app.
                    </div>
                </div>
            </div>
        </div>
    )
}
