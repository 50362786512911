
import { configureStore } from '@reduxjs/toolkit';
import overflowReducer from './overflowBlockSlice';
import blockClassNameReducer from "./blockClassNameSlice"; 
import termReducer from "./termSlice"; 
import { dataApi } from '../api/dataApi';
import scrappingReducer from './scrappingSlice';
import buttonReducer from './buttonSlice';
import buttonNotReducer from './buttonNotSlice';
import appRouteReducer from './appRouteSlice';
import { unsplashApi } from '../api/unsplashApi';

// Ensure all reducers and APIs are correctly imported and defined

const store = configureStore({
  reducer: {
    overflow: overflowReducer,
    blockClassName: blockClassNameReducer,
    term: termReducer,
    scrapping: scrappingReducer,
    button: buttonReducer,
    buttonNot: buttonNotReducer,
    appRoute: appRouteReducer,
    [dataApi.reducerPath]: dataApi.reducer,
    [unsplashApi.reducerPath]: unsplashApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(dataApi.middleware, unsplashApi.middleware),
});

export default store;
