import React, { useState } from 'react'
import CustomRadio from '../../components/UI/radio/CustomRadio';
import { setErrorButton } from '../../store/buttonSlice';
import { useDispatch } from 'react-redux';

export default function PlatfromsContent({ checkboxes, setCheckboxes, error, setDisabled, setError, setValue }) {

    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(null);

    const handleCheckboxChange = (checkbox) => {
        setSelectedOption(checkbox.id);
        setDisabled(false)
        setError(false);
        dispatch(setErrorButton(false))
        setValue(checkbox.img)
    };


    const checkboxesData = checkboxes ? checkboxes :  [
        {
            id: 'option1',
            checked: false,
            text: "Shopify",
            img: 'Shopify.svg',
        },
        {
            id: 'option2',
            checked: false,
            text: "Wix",
            img: 'Wix.svg',
        },
        {
            id: 'option3',
            checked: false,
            text: "WordPress",
            img: 'WordPress.svg',
        },
        {
            id: 'option4',
            checked: false,
            text: "Etsy",
            img: 'Etsy.svg',
        }, {
            id: 'option5',
            checked: false,
            text: "Amazon",
            img: 'amazon.svg',
        },
        {
            id: 'option6',
            checked: false,
            text: "SquareSpace",
            img: 'SquareSpace.svg',
        },
        {
            id: 'option7',
            checked: false,
            text: "TikTok",
            img: 'TikTok.svg',
        },
        {
            id: 'option8',
            checked: false,
            text: "Facebook",
            img: 'Facebook.svg',
        },
        {
            id: 'option9',
            checked: false,
            text: "Instagram",
            img: 'Instagram2.svg',
        },
        {
            id: 'option10',
            checked: false,
            text: "Other",
            img: 'domain_ico.svg',
        },
    ]


    return (

        <>
            {checkboxesData && checkboxesData.map((checkbox) => (
                <CustomRadio
                    key={checkbox.id}
                    id={checkbox.id}
                    checked={selectedOption === checkbox.id}
                    onChange={() => handleCheckboxChange(checkbox)}
                    className={error ? 'error' : undefined}
                    style={{ marginBottom: '8rem' }}
                >
                    <img src={process.env.PUBLIC_URL + `/img/${checkbox.img}`} style={{ minWidth: '20rem', width: "20rem", height: "20rem", borderRadius: '0' }} alt="" />
                    {checkbox.text}
                </CustomRadio>
            ))}</>
    )
}
