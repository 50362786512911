import React, { useState } from 'react'
import { useScrappingQuery } from '../../../../api/dataApi';

export default function AiAnalyzerImg() {

    const [url, setUrl] = useState(localStorage.getItem('site-url'))

    const { data: scrappingData, error: scrappingError, isLoading: isScrapping } = useScrappingQuery({
        url: url,
    });

    return (
        <div className="ai-analyzer-top__img--phone">
            <img src={process.env.PUBLIC_URL + '/img/phone_mokup.webp'} alt="" />
            <img src={scrappingData?.screenshot} alt="" />
        </div>
    )
}
