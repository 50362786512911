import React, { useRef, useState } from 'react';
import './style.scss';
import { gsap } from 'gsap';

export default function ProductCard({ title, onClick, img }) {

    const myElementRef = useRef(null);

    const [animate, setAnimate] = useState(false);
    const handleClick = () => {


        setAnimate(true);

        myElementRef.current.style.pointerEvents = 'none';
        const tl = gsap.timeline();
        tl.to(myElementRef.current, {
            duration: 0.085,
            scale: 0.9,
            opacity: 0.85,
            ease: "power4.out",
        });

        tl.to(myElementRef.current, {
            duration: 0.55,
            scale: 1,
            opacity: 1,
            ease: "elastic.out(1,0.6)",
        });


        // if ('vibrate' in navigator) {
        //     navigator.vibrate(100);
        // }
        setTimeout(() => {
            if (onClick) {
                myElementRef.current.style.pointerEvents = 'auto';
                onClick();
            }
        }, 200);
        setTimeout(() => {
            setAnimate(false);
        }, 635);

    };

    return (
        <div className="productCard" onClick={handleClick} ref={myElementRef}>
            <img
                src={process.env.PUBLIC_URL + img}
                alt=""
            />
            <div className="productCard-bottom">
                {title}
                <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.73407 2.62955C6.57184 2.7895 6.55662 3.04224 6.68961 3.21957L6.72955 3.26593L9.475 6.05L2.45 6.05C2.20147 6.05 2 6.25147 2 6.5C2 6.72941 2.17167 6.91873 2.39355 6.94649L2.45 6.95H9.475L6.72955 9.73407C6.56961 9.8963 6.55798 10.1492 6.69347 10.3247L6.73407 10.3704C6.8963 10.5304 7.14922 10.542 7.32465 10.4065L7.37045 10.3659L10.8704 6.81593C11.0288 6.65533 11.042 6.40544 10.91 6.22996L10.8704 6.18407L7.37045 2.63407C7.19596 2.45709 6.91104 2.45507 6.73407 2.62955Z"
                        fill="white"
                    />
                </svg>
            </div>
        </div>
    );
}
