import React, { useEffect, useState } from 'react'
import './style.scss'
import MyButton from '../../../../components/UI/button/MyButton';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setIsShowPrivacy } from '../../../../store/termSlice';

export default function Tariffs({ final, activePlan, setActivePlan, buttonShow }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showInfo, setShowInfo] = useState(false);
    const onShowInfo = () => {
        setShowInfo(!showInfo);
    }

    const closeShowInfo = () => {
        setShowInfo(false);
    }



    useEffect(() => {
        localStorage.setItem('price', '1');
    }, [])

    const handlePlanClick = (planId) => {
        setActivePlan(planId);

        localStorage.setItem('price', planId);

    };


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const handleClick = () => {
        dispatch(setIsShowPrivacy(true));
    }
    return (
        <>
            {!final &&
                <h2>Choose your plan</h2>
            }

            <div className="plan-items">
                <div className={`plan-plans__plan ${activePlan === '0' ? 'active' : ''}`} onClick={() => handlePlanClick('0')}>
                    <div className="plan-plans__top">
                        <div className="plan-plans__top-info">
                            <div className="plan-plans__plan-title">2-week trial</div>
                            <p className="plan-plans__plan-price">
                                <span>$42.00</span>
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.23407 2.62955C6.07184 2.7895 6.05662 3.04224 6.18961 3.21957L6.22955 3.26593L8.975 6.05L1.95 6.05C1.70147 6.05 1.5 6.25147 1.5 6.5C1.5 6.72941 1.67167 6.91873 1.89355 6.94649L1.95 6.95H8.975L6.22955 9.73407C6.06961 9.8963 6.05798 10.1492 6.19347 10.3247L6.23407 10.3704C6.3963 10.5304 6.64922 10.542 6.82465 10.4065L6.87045 10.3659L10.3704 6.81593C10.5288 6.65533 10.542 6.40544 10.41 6.22996L10.3704 6.18407L6.87045 2.63407C6.69596 2.45709 6.41104 2.45507 6.23407 2.62955Z" fill="#8F969C"></path>
                                </svg> {final ? '$8.40' : '$19.95'}</p>
                        </div>
                        <div className="plan-plans__top-trial">
                            <div className="plan-plans__top-trial-last">
                                <i>$2.97</i>
                                <small>{final ? '$0.59' : '$1.42'}</small>
                                <span>per day</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`plan-plans__plan ${activePlan === '1' ? 'active' : ''} plan-plans__plan--popular`} onClick={() => handlePlanClick('1')}>
                    <div className="plan-plans__plan__badge">Most popular</div><div className="plan-plans__top">
                        <div className="plan-plans__top-info">
                            <div className="plan-plans__plan-title">4-week plan</div>
                            <p className="plan-plans__plan-price">
                                <span>$83.00</span>
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.23407 2.62955C6.07184 2.7895 6.05662 3.04224 6.18961 3.21957L6.22955 3.26593L8.975 6.05L1.95 6.05C1.70147 6.05 1.5 6.25147 1.5 6.5C1.5 6.72941 1.67167 6.91873 1.89355 6.94649L1.95 6.95H8.975L6.22955 9.73407C6.06961 9.8963 6.05798 10.1492 6.19347 10.3247L6.23407 10.3704C6.3963 10.5304 6.64922 10.542 6.82465 10.4065L6.87045 10.3659L10.3704 6.81593C10.5288 6.65533 10.542 6.40544 10.41 6.22996L10.3704 6.18407L6.87045 2.63407C6.69596 2.45709 6.41104 2.45507 6.23407 2.62955Z" fill="#8F969C"></path>
                                </svg>{final ? '$16.60' : '$29.95'}</p>
                        </div>
                        <div className="plan-plans__top-trial">
                            <div className="plan-plans__top-trial-last">
                                <i>$2.35</i>
                                <small>{final ? '$0.47' : '$1.06'}</small>
                                <span>per day</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`plan-plans__plan ${activePlan === '2' ? 'active' : ''}`} onClick={() => handlePlanClick('2')}>
                    <div className="plan-plans__top">
                        <div className="plan-plans__top-info">
                            <div className="plan-plans__plan-title">8-week plan</div>
                            <p className="plan-plans__plan-price">
                                <span>$167.00</span>
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.23407 2.62955C6.07184 2.7895 6.05662 3.04224 6.18961 3.21957L6.22955 3.26593L8.975 6.05L1.95 6.05C1.70147 6.05 1.5 6.25147 1.5 6.5C1.5 6.72941 1.67167 6.91873 1.89355 6.94649L1.95 6.95H8.975L6.22955 9.73407C6.06961 9.8963 6.05798 10.1492 6.19347 10.3247L6.23407 10.3704C6.3963 10.5304 6.64922 10.542 6.82465 10.4065L6.87045 10.3659L10.3704 6.81593C10.5288 6.65533 10.542 6.40544 10.41 6.22996L10.3704 6.18407L6.87045 2.63407C6.69596 2.45709 6.41104 2.45507 6.23407 2.62955Z" fill="#8F969C"></path>
                                </svg>{final ? '$33.40' : '$49.95'}</p>
                        </div>
                        <div className="plan-plans__top-trial">
                            <div className="plan-plans__top-trial-last">
                                <i>$1.77</i>
                                <small>{final ? '$0.35' : '$0.89'}</small>
                                <span>per day</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="plan-info-text">First subscription discounted automatically <span onClick={onShowInfo}><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" stroke="#8F969C"></rect>
                <path d="M7.03022 3.09961C7.78922 3.09961 8.42722 3.31961 8.92222 3.75961C9.41722 4.21061 9.67022 4.77161 9.67022 5.44261C9.67022 6.04761 9.47222 6.55361 9.07622 6.97161C8.66922 7.38961 8.15222 7.63161 7.51422 7.70861L7.49222 8.77561H6.46922L6.41422 6.97161H6.61222C7.80022 6.97161 8.51522 6.35561 8.51522 5.48661C8.51522 4.66161 7.88822 4.04561 6.99722 4.04561C6.11722 4.04561 5.47922 4.65061 5.44622 5.47561H4.32422C4.32422 4.78261 4.57722 4.22161 5.09422 3.77061C5.60022 3.31961 6.24922 3.09961 7.03022 3.09961ZM6.43622 9.57861C6.73322 9.30361 7.28322 9.30361 7.59122 9.57861C7.88822 9.85361 7.88822 10.4036 7.59122 10.6786C7.28322 10.9536 6.73322 10.9536 6.43622 10.6786C6.12822 10.4036 6.12822 9.85361 6.43622 9.57861Z" fill="#8F969C"></path>
            </svg>
                {showInfo && <b> </b>}
            </span>
                {showInfo && <div className="priceInfo-wrapper">
                    <div className="priceInfo__title">Subscription details</div>
                    <div className="priceInfo__close" onClick={closeShowInfo}>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3.5L13 13.5" stroke="white" stroke-linecap="round"></path>
                            <path d="M13 3.5L3 13.5" stroke="white" stroke-linecap="round"></path>
                        </svg>
                    </div>
                    <p>We’ve added a discount to your first subscription automatically. After that, your subscription will renew at the full price of $79.95 per month. You can manage your subscription anytime through the app or by emailing support@zeely.app. For more details, check our <a onClick={handleClick}><i>Subscription Policy.</i></a></p></div>}

            </div>
            <div style={{ opacity: buttonShow ? 1 : 0 }}>
                <MyButton

                    onClick={() => {

                        // queryParams.set('email', name);

                        history.push({
                            pathname: '/checkout',
                            search: `?${queryParams.toString()}`,
                        });
                    }}
                    buttonIcon={""}
                    buttonText={`Get my plan`}
                ></MyButton>
            </div>


        </>
    )
}
