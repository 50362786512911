import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
  status: '',
  text: "Continue",
  icon: '',
  buttonClicked: false,
  error: false,
};

const buttonSlice = createSlice({
  name: 'button',
  initialState,
  reducers: {
    setIsShowButton: (state, action) => {
      state.isShow = action.payload;
    },
    setStatusButton: (state, action) => {
      state.status = action.payload;
    },
    setTextButton: (state, action) => {
      state.text = action.payload;
    },
    setIconButton: (state, action) => {
      state.icon = action.payload;
    },
    setErrorButton: (state, action) => {
      state.error = action.payload;
    },
    setButtonClick: (state, action) => {
      state.buttonClicked = action.payload;
    },
  },
});

export const {
  setIsShowButton,
  setStatusButton,
  setTextButton,
  setIconButton,
  setErrorButton,
  setButtonClick
} = buttonSlice.actions;

export default buttonSlice.reducer;