import React, { useEffect, useState } from 'react'
import './style.scss';
import { setButtonClick, setErrorButton, setIconButton, setIsShowButton, setTextButton } from '../../store/buttonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setIsShowButtonNot } from '../../store/buttonNotSlice';
import CustomTitle from '../../components/UI/title/CustomTitle';
import CurrentContent from './CurrentContent';

export default function Current() {

    const initialCheckboxState = [[
        {
            id: 0,
            checked: false,
            text: "0–10",
        },
        {
            id: 1,
            checked: false,
            text: "10–30",
        },
        {
            id: 2,
            checked: false,
            text: "30–70",
        },
        {
            id: 3,
            checked: false,
            text: "70–150",
        },
        {
            id: 4,
            checked: false,
            text: "150+",
        }
    ], [
        {
            id: 0,
            checked: false,
            text: "Not sure",
        },
        
        {
            id: 1,
            checked: false,
            text: "$0-10",
        },
        {
            id: 2,
            checked: false,
            text: "$10-20",
        },
        {
            id: 3,
            checked: false,
            text: "$20-50",
        },
        {
            id: 4,
            checked: false,
            text: "$50-100",
        },
        {
            id: 5,
            checked: false,
            text: "$100+",
        }
    ], [
        {
            id: 0,
            checked: false,
            text: "Not sure",
        },
        {
            id: 1,
            checked: false,
            text: "0-1",
        },
        {
            id: 2,
            checked: false,
            text: "1-5",
        },
        {
            id: 3,
            checked: false,
            text: "5-10",
        },
        {
            id: 4,
            checked: false,
            text: "10-15",
        },
        {
            id: 5,
            checked: false,
            text: "15+",
        }
    ], [
        {
            id: 0,
            checked: false,
            text: "0-1%",
        },
        {
            id: 1,
            checked: false,
            text: "1-3%",
        },
        {
            id: 2,
            checked: false,
            text: "3-5%",
        },
        {
            id: 3,
            checked: false,
            text: "5-10%",
        },
        {
            id: 4,
            checked: false,
            text: "10-15%",
        },
        {
            id: 5,
            checked: false,
            text: "15+%",
        }
    ]];

    const title = [
        'How many purchases do you receive monthly?',
        'What is your current average CPA rate?',
        'What is your current ROAS?',
        'What is your current CTR rate?'
    ]
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const [disabled, setDisabled] = useState(true);
    const [checkboxes, setCheckboxes] = useState(initialCheckboxState[queryParams.get('goals') ?? 0]);
    const [error, setError] = useState(false);
    const [value, setValue] = useState('');
    const [valueId, setValueId] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();
    const buttonClicked = useSelector((state) => state.button.buttonClicked);
    const nextPage = useSelector((state) => state.appRoute.nextPage);

    useEffect(() => {
        if (buttonClicked) {
            if (disabled) {
                setError(true);
                dispatch(setErrorButton(true))
                dispatch(setButtonClick(false));
                setTimeout(() => {
                    setError(false);
                    dispatch(setErrorButton(false))
                }, 2000);
                return;
            }
            dispatch(setButtonClick(false));
            queryParams.set('current', value);
            queryParams.set('current-id', valueId);
            history.push({
                pathname: nextPage,
                search: `?${queryParams.toString()}`,
            });
        }
    }, [buttonClicked, dispatch, disabled]);


    useEffect(() => {
        dispatch(setIsShowButton(true));
        dispatch(setTextButton('Continue'));
        dispatch(setIconButton(''));

        dispatch(setIsShowButtonNot(false));
    }, []);


    return (
        <div className=" page">
            <div className="page-top" style={{paddingRight: '1rem'}}>
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title={title[queryParams.get('goals') ? queryParams.get('goals') : 0]}
                />
           
                    <CurrentContent setValueId={setValueId} setValue={setValue} checkboxes={checkboxes} setCheckboxes={setCheckboxes} error={error} setDisabled={setDisabled} setError={setError} />
      
            </div>
        </div>
    )
}
