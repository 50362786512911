import React from 'react'
import CustomTitle from '../../components/UI/title/CustomTitle'
import './style.scss'
import MyButton from '../../components/UI/button/MyButton'
import MyButtonWhite from '../../components/UI/button/MyButtonWhite'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export default function FinalOffer() {

    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    return (
        <div className='final-offer'>
            <div className="final-offer-top">
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title="Last call to get your AI copilot with an additional discount!"
                />
                <div className="final-offer-info">
                    <div className="final-offer-info-top"><span>What you'll get</span><span>Free</span><span>Growth️</span></div>
                    <div className="final-offer-info-list"><span>Add your product</span><span>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.20049 7.50771L5.19194 10.2L11.1748 4.8154" stroke="#8F969C" stroke-width="1.3" stroke-linecap="round"></path>
                        </svg></span><span>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.69531 7.50871L5.68677 10.201L11.6697 4.81641" stroke="black" stroke-width="1.3" stroke-linecap="round"></path>
                            </svg></span></div>
                    <div className="final-offer-info-list"><span>Make UGC style AI videos</span><span>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 7.5L10.5 7.50684" stroke="#8F969C" stroke-width="1.3" stroke-linecap="round"></path>
                        </svg></span><span>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.69531 7.50871L5.68677 10.201L11.6697 4.81641" stroke="black" stroke-width="1.3" stroke-linecap="round"></path>
                            </svg></span></div>
                    <div className="final-offer-info-list"><span>30+ realistic avatars</span><span>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 7.5L10.5 7.50684" stroke="#8F969C" stroke-width="1.3" stroke-linecap="round"></path>
                        </svg></span><span>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.69531 7.50871L5.68677 10.201L11.6697 4.81641" stroke="black" stroke-width="1.3" stroke-linecap="round"></path>
                            </svg></span></div>
                    <div className="final-offer-info-list"><span>Create static AI banners</span><span>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 7.5L10.5 7.50684" stroke="#8F969C" stroke-width="1.3" stroke-linecap="round"></path>
                        </svg></span><span>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.69531 7.50871L5.68677 10.201L11.6697 4.81641" stroke="black" stroke-width="1.3" stroke-linecap="round"></path>
                            </svg></span></div>
                    <div className="final-offer-info-list"><span>100+ proven ads templates</span><span>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 7.5L10.5 7.50684" stroke="#8F969C" stroke-width="1.3" stroke-linecap="round"></path>
                        </svg></span><span>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.69531 7.50871L5.68677 10.201L11.6697 4.81641" stroke="black" stroke-width="1.3" stroke-linecap="round"></path>
                            </svg></span></div>
                    <div className="final-offer-info-list"><span>AI launch ad campaigns</span><span>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 7.5L10.5 7.50684" stroke="#8F969C" stroke-width="1.3" stroke-linecap="round"></path>
                        </svg></span><span>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.69531 7.50871L5.68677 10.201L11.6697 4.81641" stroke="black" stroke-width="1.3" stroke-linecap="round"></path>
                            </svg></span></div>
                    <div className="final-offer-info-list"><span>24/7 AI to optimize &amp; scale</span><span>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 7.5L10.5 7.50684" stroke="#8F969C" stroke-width="1.3" stroke-linecap="round"></path>
                        </svg></span><span>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.69531 7.50871L5.68677 10.201L11.6697 4.81641" stroke="black" stroke-width="1.3" stroke-linecap="round"></path>
                            </svg></span></div>
                </div>
                <div className="final-offer-text">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_4860)">
                            <path d="M8.5 14C5.186 14 2.5 11.314 2.5 8C2.5 4.686 5.186 2 8.5 2C11.814 2 14.5 4.686 14.5 8C14.5 11.314 11.814 14 8.5 14Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.1654 6.66699L7.83203 10.0003L5.83203 8.00033" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_4860">
                                <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                    Enjoy all perks of succesfull ads with
                    <span>AI copilot!</span>

                </div>
            </div>

            <MyButton buttonText={'Grab my discount  <span>60%</span>80%'}
                buttonStatus={'html'}

                onClick={() => {

                    history.push({
                        pathname: '/final-plan',
                        search: `?${queryParams.toString()}`,
                    });
                }}
            />
            {/* <div className="final-offer-reject"
                      onClick={() => {

                        history.push({
                            pathname: '/',
                        });
                    }}
            >
                Reject offer
            </div> */}


        </div>
    )
}
