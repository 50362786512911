import React, { useEffect, useState } from 'react'
import './style.scss';
import { setButtonClick, setErrorButton, setIconButton, setIsShowButton, setTextButton } from '../../store/buttonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setIsShowButtonNot } from '../../store/buttonNotSlice';
import CustomTitle from '../../components/UI/title/CustomTitle';
import PlatfromsContent from './PlatformsContent';

export default function Platfroms() {
    const initialCheckboxState = [
        {
            id: 'option1',
            checked: false,
            text: "Shopify",
            img: 'Shopify.svg',
        },
        {
            id: 'option2',
            checked: false,
            text: "Wix",
            img: 'Wix.svg',
        },
        {
            id: 'option3',
            checked: false,
            text: "WordPress",
            img: 'WordPress.svg',
        },
        {
            id: 'option4',
            checked: false,
            text: "Etsy",
            img: 'Etsy.svg',
        }, {
            id: 'option5',
            checked: false,
            text: "Amazon",
            img: 'amazon.svg',
        },
        {
            id: 'option6',
            checked: false,
            text: "SquareSpace",
            img: 'SquareSpace.svg',
        },
        {
            id: 'option7',
            checked: false,
            text: "TikTok",
            img: 'TikTok.svg',
        },
        {
            id: 'option8',
            checked: false,
            text: "Facebook",
            img: 'Facebook.svg',
        },
        {
            id: 'option9',
            checked: false,
            text: "Instagram",
            img: 'Instagram2.svg',
        },
        {
            id: 'option10',
            checked: false,
            text: "Other",
            img: 'domain_ico.svg',
        },
    ];

    const [disabled, setDisabled] = useState(true);
    const [checkboxes, setCheckboxes] = useState(initialCheckboxState);
    const [error, setError] = useState(false);
    const [value, setValue] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();
    const buttonClicked = useSelector((state) => state.button.buttonClicked);
    const nextPage = useSelector((state) => state.appRoute.nextPage);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (buttonClicked) {
            if (disabled) {
                setError(true);
                dispatch(setErrorButton(true))
                dispatch(setButtonClick(false));
                setTimeout(() => {
                    setError(false);
                    dispatch(setErrorButton(false))
                }, 2000);
                return;
            }
            dispatch(setButtonClick(false));
            queryParams.set('platforms-value', value);
            history.push({
                pathname: nextPage,
                search: `?${queryParams.toString()}`
            });
        }
    }, [buttonClicked, dispatch, disabled]);


    useEffect(() => {
        dispatch(setIsShowButton(true));
        dispatch(setTextButton('Continue'));
        dispatch(setIconButton(''));

        dispatch(setIsShowButtonNot(false));
    }, []);


    return (
        <div className="platfroms page">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title={'Choose your platform'}
                />
                <div className="platfroms-wrapper">
                    <PlatfromsContent checkboxes={checkboxes} setCheckboxes={setCheckboxes} error={error} setDisabled={setDisabled} setError={setError} setValue={setValue}/>
                </div>
            </div>
        </div>
    )
}
