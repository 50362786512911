import React from 'react'
import { useFetchBusinessProfilesQuery } from '../../../../api/dataApi';

export default function WebsiteLoaderImg() {
    const { data: businessProfiles, isLoading: isLoadingProfiles } = useFetchBusinessProfilesQuery();
    const direction = businessProfiles?.data?.direction;
    return (
        <div style={{ aspectRatio: "5/6", margin: '0 0 24rem' }} >
            {direction === "SERVICE" ? <img src={process.env.PUBLIC_URL + '/img/website-loader-service.jpg'} alt="" /> : <img src={process.env.PUBLIC_URL + '/img/website-loader.jpg'} alt="" />}
        </div>
    )
} 
