import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  screenshot: undefined,
  industry: undefined,
  brandName: undefined,
};

const scrappingSlice = createSlice({
  name: 'scrapping',
  initialState,
  reducers: {
    setScreenshot: (state, action) => {
      state.screenshot = action.payload;
    },
    setIndustry: (state, action) => {
      state.industry = action.payload;
    },
    setBrandName: (state, action) => {
      state.brandName = action.payload;
    },
    resetScrapping: () => initialState,
  },
});

export const {
  setScreenshot,
  setIndustry,
  setBrandName,
  resetScrapping,
} = scrappingSlice.actions;

export default scrappingSlice.reducer;