import React, { useEffect, useState } from 'react'

import './style.scss';
import { setButtonClick, setErrorButton, setIconButton, setIsShowButton, setTextButton } from '../../store/buttonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CustomTitle from '../../components/UI/title/CustomTitle';
import ReachContent from './ReachContent';

export default function Reach() {
    const initialCheckboxState = [
        [
            {
                id: '0',
                checked: false,
                title: 'Next month',
                number: '1',
                text: "months",
                days: '30',
            },
            {
                id: '1',
                checked: false,
                title: 'In three months',
                number: '3',
                text: "months",
                days: '90',
            },
            {
                id: '2',
                checked: false,
                title: 'Up to six months',
                number: '6',
                text: "months",
                days: '180',
            },
            {
                id: '3',
                checked: false,
                title: 'Up to three quarters',
                number: '9',
                text: "months",
                days: '270',
            }
        ],
        [
            {
                id: '0',
                checked: false,
                title: 'In two weeks',
                number: '2',
                text: "weeks",
                days: '14',
            },
            {
                id: '1',
                checked: false,
                title: 'Up to a month',
                number: '4',
                text: "weeks",
                days: '28',
            },
            {
                id: '2',
                checked: false,
                title: 'In six weeks',
                number: '6',
                text: "weeks",
                days: '42',
            },
            {
                id: '3',
                checked: false,
                title: 'In two months or more',
                number: '8',
                text: "weeks",
                days: '56',
            }
        ],
        [
            {
                id: '0',
                checked: false,
                title: 'Next month',
                number: '1',
                text: "months",
                days: '30',
            },
            {
                id: '1',
                checked: false,
                title: 'Up to two months',
                number: '2',
                text: "months",
                days: '60',
            },
            {
                id: '2',
                checked: false,
                title: 'In three months',
                number: '3',
                text: "months",
                days: '90',
            },
            {
                id: '3',
                checked: false,
                title: 'Up to six months',
                number: '6',
                text: "months",
                days: '180',
            }
        ],
        [
            {
                id: '0',
                checked: false,
                title: 'In a week',
                number: '1',
                text: "week",
                days: '7',
            },
            {
                id: '1',
                checked: false,
                title: 'In two weeks',
                number: '2',
                text: "weeks",
                days: '14',
            },
            {
                id: '2',
                checked: false,
                title: 'Up to a month',
                number: '4',
                text: "weeks",
                days: '28',
            },
            {
                id: '3',
                checked: false,
                title: 'In two months or more',
                number: '8',
                text: "weeks",
                days: '56',
            }
        ]
    ];
    


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [disabled, setDisabled] = useState(true);
    const [checkboxes, setCheckboxes] = useState(initialCheckboxState[queryParams.get('goals') ?? 0]);
    const [error, setError] = useState(false);
    const [days, setDays] = useState(0);

    const dispatch = useDispatch();
    const history = useHistory();
    const buttonClicked = useSelector((state) => state.button.buttonClicked);
    const nextPage = useSelector((state) => state.appRoute.nextPage);

    useEffect(() => {
        if (buttonClicked) {
            if (disabled) {
                setError(true);
                dispatch(setErrorButton(true))
                dispatch(setButtonClick(false));
                setTimeout(() => {
                    setError(false);
                    dispatch(setErrorButton(false))
                }, 2000);
                return;
            }
            dispatch(setButtonClick(false));
            queryParams.set('days', days);
            history.push({
                pathname: nextPage,
                search: `?${queryParams.toString()}`,
            });
        }
    }, [buttonClicked, dispatch, disabled, checkboxes]);


    useEffect(() => {
        dispatch(setIsShowButton(true));
        dispatch(setTextButton('Continue'));
        dispatch(setIconButton(''));
    }, []);



    return (
        <div className="reach page">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title={'When do you want to reach your goal?'}
                />
                <div className="reach-wrapper">
                    <ReachContent setDays={setDays} setError={setError} checkboxes={checkboxes} setCheckboxes={setCheckboxes} error={error} setDisabled={setDisabled} />
                </div>

            </div>

        </div>
    )
}
