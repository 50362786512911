import React, { useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './style.scss';
import { useDispatch } from 'react-redux';
import { setIsShowButton } from '../../store/buttonSlice';
import ProductsContent from './ProductsContent';

export default function Products() {

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setIsShowButton(false))
    }, []);

    return (
        <div className="page products">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title="What are you selling?"
                />
                <div className="products-wrapper">
                    <ProductsContent/>
                </div>
            </div>
        </div>
    );
}
