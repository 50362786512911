import React from 'react'
import { useDispatch } from 'react-redux';
import { setErrorButton } from '../../store/buttonSlice';

export default function ChannelsContent({ checkboxes, setCheckboxes, error, setDisabled, setError }) {

    const dispatch = useDispatch();

    const handleCheckboxChange = (checkboxName, isChecked) => {
        setCheckboxes((prevCheckboxes) => {
            const updatedCheckboxes = {
                ...prevCheckboxes,
                [checkboxName]: Boolean(isChecked),
            };
            const anyChecked = Object.values(updatedCheckboxes).some(value => value);
            setDisabled(!anyChecked);
            dispatch(setErrorButton(false))
            setError(false)
            return updatedCheckboxes;
        });
    };

    const checkboxesData = checkboxes ? checkboxes :  {
        Google: false,
        TikTok: false,
        Facebook: false,
        Instagram: false,
    }

    return (

        <>
        {checkboxesData && Object.keys(checkboxesData).map((checkboxName) => (
            <div
                key={checkboxName}
                checked={checkboxesData[checkboxName]}
                onClick={() => handleCheckboxChange(checkboxName, !checkboxesData[checkboxName])}

                className={`channels-item ${checkboxesData[checkboxName] ? 'checked' : undefined}`}
            >
                <div className={`channels-item__checked ${error ? 'error' : ""} ${checkboxesData[checkboxName] ? 'checked' : ""}`}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.30859 6.00764L4.8727 8.31534L10.0009 3.69995" stroke="white" stroke-width="1.6" stroke-linecap="round" />
                    </svg>
                </div>
             
                    <img src={process.env.PUBLIC_URL + `/img/${checkboxName}.svg`} alt="" />
          
                {checkboxName}
            </div>
        ))}</>
    )
}
