import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
  status: '',
  text: "Not sure",
  icon: '',
  buttonClicked: false,
  error: false,
};

const buttonNotSlice = createSlice({
  name: 'buttonNot',
  initialState,
  reducers: {
    setIsShowButtonNot: (state, action) => {
      state.isShow = action.payload;
    },
    setStatusButtonNot: (state, action) => {
      state.status = action.payload;
    },
    setTextButtonNot: (state, action) => {
      state.text = action.payload;
    },
    setIconButtonNot: (state, action) => {
      state.icon = action.payload;
    },
    setErrorButtonNot: (state, action) => {
      state.error = action.payload;
    },
    setButtonClickNot: (state, action) => {
      state.buttonClicked = action.payload;
    },
  },
});

export const {
  setIsShowButtonNot,
  setStatusButtonNot,
  setTextButtonNot,
  setIconButtonNot,
  setErrorButtonNot,
  setButtonClickNot
} = buttonNotSlice.actions;

export default buttonNotSlice.reducer;