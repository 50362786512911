import React from 'react'
import { useDispatch } from 'react-redux';
import { setErrorButton } from '../../store/buttonSlice';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function GoalsContent({ checkboxes, setCheckboxes, error, setDisabled, setError }) {

    const dispatch = useDispatch();
    const handleCheckboxChange = (checkboxId, isChecked) => {

        setCheckboxes(prevCheckboxes => {
            const updatedCheckboxes = prevCheckboxes.map(checkbox =>
                checkbox.id === checkboxId ? { ...checkbox, checked: isChecked } : checkbox
            );

            const anyChecked = updatedCheckboxes.some(checkbox => checkbox.checked);
            setDisabled(!anyChecked);
            dispatch(setErrorButton(false))
            setError(false)
            return updatedCheckboxes;
        });
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const checkboxesData = checkboxes ? checkboxes : [
        {
            id: '0',
            checked: false,
            title: 'Purchases',
            text: "Increase amount of orders",
            img: 'Shopify.svg',
        },
        {
            id: '2',
            checked: false,
            title: 'ROAS',
            text: "Boost return on ad spend",
            img: 'g2.svg',
        },
        {
            id: '1',
            checked: false,
            title: 'CPA',
            text: "Lower cost per action",
            img: 'g2.svg',
        },

        {
            id: '3',
            checked: false,
            title: 'CTR',
            text: "Improve click through rate",
            img: 'g2.svg',
        }
    ]

    const socials = queryParams.get('ad-channels-value') ? queryParams.get('ad-channels-value').split(',') : [];

    return (

        <>
            {checkboxesData && checkboxesData.map((checkbox, index) => (
                <div
                    key={checkbox.id}
                    checked={checkbox.checked}
                    onClick={() => handleCheckboxChange(checkbox.id, !checkbox.checked)}

                    className={`goals-item ${checkbox.checked ? 'checked' : undefined}`}
                >
                    <div className={`goals-item__checked ${error ? 'error' : ""} ${checkbox.checked ? 'checked' : ""}`}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.30859 6.00764L4.8727 8.31534L10.0009 3.69995" stroke="white" stroke-width="1.6" stroke-linecap="round" />
                        </svg>
                    </div>
                    {index === 0 ?
                        (
                            queryParams.get('platforms-value') ? (<img src={process.env.PUBLIC_URL + `/img/${queryParams.get('platforms-value')}`} alt="" />):(<img src={process.env.PUBLIC_URL + `/img/SVG_Goals/${checkbox.img}`} alt="" />)
                            
                        ) : (<div className="goals-item__images">
                            {socials.length > 0 ? (socials.map((social, index) => (
                                <img src={process.env.PUBLIC_URL + `/img/SVG_Goals/${social}.svg`} alt="" />
                            ))) : (
                                <>
                                    <img src={process.env.PUBLIC_URL + `/img/SVG_Goals/Google.svg`} alt="" />
                                    <img src={process.env.PUBLIC_URL + `/img/SVG_Goals/TikTok.svg`} alt="" />
                                    <img src={process.env.PUBLIC_URL + `/img/SVG_Goals/Facebook.svg`} alt="" />
                                    <img src={process.env.PUBLIC_URL + `/img/SVG_Goals/Instagram.svg`} alt="" />
                                </>
                            )}

                        </div>
                        )
                    }
                    <div>
                        <h4>{checkbox.title}</h4>
                        <p>{checkbox.text}</p>
                    </div>
                </div>
            ))}</>
    )
}
