import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProductCard from '../../components/ProductCard/ProductCard';

export default function StageContent() {
    const history = useHistory();

    const handleClick = () => {
        history.push('/products');
	}
  return (
    <>
       <ProductCard
                            title={'Just starting'}
                            img={'/img/WEBP_selling stage/Just-started.webp'}
                            onClick={handleClick}
                        />
                        <ProductCard
                            title={'Already selling'}
                            img={'/img/WEBP_selling stage/Already selling.webp'}
                            onClick={handleClick}
                        />
    </>
  )
}
