import React from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ProductCard from '../../components/ProductCard/ProductCard';

export default function ProductsContent() {
    const history = useHistory();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const handleClick = (direction) => {
        queryParams.set('direction', direction);
        history.push({
            pathname: '/helped',
            search: `?${queryParams.toString()}`
        });
    };

    return (
        <>
            <ProductCard
                title={'Products'}
                img={'/img/WEBP_Type/Products.webp'}
                onClick={() => handleClick("PRODUCT")}
            />
            <ProductCard
                title={'Services'}
                img={'/img/WEBP_Type/Services.webp'}
                onClick={() => handleClick("SERVICE")}
            />
            <ProductCard
                title={'Digital'}
                img={'/img/WEBP_Type/Digital.webp'}
                onClick={() => handleClick("PRODUCT")}
            />
            <ProductCard
                title={'Dropshipping'}
                img={'/img/WEBP_Type/Dropshipping.webp'}
                onClick={() => handleClick("PRODUCT")}
            />
        </>
    )
}
