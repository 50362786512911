import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, FreeMode } from 'swiper/modules';
import ReactPlayer from 'react-player';

const VideosContent = () => {

    const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [loadings, setLoadings] = useState([true, true, true, true, true, true]);

    const handleLoadedData = (index) => {
        setLoadings(prevLoadings => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
        });
    };
    return (
        <Swiper
            spaceBetween={13}
            slidesPerView={2.2}
            allowTouchMove={false}
            speed={2000}
            loop={true}
            autoplay={{
                delay: 0,
                disableOnInteraction: false,

            }}
            freeMode={true}
            modules={[Autoplay, FreeMode]

            }

        >
            <SwiperSlide>
                <div className="video-quiz__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                    <ReactPlayer
                        width='100%'
                        height='auto'
                        loop={true}
                        playing={true}
                        playsinline={true}
                        volume={0.5}
                        muted={true}
                        url={process.env.PUBLIC_URL + `/video/video-avatars/avatar-video-01.m3u8`} controls={false} />
                    <span>Product demo</span>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="video-quiz__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                    <ReactPlayer
                        width='100%'
                        height='auto'
                        loop={true}
                        playing={true}
                        playsinline={true}
                        volume={0.5}
                        muted={true}
                        url={process.env.PUBLIC_URL + `/video/video-avatars/avatar-video-02.m3u8`} controls={false} />
                    <span>Product demo</span>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="video-quiz__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                    <ReactPlayer
                        width='100%'
                        height='auto'
                        loop={true}
                        playing={true}
                        playsinline={true}
                        volume={0.5}
                        muted={true}
                        url={process.env.PUBLIC_URL + `/video/video-avatars/avatar-video-03.m3u8`} controls={false} />
                    <span>Product demo</span>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="video-quiz__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                    <ReactPlayer
                        width='100%'
                        height='auto'
                        loop={true}
                        playing={true}
                        playsinline={true}
                        volume={0.5}
                        muted={true}
                        url={process.env.PUBLIC_URL + `/video/video-avatars/avatar-video-04.m3u8`} controls={false} />
                    <span>Product demo</span>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="video-quiz__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                    <ReactPlayer
                        width='100%'
                        height='auto'
                        loop={true}
                        playing={true}
                        playsinline={true}
                        volume={0.5}
                        muted={true}
                        url={process.env.PUBLIC_URL + `/video/video-avatars/avatar-video-05.m3u8`} controls={false} />
                    <span>Product demo</span>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="video-quiz__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                    <ReactPlayer
                        width='100%'
                        height='auto'
                        loop={true}
                        playing={true}
                        playsinline={true}
                        volume={0.5}
                        muted={true}
                        url={process.env.PUBLIC_URL + `/video/video-avatars/avatar-video-06.m3u8`} controls={false} />
                    <span>Product demo</span>
                </div>
            </SwiperSlide>

        </Swiper>
    );
}

export default VideosContent;