import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setIsShowButton } from '../../store/buttonSlice';
import CustomTitle from '../../components/UI/title/CustomTitle';
import Loader from '../../components/Loader/Loader';

import 'swiper/swiper-bundle.css';
import './style.scss';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ReviewsContent from './ReviewsContent';

export default function Reviews() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const history = useHistory();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setIsShowButton(false));
    }, []);

    const [progress, setProgress] = useState(0);
    const [title, setTitle] = useState('Preparing AI tools...');
    const [secondLoader, setSecondLoader] = useState(false);
    const [thirdLoader, setThirdLoader] = useState(false);

    const [isFirstPopupShow, setIsFirstPopupShow] = useState(false);
    const [isSecondPopupShow, setIsSecondPopupShow] = useState(false);
    const [isThirdPopupShow, setIsThirdPopupShow] = useState(false);


    const swiperRef = useRef(null);
    const [swiper, setSwiper] = useState(null);

    const handleNextSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev < 33) return prev + 1;
                if (secondLoader && prev < 66) return prev + 1;
                if (thirdLoader && prev < 100) return prev + 1;
                clearInterval(interval);
                return prev;
            });
        }, 121);

        return () => clearInterval(interval);
    }, [secondLoader, thirdLoader]);

    useEffect(() => {
        if (progress === 33) {
            swiper.slideNext()
            setTitle('Processing your answers...')
            setSecondLoader(true)
        }
        if (progress === 66) {
            swiper.slideNext()
            setTitle('Getting AI copilot ready...')
            setThirdLoader(true)
        }
        if (progress === 100) {
            history.push({
                pathname: '/name',
                search: `?${queryParams.toString()}`,
            });
        }
    }, [progress])

    return (
        <div className="page reviews page--without-button">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title={'Setting up AI copilot to create powerful ads and boost your sales'}
                />

              <ReviewsContent setSwiper={setSwiper}/>
            </div>

            <Loader progress={progress} tripleLoader={true} title={title} />
        </div>
    )
}
