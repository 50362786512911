import React, { useState } from 'react'
import { setErrorButton } from '../../store/buttonSlice';
import { useDispatch } from 'react-redux';

export default function ReachContent({ checkboxes, setCheckboxes, error, setDisabled, setError, setDays }) {

    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();
    const handleCheckboxChange = (checkboxId, isChecked, days) => {

        setSelectedOption(checkboxId);

        setCheckboxes(prevCheckboxes => {
            const updatedCheckboxes = prevCheckboxes.map(checkbox =>

                checkbox.id === checkboxId ? { ...checkbox, checked: isChecked } : checkbox
            );

            const anyChecked = updatedCheckboxes.some(checkbox => checkbox.checked);
            setDays(days)
            setDisabled(!anyChecked);
            dispatch(setErrorButton(false))
            setError(false)
            return updatedCheckboxes;
        });
    };

    return (

        <>
            {checkboxes && checkboxes.map((checkbox, index) => (
                <div
                    key={checkbox.id}
                    checked={selectedOption === checkbox.id}
                    onClick={() => handleCheckboxChange(checkbox.id, !checkbox.checked, checkbox.days)}

                    className={`reach-item ${selectedOption === checkbox.id ? 'checked' : undefined}`}
                >
                    <div className={`reach-item__checked ${error ? 'error' : ""} ${selectedOption === checkbox.id ? 'checked' : ""}`}>
                        <span></span>
                    </div>
                    <div className="reach-item__icon">
                        {index === 0 &&
                            <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="38" cy="38" r="34" stroke="#F2F4F6" stroke-width="8" />
                                <path d="M72 38C72 33.5351 71.1206 29.1138 69.4119 24.9888C67.7032 20.8637 65.1988 17.1156 62.0416 13.9584C58.8844 10.8012 55.1363 8.29676 51.0112 6.5881C46.8862 4.87944 42.4649 4 38 4" stroke="black" stroke-width="8" stroke-linecap="round" />
                            </svg>
                        }
                        {index === 1 &&
                            <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="38" cy="38" r="34" stroke="#F2F4F6" stroke-width="8" />
                                <path d="M38 72C47.0174 72 55.6654 68.4179 62.0416 62.0416C68.4179 55.6654 72 47.0174 72 38C72 28.9826 68.4179 20.3346 62.0416 13.9584C55.6654 7.58213 47.0174 4 38 4" stroke="black" stroke-width="8" stroke-linecap="round" />
                            </svg>

                        }
                        {index === 2 &&
                            <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="38" cy="38" r="34" stroke="#F2F4F6" stroke-width="8" />
                                <path d="M4 38C4 44.7246 5.99407 51.2981 9.73004 56.8894C13.466 62.4807 18.7761 66.8385 24.9888 69.4119C31.2014 71.9853 38.0377 72.6586 44.6331 71.3467C51.2284 70.0348 57.2866 66.7966 62.0416 62.0416C66.7966 57.2866 70.0348 51.2284 71.3467 44.6331C72.6586 38.0377 71.9853 31.2014 69.4119 24.9888C66.8385 18.7761 62.4807 13.466 56.8894 9.73003C51.2981 5.99406 44.7246 4 38 4" stroke="black" stroke-width="8" stroke-linecap="round" />
                            </svg>

                        }
                        {index === 3 &&
                            <svg width="77" height="76" viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="38.6406" cy="38" r="34" stroke="black" stroke-width="8" />
                            </svg>


                        }
                        <span>{checkbox.number}</span>
                        <p>{checkbox.text}</p>
                    </div>

                    <div>
                        <h4>{checkbox.title}</h4>

                    </div>
                </div>
            ))}</>
    )
}
