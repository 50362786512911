import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setNextPage } from '../../store/appRouteSlice';

const BackHistory = ({ nextFunction, step, steps, sessionId }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (step) {
      const nextStep = steps[steps.indexOf(step) + 1];
      dispatch(setNextPage(nextStep?.id));

    }
  }, [step]);

  const getNextStep = (currentPath) => {
    const currentIndex = steps.findIndex(step => step.id === currentPath);
    if (currentIndex !== -1 && currentIndex < steps.length - 1) {
        return steps[currentIndex + 1];
    }
    return null;
};


  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      const blockedPaths = [
        // "/summary", "/loading", "/signup", "/trial", "/loader", "/booster", "/booster1", "/booster2", "/brandname", "/domain", '/signup2',
        '']
      if (action === 'POP' && blockedPaths.includes(location.pathname)) {
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [history]);



  // useEffect(() => {
  //   // Ваш код, который нужно выполнить при изменении history
  //   console.log("History был изменен:", history);

  //   if(history) {
  //     if (history.location.pathname === '/loader') {
  //       mixpanel.track("web_loader", {
  //         session_id: sessionId,
  //         web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
  //         user_id: localStorage.getItem('userId'),
  //         web_utm_campaign: localStorage.getItem("utmCampaign"),
  //         web_utm_source: localStorage.getItem("utmSource"),
  //         web_utm_medium: localStorage.getItem("utmMedium"),
  //         web_utm_content: localStorage.getItem("utmContent"),
  //         web_utm_term: localStorage.getItem("utmTerm"),
  //         isDebug: false,
  //         funnel: 'MAIN_v1',
  //       });
  //     }
  //     if (history.location.pathname === '/thankyou') {
  //       mixpanel.track('web_appdownload', {
  //         session_id: sessionId,
  //         web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
  //         user_id: localStorage.getItem('userId'),
  //         // web_type_brandname: webTypeBrandname,
  //         web_plan: localStorage.getItem('plan'),
  //         web_utm_campaign: localStorage.getItem("utmCampaign"),
  //         web_utm_source: localStorage.getItem("utmSource"),
  //         web_utm_medium: localStorage.getItem("utmMedium"),
  //         web_utm_content: localStorage.getItem("utmContent"),
  //         web_utm_term: localStorage.getItem("utmTerm"),
  //         isDebug: false,
  //         funnel: 'MAIN_v1',
  //       });

  //       const installationDate = new Date();

  //       const formattedInstallationDate = formatDate(installationDate);


  //       const sessionStart = localStorage.getItem('installation_date');
  //       const sessionEnd = new Date();
  //       const parts = sessionStart.split(/[. :]/);

  //       const sessionDurationSeconds = (sessionEnd - new Date(sessionStart)) / 1000;

  //       console.log(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5])
  //       const formattedSessionDuration = formatDuration(sessionDurationSeconds);

  //       mixpanel.track('SESSION_ENDED', {
  //         session_id: sessionId,
  //         web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
  //         user_id: localStorage.getItem('userId'),
  //         session_start: sessionStart,
  //         session_end: formattedInstallationDate,
  //         session_duration: formattedSessionDuration,
  //         web_utm_campaign: localStorage.getItem("utmCampaign"),
  //         web_utm_source: localStorage.getItem("utmSource"),
  //         web_utm_medium: localStorage.getItem("utmMedium"),
  //         web_utm_content: localStorage.getItem("utmContent"),
  //         web_utm_term: localStorage.getItem("utmTerm"),
  //         isDebug: false,
  //         funnel: 'MAIN_v1',
  //       });
  //     }
  //   }

  // }, [history.location.pathname]);


  useEffect(() => {
    const handleHistoryChange = () => {
      const pathname = history.location.pathname;
      console.log("History was changed:", pathname);

      if (pathname === '/loader') {
        setTimeout(() => {
          mixpanel.track("web_loader", {
            session_id: sessionId,
            web_level: 'TRIAL',
            web_plan__name: localStorage.getItem('plan'),
            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
            user_id: localStorage.getItem('userId'),
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
            funnel: 'MAIN_v1',
          });
        }, 500);

        funnels();
        purchases();
      }

      if (pathname === '/thankyou') {
        setTimeout(() => {

          mixpanel.track('web_appdownload', {
            session_id: sessionId,
            web_level: localStorage.getItem('web_booster_pay') === true ? 'GROWTH' : 'TRIAL',
            web_plan__name: localStorage.getItem('plan'),
            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
            user_id: localStorage.getItem('userId'),
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
            funnel: 'MAIN_v1',
            web_pay_method: localStorage.getItem('web_pay_method')
          });

          sessionEnded();
        }, 500);
      }
      if (pathname === '/trial') {
        if (localStorage.getItem("price")) {
          if (localStorage.getItem('price') == 'growth') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'begin_checkout',
              ecommerce: {
                value: '19.95',
                currency: "USD",
                items: [
                  {
                    discount: '22.00',
                    item_name: localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product',
                    item_variant: `2-week plan`,
                    price: "42.00",
                    quantity: 1
                  }
                ]
              },
            });
          }
          if (localStorage.getItem('price') == 'plus') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'begin_checkout',
              ecommerce: {
                value: '29.95',
                currency: "USD",
                items: [
                  {
                    discount: '53.00',
                    item_name: localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product',
                    item_variant: `4-week plan`,
                    price: "83.00",
                    quantity: 1
                  }
                ]
              },
            });
          }
          if (localStorage.getItem('price') == 'starter') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'begin_checkout',
              ecommerce: {
                value: '49.95',
                currency: "USD",
                items: [
                  {
                    discount: '117.00',
                    item_name: localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product',
                    item_variant: `8-week plan`,
                    price: "167.00",
                    quantity: 1
                  }
                ]
              },
            });
          }
        }
      }
    };

    handleHistoryChange();

    return history.listen(handleHistoryChange);
  }, [sessionId]);


  const funnels = () => {



    const data = {
      "pageId": localStorage.getItem('PriceId')
    };
    axios.post("https://staging.zeely.link/pages/paywalls", data, {
      headers: {
        "Content-Type": "application/json"
      },
      redirect: 'follow'
    })
      .then(response => {

      })
      .catch(error => {

      });
  };

  const purchases = () => {
    const data = {
      "pageId": localStorage.getItem('HomeId')
    };
    axios.post("https://staging.zeely.link/pages/purchases ", data, {
      headers: {
        "Content-Type": "application/json"
      },
      redirect: 'follow'
    })
      .then(response => {

      })
      .catch(error => {

      });
  };

  function sessionEnded() {
    const installationDate = new Date();
    const formattedInstallationDate = formatDate(installationDate);

    const sessionStart = localStorage.getItem('installation_date');
    const sessionEnd = new Date();
    const sessionDurationSeconds = (sessionEnd - new Date(sessionStart)) / 1000;
    const formattedSessionDuration = formatDuration(sessionDurationSeconds);

    mixpanel.track('SESSION_ENDED', {
      session_id: sessionId,

      web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
      user_id: localStorage.getItem('userId'),
      session_start: sessionStart,
      session_end: formattedInstallationDate,
      session_duration: formattedSessionDuration,
      web_utm_campaign: localStorage.getItem("utmCampaign"),
      web_utm_source: localStorage.getItem("utmSource"),
      web_utm_medium: localStorage.getItem("utmMedium"),
      web_utm_content: localStorage.getItem("utmContent"),
      web_utm_term: localStorage.getItem("utmTerm"),
      isDebug: false,
      funnel: 'MAIN_v1',
    });
  }
  function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return {
      hours,
      minutes,
      seconds: remainingSeconds
    };
  }

  const formatDate = (date) => {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${month}.${day}.${year} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      sessionEnded()
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

  }, []);


  return (
    <></>
  )
}

export default BackHistory