import React, { useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setButtonClick, setIconButton, setIsShowButton, setTextButton } from '../../store/buttonSlice';
import VideosContent from './VideosContent';
import './style.scss';
import MyButtonWhite from '../../components/UI/button/MyButtonWhite';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setButtonClickNot, setIconButtonNot, setIsShowButtonNot, setTextButtonNot } from '../../store/buttonNotSlice';

export default function VideoQuiz() {

    const dispatch = useDispatch();
    const history = useHistory();
    const buttonClicked = useSelector((state) => state.button.buttonClicked);
    const nextPage = useSelector((state) => state.appRoute.nextPage);
    const buttonNotClicked = useSelector((state) => state.buttonNot.buttonClicked);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (buttonClicked) {
            dispatch(setButtonClick(false));
            queryParams.set('video-ads', 1);
            history.push({
                pathname: nextPage,
                search: `?${queryParams.toString()}`
            });
        }
    }, [buttonClicked, dispatch]);

    useEffect(() => {
        if (buttonNotClicked) {
            dispatch(setButtonClickNot(false));
            history.push({
                pathname: '/static-ads',
                search: `?${queryParams.toString()}`
            });
        }
    }, [buttonNotClicked, dispatch]);

    useEffect(() => {
        dispatch(setIsShowButton(true));
        dispatch(setTextButton('Let’s do it'));
        dispatch(setIconButton('Tick'));

        dispatch(setIsShowButtonNot(true));
        dispatch(setTextButtonNot('Not sure'));
        dispatch(setIconButtonNot('not'));
    }, []);

    return (
        <div className="page video-quiz page--full">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '12rem' }}
                    title={'Would you like to have viral video ads made with AI?'}
                />
                <p className="text" style={{ marginBottom: '24rem' }}>
                    Takes 5 mins. AI does the talking, writing, and editing.
                </p>
                <div className="video-quiz-wrapper">
                    <VideosContent />
                </div>

            </div>
        </div>
    );
}
