import React, { useState } from 'react'
import CustomTitle from '../../components/UI/title/CustomTitle'
import Promocode from './components/Promocode/Promocode'
import Tariffs from './components/Tariffs/Tariffs'

export default function FinalPlan() {
        const [activePlan, setActivePlan] = useState('1');
    return (
        <div className="plan">
            <CustomTitle style={{ marginBottom: "24rem" }} title="New promo code applied: <span>60%</span> 80% discount !" />
            <Promocode final={true} />
            <Tariffs final={true}  setActivePlan={setActivePlan} activePlan={activePlan} buttonShow={true}/>
        </div>
    )
}
