import React from 'react'
import './style.scss';

export default function AvatarsItem({ name, gender, age, img, checked, onClick, error }) {
    return (
        <div className={`avatars-item ${checked ? 'checked' : ''}`} onClick={onClick}>
            <div className={`avatars-item__checked ${checked ? 'checked' : ''} ${error ? 'error' : ''}`}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.30859 6.00764L4.8727 8.31534L10.0009 3.69995" stroke="white" stroke-width="1.6" stroke-linecap="round" />
            </svg>
            </div>
            <img
                src={process.env.PUBLIC_URL + "/img/WEBP_avatars-preview/" + img}
                alt=""
            />
            <div className="avatars-item-bottom">
                <div>
                    <div className="avatars-item__title">
                        {name}
                    </div>
                    <div className="avatars-item__text">
                        {gender} <span></span>{age}
                    </div>
                </div>
                {/* <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_b_3_1517)">
                        <rect width="30" height="30" rx="15" fill="white" fill-opacity="0.3" />
                        <path d="M20.5004 14.0113C21.1665 14.4073 21.1665 15.5927 20.5004 15.9887L12.2515 20.8926C11.6705 21.238 11 20.7083 11 19.9039L11 10.0961C11 9.29172 11.6705 8.76201 12.2515 9.1074L20.5004 14.0113Z" fill="white" />
                    </g>
                    <defs>
                        <filter id="filter0_b_3_1517" x="-5" y="-5" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3_1517" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3_1517" result="shape" />
                        </filter>
                    </defs>
                </svg> */}

            </div>
        </div>
    )
}
