import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowTerm: false,
  isShowPrivacy: false,
};

const termSlice = createSlice({
  name: "term",
  initialState,
  reducers: {
    setIsShowTerm: (state, action) => {
      state.isShowTerm = action.payload;
    },
    setIsShowPrivacy: (state, action) => {
      state.isShowPrivacy = action.payload;
    },
  },
});

export const { setIsShowTerm, setIsShowPrivacy } = termSlice.actions;
export default termSlice.reducer;