import React, { useState } from 'react'
import CustomRadio from '../../components/UI/radio/CustomRadio';
import { setErrorButton } from '../../store/buttonSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function TargetContent({ checkboxes, setCheckboxes, error, setDisabled, setError, setValue }) {

    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(null);

    const handleCheckboxChange = (checkbox) => {
        setSelectedOption(checkbox.id);
        setDisabled(false)
        setError(false);
        dispatch(setErrorButton(false))
        setValue(checkbox.text)
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('current-id');

    return (
        <>
            {checkboxes && checkboxes.slice(id).map((checkbox) => (
                <CustomRadio
                    key={checkbox.id}
                    id={checkbox.id}
                    checked={selectedOption === checkbox.id}
                    onChange={() => handleCheckboxChange(checkbox)}
                    className={error ? 'error' : undefined}
                    style={{ marginBottom: '8rem' }}
                >
                    {checkbox.text}
                </CustomRadio>
            ))}
        </>
    )
}
