import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';

export default function ReviewsContent({setSwiper}) {
    return (
        <Swiper
            atyle={{ marginBottom: "6rem" }}
            spaceBetween={4}
            slidesPerView={1}
            allowTouchMove={false}
            speed={500}
            freeMode={true}
            modules={[Autoplay, FreeMode]}
            onSwiper={(s) => {
                if(setSwiper) {
                    setSwiper(s);
                }
            }}
            allowSlideNext={true}
        >
            <SwiperSlide>
                <div className="reviews-item">
                    <div className="reviews-item-top">
                        <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/avatar-01-L.webp'} alt="slide" />
                        <div>
                            <h4>Mary S.</h4>
                            <span>
                                <svg width="57" height="11" viewBox="0 0 57 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.16162 0.161499L6.67858 3.2352L10.0706 3.72809L7.61611 6.12063L8.19554 9.49895L5.16162 7.90392L2.12769 9.49895L2.70712 6.12063L0.252627 3.72809L3.64466 3.2352L5.16162 0.161499Z" fill="#FF8319" />
                                    <path d="M16.5908 0.161499L18.1078 3.2352L21.4998 3.72809L19.0453 6.12063L19.6247 9.49895L16.5908 7.90392L13.5569 9.49895L14.1363 6.12063L11.6818 3.72809L15.0739 3.2352L16.5908 0.161499Z" fill="#FF8319" />
                                    <path d="M28.0203 0.161499L29.5372 3.2352L32.9292 3.72809L30.4748 6.12063L31.0542 9.49895L28.0203 7.90392L24.9863 9.49895L25.5658 6.12063L23.1113 3.72809L26.5033 3.2352L28.0203 0.161499Z" fill="#FF8319" />
                                    <path d="M39.4495 0.161499L40.9664 3.2352L44.3584 3.72809L41.904 6.12063L42.4834 9.49895L39.4495 7.90392L36.4155 9.49895L36.995 6.12063L34.5405 3.72809L37.9325 3.2352L39.4495 0.161499Z" fill="#FF8319" />
                                    <path d="M50.8789 0.161499L52.3959 3.2352L55.7879 3.72809L53.3334 6.12063L53.9128 9.49895L50.8789 7.90392L47.845 9.49895L48.4244 6.12063L45.9699 3.72809L49.3619 3.2352L50.8789 0.161499Z" fill="#FF8319" />
                                </svg>
                                Oct 18, 2024
                            </span>
                        </div>
                    </div>
                    <p>With Zeely, I can launch polished video ads in minutes. It’s like having a full creative team powered by AI, and the ROI speaks for itself!</p>
                    <div className="reviews-item__img">
                        <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Shopify/shopify-1.webp'} alt="slide" />
                    </div>
                    <div className="reviews-item-bottom">
                        <div className="reviews-item-bottom-images">
                            <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/avarat-02-s.webp'} alt="slide" />
                            <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/avatar-03-s.webp'} alt="slide" />
                            <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/Avatar-04-s.webp'} alt="slide" />

                        </div>
                        <span>Trusted by 88,213 users</span>
                    </div>
                </div>

            </SwiperSlide>
            <SwiperSlide>
                <div className="reviews-item">
                    <div className="reviews-item-top">
                        <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/Avatar-02-L.webp'} alt="slide" />
                        <div>
                            <h4>Emma D.</h4>
                            <span>
                                <svg width="57" height="11" viewBox="0 0 57 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.16162 0.161499L6.67858 3.2352L10.0706 3.72809L7.61611 6.12063L8.19554 9.49895L5.16162 7.90392L2.12769 9.49895L2.70712 6.12063L0.252627 3.72809L3.64466 3.2352L5.16162 0.161499Z" fill="#FF8319" />
                                    <path d="M16.5908 0.161499L18.1078 3.2352L21.4998 3.72809L19.0453 6.12063L19.6247 9.49895L16.5908 7.90392L13.5569 9.49895L14.1363 6.12063L11.6818 3.72809L15.0739 3.2352L16.5908 0.161499Z" fill="#FF8319" />
                                    <path d="M28.0203 0.161499L29.5372 3.2352L32.9292 3.72809L30.4748 6.12063L31.0542 9.49895L28.0203 7.90392L24.9863 9.49895L25.5658 6.12063L23.1113 3.72809L26.5033 3.2352L28.0203 0.161499Z" fill="#FF8319" />
                                    <path d="M39.4495 0.161499L40.9664 3.2352L44.3584 3.72809L41.904 6.12063L42.4834 9.49895L39.4495 7.90392L36.4155 9.49895L36.995 6.12063L34.5405 3.72809L37.9325 3.2352L39.4495 0.161499Z" fill="#FF8319" />
                                    <path d="M50.8789 0.161499L52.3959 3.2352L55.7879 3.72809L53.3334 6.12063L53.9128 9.49895L50.8789 7.90392L47.845 9.49895L48.4244 6.12063L45.9699 3.72809L49.3619 3.2352L50.8789 0.161499Z" fill="#FF8319" />
                                </svg>
                                Jul 7, 2024
                            </span>
                        </div>
                    </div>
                    <p>Thanks to Zeely, I no longer worry about creating video ads. The AI takes care of everything, saving me time and delivering impressive sales growth.</p>
                    <div className="reviews-item__img">
                        <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Shopify/shopify-2.webp'} alt="slide" />
                    </div>
                    <div className="reviews-item-bottom">
                        <div className="reviews-item-bottom-images">
                            <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/avatar-03-s.webp'} alt="slide" />
                            <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/Avatar-04-s.webp'} alt="slide" />
                            <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/Avatar-05-s.webp'} alt="slide" />
                        </div>
                        <span>Trusted by 88,213 users</span>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className="reviews-item">
                    <div className="reviews-item-top">
                        <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/avatar-03-L.webp'} alt="slide" />
                        <div>
                            <h4>Leo F.</h4>
                            <span>
                                <svg width="57" height="11" viewBox="0 0 57 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.16162 0.161499L6.67858 3.2352L10.0706 3.72809L7.61611 6.12063L8.19554 9.49895L5.16162 7.90392L2.12769 9.49895L2.70712 6.12063L0.252627 3.72809L3.64466 3.2352L5.16162 0.161499Z" fill="#FF8319" />
                                    <path d="M16.5908 0.161499L18.1078 3.2352L21.4998 3.72809L19.0453 6.12063L19.6247 9.49895L16.5908 7.90392L13.5569 9.49895L14.1363 6.12063L11.6818 3.72809L15.0739 3.2352L16.5908 0.161499Z" fill="#FF8319" />
                                    <path d="M28.0203 0.161499L29.5372 3.2352L32.9292 3.72809L30.4748 6.12063L31.0542 9.49895L28.0203 7.90392L24.9863 9.49895L25.5658 6.12063L23.1113 3.72809L26.5033 3.2352L28.0203 0.161499Z" fill="#FF8319" />
                                    <path d="M39.4495 0.161499L40.9664 3.2352L44.3584 3.72809L41.904 6.12063L42.4834 9.49895L39.4495 7.90392L36.4155 9.49895L36.995 6.12063L34.5405 3.72809L37.9325 3.2352L39.4495 0.161499Z" fill="#FF8319" />
                                    <path d="M50.8789 0.161499L52.3959 3.2352L55.7879 3.72809L53.3334 6.12063L53.9128 9.49895L50.8789 7.90392L47.845 9.49895L48.4244 6.12063L45.9699 3.72809L49.3619 3.2352L50.8789 0.161499Z" fill="#FF8319" />
                                </svg>
                                Nov 17, 2024
                            </span>
                        </div>
                    </div>
                    <p>Using Zeely feels like having a personal marketing assistant. The AI-driven videos are top-notch and deliver results I never thought possible!</p>
                    <div className="reviews-item__img">
                        <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Shopify/shopify-3.webp'} alt="slide" />
                    </div>
                    <div className="reviews-item-bottom">
                        <div className="reviews-item-bottom-images">

                            <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/Avatar-04-s.webp'} alt="slide" />
                            <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/Avatar-05-s.webp'} alt="slide" />
                            <img src={process.env.PUBLIC_URL + '/img/WEBP_Loader-02/WEBP_Users-profile/Avatar-06-s.webp'} alt="slide" />
                        </div>
                        <span>Trusted by 88,213 users</span>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    )
}
