import React, { useState, useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import MyButton from '../../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import './style.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as IconDomain } from '../../assets/icons/domain.svg';
import { useCheckSiteMutation, useFetchBusinessProfilesQuery } from '../../api/dataApi';

const Website = () => {

    const history = useHistory();
    const [checkSite, { isCheckSite, checkSiteData, checkSiteError }] = useCheckSiteMutation();

    const [checkboxes, setCheckboxes] = useState({
        F: false,
    });
    const [errorUrl, setErrorUrl] = useState();
    const [name, setName] = useState('');

    const [error, setError] = useState(false);

    const areAllCheckboxesUnchecked = () => {
        return Object.values(checkboxes).every((isChecked) => !isChecked);
    };

    const { data: businessProfiles, isLoading: isLoadingProfiles } = useFetchBusinessProfilesQuery();

    const direction = businessProfiles?.data?.direction;

    const [buttonStatus, setButtonStatus] = useState('');

    const handleClick = () => {
        if (areAllCheckboxesUnchecked()) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 1000);
        } else {
            setButtonStatus('load')
            if (name.startsWith('https://') || name.startsWith('http://')) {
                checkWebsite(name);
            } else {
                checkWebsite(`https://${name}`);
            }
            setError(false);
        }
    };

    const handleInputChange = (event) => {
        const enteredValue = event.target.value.replace(/\s/g, '');

        setName(enteredValue);
        setErrorUrl(false)
        if (event.target.value == "") {
            setCheckboxes({ F: false, })
        } else {
            setCheckboxes({ F: true, })
        }
    };

    // function businessProfiles(value) {
    //     var profilesJSON = localStorage.getItem('business-profiles');
    //     if (profilesJSON) {
    //         var profiles = JSON.parse(profilesJSON);
    //         profiles.forEach(function (profile) {
    //             profile.siteDomain = value;
    //         });
    //         var updatedProfilesJSON = JSON.stringify(profiles);
    //         localStorage.setItem('business-profiles', updatedProfilesJSON);
    //     } else {
    //         var newProfile = {
    //             siteDomain: value
    //         };
    //         var newProfiles = [newProfile];
    //         var newProfilesJSON = JSON.stringify(newProfiles);
    //         localStorage.setItem('business-profiles', newProfilesJSON);
    //     }
    // }

    const businessProfilesUpdate = (url) => {
        const fullJSON = localStorage.getItem('business-profiles');
        const profile = JSON.parse(fullJSON)[0];
        const trimmedJSON = JSON.stringify(profile);

        const data = trimmedJSON

        axios.get("https://staging.zeely.link/business-profiles", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            redirect: 'follow'
        })

            .then(response => {
                localStorage.setItem('business-profiles-id', response.data.data.id);
                axios.patch(`https://staging.zeely.link/business-profiles/${response.data.data.id}`, data, {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    },
                    redirect: 'follow'
                })
                    .then(response => {
                        // CreateSite(next, true)
                    })
                    .catch(error => {

                        sendAlert(`https://staging.zeely.link/business-profiles/${localStorage.getItem('business-profiles-id')}`, data, error.response.data.message)
                        console.log(error)
                    });
            })
            .catch(error => {
                console.log(error)

            });


    };


    const checkWebsite = async (url) => {
        const data = JSON.stringify({ url: url })
        try {
            const result = await checkSite(url);
            if (result.data.status === true) {
                localStorage.setItem('site-url', url);
                history.push('/product-loader')
            } else {
                setButtonStatus('')
                setErrorUrl(true)
            }
            console.log('Check Site Result:', result);
        } catch (err) {
            if (err.message === "Network Error" && url.includes('www.')) {
                checkWebsite(removeWWW(url))
            } else {
                let errorMessage = err;
                if (err && err.response && err.response.data && err.response.data.message) {
                    errorMessage = err.response.data.message;
                }
                sendAlert(`https://staging.zeely.link/sites/check-user-site`, data, errorMessage);
                setButtonStatus('')
                setErrorUrl(true)
            }
        }
    };

    // function checkWebsite(url) {

    //     const data = JSON.stringify({ url: url })
    //     axios.post(`https://staging.zeely.link/sites/check-user-site`, data, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    //         },
    //         redirect: 'follow'
    //     })
    //         .then(response => {
    //             console.log(response.data.status)
    //             if (response.data.status === true) {
    //                 // businessProfiles(url)
    //                 // businessProfilesUpdate(url)
    //                 // Scraper(url)
    //                 // mixpanel.track('web_websiteurl', {
    //                 //     web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
    //                 //     session_id: sessionId,
    //                 //     web_level: localStorage.getItem('web_booster_pay') === true ? 'GROWTH' : 'TRIAL',
    //                 //     web_plan__name: localStorage.getItem('plan'),
    //                 //     user_id: localStorage.getItem('userId'),
    //                 //     web_website_act: url,
    //                 //     web_utm_campaign: localStorage.getItem("utmCampaign"),
    //                 //     web_utm_source: localStorage.getItem("utmSource"),
    //                 //     web_utm_medium: localStorage.getItem("utmMedium"),
    //                 //     web_utm_content: localStorage.getItem("utmContent"),
    //                 //     web_utm_term: localStorage.getItem("utmTerm"),
    //                 //     isDebug: false,
    //                 //     funnel: 'MAIN_v1',
    //                 // });
    //                 Scrapping(url)
    //                 history.push('/domain-loader')
    //             } else {
    //                 setButtonStatus('')
    //                 setErrorUrl(true)
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error.message)
    //             if (error.message === "Network Error" && url.includes('www.')) {

    //                 checkWebsite(removeWWW(url))
    //             } else {
    //                 let errorMessage = error;
    //                 if (error && error.response && error.response.data && error.response.data.message) {
    //                     errorMessage = error.response.data.message;
    //                 }

    //                 sendAlert(`https://staging.zeely.link/sites/check-user-site`, data, errorMessage);
    //                 setButtonStatus('')
    //                 setErrorUrl(true)
    //             }

    //         });


    // }

    function removeWWW(url) {
        return url.replace('www.', ''); // or 'https://' if you prefer to maintain https
    }

    const Scraper = (url) => {
        // const trimmedJSON = JSON.stringify({
        //     'url': name,
        //     'structure': localStorage.getItem('structure'),
        // });

        // const data = trimmedJSON
        const structure = localStorage.getItem('structure') ? localStorage.getItem('structure').toUpperCase() : 'OTHER'

        axios.get(`https://staging.zeely.link/users/scraper?url=${url}&structure=${structure}`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            redirect: 'follow'
        })
            .then(response => {
                // localStorage.setItem('site-id', response.data.data.id);
            })
            .catch(error => {
                sendAlert(`https://staging.zeely.link/users/scraper?url=${url}&structure=${structure}`, '', error.response.data.message)
                console.log(error)
            });
    };


    const Scrapping = (url) => {
        // const trimmedJSON = JSON.stringify({
        //     'url': name,
        //     'structure': localStorage.getItem('structure'),
        // });

        // const data = trimmedJSON
        const structure = localStorage.getItem('structure') ? localStorage.getItem('structure').toUpperCase() : 'OTHER'

        axios.get(`https://staging.zeely.link/products/external/scrapping?link=${url}`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            redirect: 'follow'
        })
            .then(response => {
                console.log(response)
                // localStorage.setItem('site-id', response.data.data.id);
            })
            .catch(error => {
                // sendAlert(`https://staging.zeely.link/users/scraper?url=${url}&structure=${structure}`, '', error.response.data.message)
                console.log(error)
            });
    };

    const UpdateSite = (url) => {
        const trimmedJSON = JSON.stringify({
            'url': url,
            'type': localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product'
        });

        const data = trimmedJSON

        axios.post("https://staging.zeely.link/sites", data, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            redirect: 'follow'
        })
            .then(response => {
                // localStorage.setItem('site-id', response.data.data.id);
                // next()
            })
            .catch(error => {
                console.log(error)
                // next()
            });
        // const trimmedJSON = JSON.stringify({
        //     "isActive": true
        // });
        // const data = trimmedJSON
        // axios.patch(`https://staging.zeely.link/sites/${localStorage.getItem('site-id')}`, data, {
        //     headers: {
        //         "Content-Type": "application/json",
        //         'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        //     },
        //     redirect: 'follow'
        // })
        //     .then(response => {
        //         localStorage.setItem('site-id', response.data.data.id);
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     });
    };



    const sendAlert = async (path, data, errorLog) => {
        const now = new Date();
        const utcString = now.toISOString();

        const formdata = new FormData();
        formdata.append("domain", window.location.hostname);
        formdata.append("time", utcString);
        formdata.append("email", localStorage.getItem('email'));
        formdata.append("path", path);
        formdata.append("data", data);
        formdata.append("error", errorLog);
        console.log(formdata)
        try {
            const response = await axios.post("https://zee.sale/send-alert.php", formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };



    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setName(text);

            if (text == "") {
                setCheckboxes({ F: false, })
            } else {
                setCheckboxes({ F: true, })
            }
        } catch (err) {
            console.error("Failed to read clipboard contents: ", err);
        }
    };

    return (
        <div className='website'>
            <div className="website-top">
                <CustomTitle style={{ marginBottom: "8rem" }} title={`${direction === "PRODUCT" ? "Add your first product link" : "Add your first service link"}`} />
                <p style={{ marginBottom: "24rem" }} className="text">Our AI will analyze it to get insights into your brand</p>
                <div className={`input ${errorUrl || error ? 'error' : ''}`} style={{ marginBottom: "24rem", paddingLeft: "46rem", paddingRight: "16rem" }}>
                    <input
                        type="text" 
                        placeholder={`${direction === "PRODUCT" ? "yoursite.com/product" : "yoursite.com/service"}`}
                        value={name}
                        onChange={handleInputChange}
                        autocapitalize="none"
                    />
                    <div className={`input-border ${name === '' ? 'empty' : ''}`}></div>
                    <IconDomain />
                    <span>Link</span>
                    {errorUrl && (
                        <div className="error-text">This domain name is unavailable. Try a different one.</div>
                    )}
                    {/* <div className="paste" onClick={handlePaste} >
                        Paste
                    </div> */}

                    {/* <div className="input-ai">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.6088 8.50259L13.9913 6.58984L14.3739 8.50259C15.0804 12.0353 17.8418 14.7968 21.3746 15.5033L23.2873 15.8858L21.3746 16.2684C17.8418 16.9749 15.0804 19.7364 14.3739 23.2691L13.9913 25.1818L13.6088 23.2691C12.9022 19.7364 10.1408 16.9749 6.60806 16.2684L4.69531 15.8858L6.60806 15.5033C10.1408 14.7968 12.9022 12.0353 13.6088 8.50259Z" fill="black" />
                        <path d="M5.71537 1.42766L5.92512 0.378906L6.13487 1.42766C6.52229 3.36465 8.03634 4.87874 9.97336 5.26615L11.0221 5.4759L9.97336 5.68566C8.03634 6.07303 6.52229 7.58712 6.13487 9.52414L5.92512 10.5729L5.71537 9.52414C5.32796 7.58712 3.81386 6.07303 1.87688 5.68566L0.828125 5.4759L1.87688 5.26615C3.81386 4.87874 5.32796 3.36465 5.71537 1.42766Z" fill="black" />
                        <path d="M21.3189 4.01865L21.471 3.25781L21.6232 4.01865C21.9042 5.42384 23.0026 6.52221 24.4078 6.80326L25.1687 6.95546L24.4078 7.1076C23.0026 7.38865 21.9042 8.48702 21.6232 9.89222L21.471 10.6531L21.3189 9.89222C21.0378 8.48702 19.9395 7.38865 18.5343 7.1076L17.7734 6.95546L18.5343 6.80326C19.9395 6.52221 21.0378 5.42384 21.3189 4.01865Z" fill="black" />
                    </svg>

                </div> */}
                </div>
            </div>



            <div className="website-bottom">




                <div className="skip" onClick={() => {
                    //                             mixpanel.track('web_websiteurl', {
                    //                                 web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                    //                                 session_id: sessionId,
                    //                                 web_level: localStorage.getItem('web_booster_pay') === true ? 'GROWTH' : 'TRIAL',
                    //                                 web_plan__name: localStorage.getItem('plan'),
                    //                                 user_id: localStorage.getItem('userId'),
                    //                                 web_website_act: 'skip',
                    //                                 web_utm_campaign: localStorage.getItem("utmCampaign"),
                    //                                 web_utm_source: localStorage.getItem("utmSource"),
                    //                                 web_utm_medium: localStorage.getItem("utmMedium"),
                    //                                 web_utm_content: localStorage.getItem("utmContent"),
                    //                                 web_utm_term: localStorage.getItem("utmTerm"),
                    //                                 isDebug: false,
                    // funnel: 'MAIN_v1',
                    //                             });
                    history.push("/industry")
                }}>
                    I don't have a link
                </div>
                <MyButton
                    // ref={nextPageButtonRef}
                    buttonStatus={buttonStatus}
                    buttonTime={"0"}
                    buttonText={"Analyze with AI"}
                    buttonIcon={'no'}
                    onClick={() => handleClick()}
                    className={(areAllCheckboxesUnchecked()) ? (error ? 'error' : 'disable') : undefined}
                >
                </MyButton>


            </div>





        </div>
    );
}

export default Website;

