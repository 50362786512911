import React, { useEffect, useState } from 'react'

import './style.scss';
import { setButtonClick, setErrorButton, setIconButton, setIsShowButton, setTextButton } from '../../store/buttonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CustomTitle from '../../components/UI/title/CustomTitle';
import GoalsContent from './GoalsContent';

export default function Goals() {
    const initialCheckboxState = [
        {
            id: '0',
            checked: false,
            title: 'Purchases',
            text: "Increase amount of orders",
            img: 'Shopify.svg',
        },
        {
            id: '2',
            checked: false,
            title: 'ROAS',
            text: "Boost return on ad spend",
            img: 'g2.svg',
        },
        {
            id: '1',
            checked: false,
            title: 'CPA',
            text: "Lower cost per action",
            img: 'g2.svg',
        },
        
        {
            id: '3',
            checked: false,
            title: 'CTR',
            text: "Improve click through rate",
            img: 'g2.svg',
        }
    ];

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [disabled, setDisabled] = useState(true);
    const [checkboxes, setCheckboxes] = useState(initialCheckboxState);
    const [error, setError] = useState(false);
    const [value, setValue] = useState([]);

    useEffect(() => {
      setValue(checkboxes.filter(checkbox => checkbox.checked).map(checkbox => checkbox.id));
    }, [checkboxes]);
    
    const dispatch = useDispatch();
    const history = useHistory();
    const buttonClicked = useSelector((state) => state.button.buttonClicked);
    const nextPage = useSelector((state) => state.appRoute.nextPage);

    const getPriorityCheckedCheckboxId = () => {
        const checkedCheckboxes = checkboxes.filter(checkbox => checkbox.checked);
        if (checkedCheckboxes.length > 0) {
            return checkedCheckboxes[0].id;
        }
        return null;
    };
    

    useEffect(() => {
        if (buttonClicked) {
            if (disabled) {
                setError(true);
                dispatch(setErrorButton(true))
                dispatch(setButtonClick(false));
                setTimeout(() => {
                    setError(false);
                    dispatch(setErrorButton(false))
                }, 2000);
                return;
            }
            dispatch(setButtonClick(false));
            queryParams.set('goals', getPriorityCheckedCheckboxId());
            queryParams.set('goals-value', value);
            history.push({
                pathname: nextPage,
                search: `?${queryParams.toString()}`,
            });
        }
    }, [buttonClicked, dispatch, disabled, checkboxes]);


    useEffect(() => {
        dispatch(setIsShowButton(true));
        dispatch(setTextButton('Let’s achive your goal'));
        dispatch(setIconButton(''));
    }, []);


    return (
        <div className="goals page">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title={'What goals do you want to achieve with help of AI?'}
                />
                <div className="goals-wrapper">
                    <GoalsContent  setError={ setError}checkboxes={checkboxes} setCheckboxes={setCheckboxes} error={error} setDisabled={setDisabled} />
                </div>

            </div>

        </div>
    )
}
