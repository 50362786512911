import React from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './style.scss';
import { useDispatch } from 'react-redux';
import { setIsShowPrivacy, setIsShowTerm } from '../../store/termSlice';
import StageContent from './StageContent';

export default function Stage() {
    const dispatch = useDispatch();

    const termChange = () => {
        dispatch(setIsShowTerm(true));
    };

    const privacyChange = () => {
        dispatch(setIsShowPrivacy(true));
    };

    return (
        <div className="page stage page--without-button">
            <div className="page-top">
                <CustomTitle
                    style={{ marginBottom: '24rem' }}
                    title="Which of these best<br>describes you?"
                />
                <div className="stage-wrapper">
                    <StageContent />
                </div>
            </div>
            <div className="stage-bottom">
                By continuing, you accept our<br></br>
                <i onClick={termChange}>Terms of Use</i>, <i onClick={privacyChange}>Privacy Policy</i>, and <i>Refund Policy</i>
            </div>
        </div>
    );
}
